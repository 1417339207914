import { Modal, ModalBody, Spinner } from "reactstrap"

const Loader = ({ isOpen = '' }) => {
  return (
    <Modal className="loader-css" isOpen={isOpen}
    centered
    backdrop
    size="sm">
      <ModalBody >
        <div className='d-flex justify-content-center align-items-center' >
          <Spinner
            color="warning"
            style={{
              height: '5rem',
              width: '5rem'
            }}
            type="grow"
          >
            Loading...
          </Spinner>
        </div>
      </ModalBody>
    </Modal>

  )
}

export default Loader