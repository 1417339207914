import React, { useEffect, useState } from 'react';
import { IoIosCheckmarkCircle, IoMdInformationCircle } from 'react-icons/io';
import {
  Button,
  Card,
  CardBody,
  Input,
  Spinner,
  Table,
  Tooltip,
} from 'reactstrap';
import ConfirmationPopup from '../common/ConfirmationPopup';
import EmptyIcon from '../../assests/images/miscellaneous/search.svg';
import NoData from '../../assests/images/miscellaneous/file.svg';
import moment from 'moment-timezone';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { formatNumber } from '../../utilities/function';
import { processAPI } from '../../utilities/api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { endpoints } from '../../utilities/Endpoints';
import Loader from '../common/Loader';

const ForecastCorrection = ({
  forecastDate = '',
  forecastData = '',
  setIsUpdated = {},
  loading = '',
}) => {
  const { t } = useTranslation();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [disable, setDisable] = useState(false);
  const [forecastLoading, setForecastLoading] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const currentTime = moment(new Date()).utc().format('DD-MM-YYYY HH:mm');
  const maxTime = moment(forecastDate).utc().format('DD-MM-YYYY 08:00');
  const validationSchema = Yup.object().shape({
    forecastData: Yup.array().of(
      Yup.object().shape({
        returnVolume: Yup.number()
          .typeError(t('Must be a number')) // Ensures that the value is a valid number
          .integer(t('This field cannot include decimals')) // Ensures the number is an integer
          .test({
            name: 'is-divisible-by-4',
            message: t('Should be divisible by 4'),
            test: value => {
              if (value === undefined || value === null) return true; // Allow empty values to pass validation
              return value % 4 === 0;
            },
          })
          .max(10000000, t(`Max Volume - ${formatNumber(10000000)}`)),
        updatedVolume: Yup.string(),
      }),
    ),
  });

  const formik = useFormik({
    initialValues: { forecastData: [] },
    validationSchema: validationSchema,
    onSubmit: values => {
      handleSubmit(values);
    },
  });

  const handlegetData = () => {
    const timeout = setTimeout(() => {
      if (forecastData && forecastData.length > 0) {
        formik.setFieldValue('forecastData', forecastData);
      }
    }, 1000);
    return () => clearTimeout(timeout);
  };
  const handleDateCheck = () => {
    const currentTimeMoment = moment(currentTime, 'DD-MM-YYYY HH:mm');
    const maxTimeMoment = moment(maxTime, 'DD-MM-YYYY HH:mm');
    // Compare the two moments
    if (currentTimeMoment.isBefore(maxTimeMoment)) {
      setReadOnly(false);
      setDisable(false);
    } else if (currentTimeMoment.isAfter(maxTimeMoment)) {
      setReadOnly(true);
      setDisable(true);
    } else {
      setReadOnly(true);
      setDisable(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    sessionStorage.setItem('isEditing', false);
    setIsUpdated(true);
    formik.handleReset();
    setDisable(true);
  };

  const handleInputChange = (value, index) => {
    if (value != '') {
      sessionStorage.setItem('isEditing', true);
      setDisable(false);
      formik.setFieldValue(`forecastData[${index}].returnVolume`, value);
    } else {
      formik.setFieldValue(`forecastData[${index}].returnVolume`, '');
    }
  };
  const accessToken =
    sessionStorage.getItem('accessToken') ||
    localStorage.getItem('accessToken');
  const companyId =
    sessionStorage.getItem('companyId') || localStorage.getItem('companyId');

  const handleSubmit = async values => {
    setDisable(true);
    setForecastLoading(true);
    let modifiedValues = [];
    modifiedValues = values.forecastData.filter(item => item?.returnVolume);
    modifiedValues = modifiedValues.map(item => ({
      dateFrom: item?.fromDate,
      dateTo: item?.toDate,
      adjustedVolume: Number(item?.returnVolume),
      pointOfDeliveryId: item?.pointOfDeliveryId,
    }));
    try {
      const response = await processAPI.post(
        endpoints.forecastCorrection,
        modifiedValues,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            companyId: companyId,
            accessToken: accessToken,
          },
        },
      );
      setForecastLoading(false);
      setOpen(true);
    } catch (error) {
      console.log('error updating data', error);
      setForecastLoading(false);
      handleClear();
      toast.error(
        error?.response?.data || t('Error updating forecast. Try again later.'),
      );
    }
    setDisable(false);
  };
  const handleClear = () => {
    const timeout = setTimeout(() => {
      formik?.values?.forecastData.map(index =>
        formik.setFieldValue(`forecastData[${index}].returnVolume`, ''),
      );
    }, 1000);
    return () => clearTimeout(timeout);
  };
  const handleUpdateVolume = index => {
    const timeout = setTimeout(() => {
      formik.setFieldValue(
        `forecastData[${index}].updatedVolume`,
        formik?.values?.forecastData[index]?.returnVolume,
      );
    }, 1000);
    return () => clearTimeout(timeout);
  };

  const formatTime = dateTime => {
    let formattedTime = moment.parseZone(dateTime).format('HH:mm');
    return formattedTime;
  };

  const forecastTable = (item, index) => {
    return (
      <tr key={index}>
        <td valign="middle">
          {formatTime(item?.fromDate)}-{formatTime(item?.toDate)}
        </td>
        <td valign="middle">{formatNumber(item?.forecastVolume)}</td>
        <td valign="middle">
          <Input
            type="text"
            placeholder="0"
            name={`forecastData[${index}].returnVolume`}
            id={`forecastData[${index}].returnVolume`}
            value={formik.values.forecastData[index].returnVolume}
            onChange={e => handleInputChange(e.target.value, index)}
            onBlur={formik.handleBlur}
            onKeyUp={() => handleUpdateVolume(index)}
            disabled={readOnly}
            className={`${
              formik.errors.forecastData &&
              formik.errors.forecastData[index] &&
              formik.errors.forecastData[index].returnVolume
                ? 'has-error'
                : ''
            } input-volume`}
          />
          {formik.errors.forecastData &&
            formik.errors.forecastData[index] &&
            formik.errors.forecastData[index].returnVolume && (
              <div className="text-danger error-msg">
                {formik.errors.forecastData[index].returnVolume
                  ? formik.errors.forecastData[index].returnVolume
                  : ''}
              </div>
            )}
        </td>
        <td valign="middle">
          {formik?.values?.forecastData[index]?.returnVolume
            ? formatNumber(formik?.values?.forecastData[index]?.returnVolume)
            : item?.customerVolume
              ? formatNumber(item?.customerVolume)
              : '-'}
        </td>
        <td valign="middle">
          {item?.forecastPrice
            ? formatNumber(item?.forecastPrice)
            : `${t('Will be available soon')}`}
        </td>
      </tr>
    );
  };

  useEffect(() => {
    let allEmpty = formik.values.forecastData.every(item => !item.returnVolume);
    if (allEmpty) {
      sessionStorage.setItem('isEditing', false);
      setDisable(true);
    }
  }, [formik.values]);
  useEffect(() => {
    sessionStorage.setItem('isEditing', false);
    handleDateCheck();
    handlegetData();
  }, [forecastDate, forecastData]);

  return (
    <div>
      <Card className="cstm-card mt-3">
        <CardBody>
          {(formik.values.forecastData &&
            formik.values.forecastData.length <= 0) ||
          forecastData.length == 0 ||
          loading ? (
            <div className="no-data-view">
              <img
                src={!loading ? NoData : EmptyIcon}
                className=""
                height={100}
              />
              {!loading ? (
                <span className="font-regular text-center">
                  {t('No data yet')}
                </span>
              ) : (
                <span className="font-regular text-center">
                  {' '}
                  {t('Forecast is being generated.')} <br />
                  {t('This usually appears the next day')}
                </span>
              )}
            </div>
          ) : (
            <>
              <form onSubmit={formik.handleSubmit}>
                <Table borderless>
                  <thead>
                    <tr>
                      <td valign="top" className="forecast-header">
                        {t('Tijdvak')}
                      </td>
                      <td
                        valign="top"
                        className="forecast-header text-transform-none">
                        {t('VOLUME')} (kWh)
                      </td>
                      <td valign="top" className="forecast-header">
                        Correctie (volume)
                        <span className="ms-1 cursor-pointer" role="text">
                          <IoMdInformationCircle
                            size={20}
                            color="#fbbb36"
                            id="info-table"
                          />
                        </span>
                      </td>
                      <td
                        valign="top"
                        className="forecast-header text-transform-none">
                        {t('GECORRIGEERD VOLUME')} (kWh)
                      </td>
                      <td
                        valign="top"
                        className="forecast-header text-transform-none">
                        {t('PRICE')} (€ / kWh)
                      </td>
                    </tr>
                  </thead>
                  <tbody className="forecast-content">
                    {formik?.values?.forecastData.map((item, index) =>
                      forecastTable(item, index),
                    )}
                  </tbody>
                </Table>
                {!loading ? (
                  <div className="text-end w-100">
                    <Button
                      type="submit"
                      className="btn-submit mx-3 px-5"
                      disabled={disable}>
                      {t('Save')}
                    </Button>
                  </div>
                ) : (
                  ''
                )}
                <Tooltip
                  isOpen={tooltipOpen}
                  target="info-table"
                  toggle={toggle}>
                  {t('You can’t make changes after 8:00 AM')}
                </Tooltip>
              </form>
            </>
          )}
        </CardBody>
      </Card>
      {isOpen && (
        <ConfirmationPopup
          type="success"
          title="Changes Saved"
          content={t('Your changes have been saved successfully.')}
          confirmationText2="OK"
          icon={<IoIosCheckmarkCircle size={25} color="#06C202" />}
          isOpen={isOpen}
          handleClose={handleClose}
          handleSave={formik.handleSubmit}
        />
      )}
      {forecastLoading && <Loader isOpen={forecastLoading} />}
    </div>
  );
};

export default ForecastCorrection;
