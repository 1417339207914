import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';
import Search from '../assests/images/miscellaneous/search.svg';


const ZeroDataPage = ({ loading = true }) => {
  const { t } = useTranslation();
  return (
    <div className="user-details h-100">
      <Card className="company-details h-100 d-flex justify-content-center">
        <CardBody className="h-100 d-flex flex-column justify-content-center align-items-center font-regular fs-16">
          {loading &&<img src={Search} className="d-block" height={100} />}
          {loading ? t('Loading connections...') : (
            <p className='text-center'>{t(`No connections were found at this address. Please add another address to retrieve the connections.`)}
            <br/>{t(`If you can't find the correct connection, please contact`)} <a href='mailto:contact@powerhive.energy'>contact@powerhive.energy</a> .
            <br/>
             {t('Note: PowerHive currently only facilitates large consumption connections (>3x80A).')}
            </p>

          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default ZeroDataPage;
