import React, { Component } from 'react';
import './styles.scss';
import { Card, CardBody } from 'reactstrap';
import { BiSolidError } from 'react-icons/bi';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, info: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    this.setState({ error, info });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-page">
          <Card className="cstm-card">
            <CardBody className="p-4">
              <div className="text-center">
                <BiSolidError color="#fbbb36" size={50} />
              </div>
              <h1 className="text-theme font-regular">Something went wrong.</h1>
              <h4 className="font-regular  text-center">
                <span className="text-danger">{this.props.fallback} :</span>{' '}
                {this.state.error?.message}
              </h4>
            </CardBody>
          </Card>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
