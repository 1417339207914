import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import closeIcon from '../../assests/images/icons/close.svg';
import { useTranslation } from 'react-i18next';

const ConfirmationPopup = ({
  type = '',
  title = '',
  content = '',
  confirmationText1 = '',
  confirmationText2 = '',
  icon = '',
  isOpen = {},
  handleClose = {},
  handleSave = {},
}) => {
  const {t} = useTranslation()
  return (
    <Modal isOpen={isOpen} centered backdrop className="mw-350">
      <ModalBody>
        <span role="button" className="close-button">
          <img src={closeIcon} height={25} onClick={handleClose} />
        </span>
        <div className="d-flex align-items-end justify-content-start">
          <span className="me-2">{icon}</span>{' '}
          <h5
            className={`${type == 'success' ? 'text-success' : ''} mb-0 font-bold`}>
            {t(`${title}`)}
          </h5>
        </div>
        <p className="pt-3 font-regular">{content}</p>
        <div className="d-flex justify-content-end">
          {confirmationText1 && (
            <Button className="btn-previous px-3 mx-3" onClick={handleClose}>
              {t(`${confirmationText1}`)}
            </Button>
          )}
          {confirmationText2 == 'OK' && type=='success' ? (
            <Button
              className="btn-submit px-4"
              type="submit"
              onClick={handleClose}>
              {t(`${confirmationText2}`)}
            </Button>
          ) : (
            <Button
              className="btn-submit px-4"
              type="submit"
              onClick={handleSave}>
             {t(`${confirmationText2}`)}
            </Button>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmationPopup;
