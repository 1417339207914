import React, { useEffect, useState } from 'react';
import logo from '../../assests/images/homePageImages/logo.svg';
import './style.scss';
import { Link, useNavigate } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';

const TermsAndConditions = () => {
  const navigate = useNavigate();
  const [hideBack, setHideBack] = useState(false);
  useEffect(() => {
    if (window.location.pathname == '/terms&Conditions') {
      setHideBack(true);
    } else {
      setHideBack(false);
    }
    console.log(window.location);
  }, []);
  console.log('navigate', navigate);
  return (
    <div className="terms-and-conditions">
      <div className="terms-header">
        {!hideBack&&<div className="btn-back cursor-pointer" onClick={() => navigate(-1)}>
          <IoArrowBack color="#fff" size={20} className="mb-1 me-2" />
          <span className="btn-back-text">Back</span>
        </div>}
        <div className="logo-image">
          <img src={logo} alt="logo" />
        </div>
        <div className="container">
          <h1>ALGEMENE VOORWAARDEN POWERHIVE PLATFORM</h1>
        </div>
      </div>
      <div className="container terms-and-conditions-container">
        <h2>Artikel 1. Definities</h2>
        <ol>
          <li>
            <p>
              <strong>PowerHive:</strong> PowerHive b.v., gevestigd te Woerden,
              KvK-nummer 93459017.
            </p>
          </li>
          <li>
            <p>
              <strong>Het PowerHive-platform:</strong> Het online IT-platform
              van PowerHive dat Klanten kunnen gebruiken voor het inkopen van
              energie, doen van energie transacties, het afnemen/kopen van
              energie-gerelateerde producten en diensten.
            </p>
          </li>
          <li>
            <p>
              <strong>Klant:</strong>degene met wie PowerHive de overeenkomst
              voor toegang tot het PowerHive-platform is aangegaan.
            </p>
          </li>
          <li>
            <p>
              {' '}
              <strong>Partijen:</strong>PowerHive en Klant samen.
            </p>
          </li>
          <li>
            <p>
              <strong>Overeenkomst PowerHive:</strong> De schriftelijke
              overeenkomst PowerHive Standard tussen PowerHive en Klant met
              inbegrip van alle wijzigingen en aanvulling daarop, waarop deze
              Algemene Voorwaarden van toepassing zijn en waarvan zij deel
              uitmaken.
            </p>
          </li>
          <li>
            <p>
              <strong>Diensten:</strong> Het toegang verlenen tot het PowerHive
              platform, het faciliteren van de registratie en afwikkeling van
              energietransacties, het faciliteren van de aan- en verkoop van
              hernieuwbare energie certificaten (Garanties van Oorsprong (GvO),
              Hernieuwbare Brandstof Eenheden (HBE)) en het faciliteren van
              energie management software ten behoeve van trade optimalisatie.
            </p>
          </li>
        </ol>

        <h2>Artikel 2. Toepasselijkheid</h2>
        <ol>
          <li>
            <p>
              Deze voorwaarden zijn van toepassing op alle aanbiedingen,
              pakketten, overeenkomsten en leveringen van diensten of producten
              door of namens PowerHive, tenzij nadrukkelijk anders
              overeengekomen.
            </p>
          </li>
          <li>
            <p>
              PowerHive en de Klant kunnen alleen afwijken van deze voorwaarden
              als dat schriftelijk is afgesproken/overeengekomen.
            </p>
          </li>
          <li>
            <p>
              PowerHive en de Klant sluiten de toepasselijkheid van de algemene
              voorwaarden van de Klant of van anderen uitdrukkelijk uit.
            </p>
          </li>
          <li>
            <p>
              Indien te eniger tijd een gedeelte van de overeenkomst op grond
              van toepasselijk recht ongeldig is dan wel op andere wijze niet
              afdwingbaar blijkt te zijn, tast dit de geldigheid en/of
              afdwingbaarheid van de resterende bepalingen van de overeenkomst
              niet aan. PowerHive zal in voorkomend geval, met inachtneming van
              het bepaalde Artikel 20 vervangende bepalingen vaststellen die de
              oorspronkelijke bepalingen in economisch opzicht zo dicht mogelijk
              benaderen.
            </p>
          </li>
          <li>
            <p>
              Bij strijdigheid tussen bepalingen in de overeenkomst voor een van
              de diensten van PowerHive en deze Algemene Voorwaarden prevaleren
              de bepalingen in de overeenkomst.
            </p>
          </li>
          <li>
            <p>
              Deze Algemene Voorwaarden zijn te vinden op de website van
              PowerHive
            </p>
          </li>
        </ol>

        <h2>Artikel 3. Geschiktheid</h2>
        <ol>
          <li>
            <p>
              Om gebruik te maken van de meeste van onze diensten, moet u een
              account bij ons aanmaken. Uw account kan een geld saldo bevatten.
              Om gebruik te maken van onze diensten, of om een account aan te
              maken, moet minimaal u aan de volgende voorwaarden voldoen
              ("Voorwaarden"):
            </p>
            <ol style={{ listStyleType: 'lower-alpha' }}>
              <li>
                <p>
                  Als individu, moet u oud genoeg zijn om wettelijk een bindend
                  contract te kunnen aangaan in Nederland
                </p>
              </li>
              <li>
                <p>
                  Als entiteit, moet u naar behoren georganiseerd en
                  rechtsgeldig bestaand zijn onder de toepasselijke wetten van
                  het rechtsgebied van uw organisatie,
                </p>
              </li>
              <li>
                <p>
                  Een actueel en geldig e-mailadres, mobiel telefoonnummer en
                  straatadres hebben
                </p>
              </li>
              <li>
                <p>
                  Volledige (teken) bevoegdheid en autoriteit hebben om deze
                  Voorwaarden aan te gaan zonder enige andere overeenkomst die u
                  hebt gemaakt te schenden,
                </p>
              </li>
              <li>
                <p>
                  uw account niet zult gebruiken ten voordele van iemand anders
                  dan uzelf,
                </p>
              </li>
              <li>
                <p>
                  uw account of wachtwoord niet met iemand anders zult delen,
                </p>
              </li>
              <li>
                <p>
                  de veiligheid van uw account zult handhaven, inclusief door
                  een sterk wachtwoord voor uw account te gebruiken dat u
                  nergens anders gebruikt,
                </p>
              </li>
              <li>
                <p>
                  {' '}
                  ons onmiddellijk zult informeren als u beveiligingsinbreuken
                  gerelateerd aan uw account ontdekt of vermoedt,
                </p>
              </li>
              <li>
                <p>
                  uw account enkel zult gebruiken ten behoeve van het vervullen
                  van de eigen (energie) behoefte en gerelateerd te zijn aan
                  bestaande of redelijkerwijs te verwachten fysieke in- of
                  verkoopposities.
                </p>
              </li>
              <li>
                <p>
                  verantwoordelijkheid zult nemen voor alle activiteiten die
                  plaatsvinden onder uw account, en alle risico's van elke
                  geautoriseerde of ongeautoriseerde toegang tot uw account
                  accepteert, voor zover maximaal toegestaan onder toepasselijk
                  recht, en
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              te allen tijde nauwkeurige, volledige, waarheidsgetrouwe en
              bijgewerkte informatie zult verstrekken (inclusief e-mailadres,
              mobiel telefoonnummer en straatadres) bij het gebruik van een
              account of dienst, inclusief bij het uitvoeren van een Handel,
              Storting, en Opname, en wanneer anders gevraagd door een scherm
              weergegeven binnen de diensten of op onze Platforms.
            </p>
          </li>
          <li>
            <p>
              De Klant is als enige verantwoordelijk voor alle handelingen en
              nalatigheden die plaatsvinden onder het account, en de Klant wordt
              geacht alle acties die met zijn account zijn uitgevoerd, zelf te
              hebben verricht.
            </p>
          </li>
          <li>
            <p>
              De Klant machtigt PowerHive om onderzoeken uit te voeren, direct
              of via derden, die noodzakelijk worden geacht om de identiteit van
              de Klant te verifiëren, de bovenstaande geschiktheidseisen te
              bevestigen, PowerHive te beschermen tegen fraude of andere
              financiële criminaliteit, en zoals PowerHive noodzakelijk acht om
              naleving van deze Voorwaarden en toepasselijke wetten te
              faciliteren. de Klant begrijpt en gaat ermee akkoord dat PowerHive
              actie kan ondernemen die redelijkerwijs noodzakelijk wordt geacht
              op basis van de resultaten van dergelijke onderzoeken, dat
              PowerHive geen verplichting heeft om de Klant te informeren over
              de resultaten van een onderzoek en dat de Klant uitdrukkelijk
              afstand doet van elke verplichting die PowerHive (of onze
              partners) mogelijk heeft om maatregelen te nemen, of de Klant te
              adviseren over eventuele mogelijke herstelmaatregelen. Wanneer
              PowerHive deze onderzoeken uitvoert, erkent de Klant en gaat ermee
              akkoord dat persoonlijke informatie kan worden vrijgegeven aan
              kredietreferentie- en fraudepreventie- of financiële
              criminaliteitsinstanties en dat deze instanties volledig kunnen
              reageren op onze vragen.
            </p>
          </li>
        </ol>

        <h2>Artikel 4. Deze overeenkomst</h2>
        <ol>
          <li>
            <p>
              De Overeenkomst komt tot stand op het moment dat de Klant een door
              PowerHive® gedaan aanbod aanvaardt.
            </p>{' '}
          </li>
          <li>
            <p>
              Deze Overeenkomst gaat in op de tussen Partijen overeengekomen
              ingangsdatum.
            </p>
          </li>
          <li>
            <p>
              Onverminderd het elders in deze Algemene Voorwaarden bepaalde en
              onverminderd artikel 6:265 BW kan PowerHive® het aangaan van de
              Overeenkomst weigeren dan wel de Overeenkomst vóór aanvang van de
              Levering ontbinden zonder jegens de Klant schadeplichtig zijn, dan
              wel te worden, indien:
            </p>
          </li>
          <li>
            <p>
              de Klant zich niet op een door PowerHive® aan te geven wijze
              identificeert en/of andere voor het aangaan van de Overeenkomst
              benodigde informatie niet dan wel onjuist verstrekt; of
            </p>
          </li>
          <li>
            <p>
              aannemelijk is dat de Klant één of meer bepalingen of
              voorschriften uit de Overeenkomst of wet- of regelgeving niet is
              nagekomen of zal nakomen.
            </p>
          </li>
          <li>
            <p>
              Indien de Overeenkomst met PowerHive® met twee of meer Partijen
              wordt aangegaan, is ieder van deze Partijen jegens PowerHive®
              hoofdelijk verbonden.
            </p>
          </li>
          <li>
            <p>
              PowerHive® is gerechtigd om de Overeenkomst dan wel alle rechten
              en verplichtingen uit de Overeenkomst geheel of gedeeltelijk over
              te dragen, voor zover mogelijk met beperkte rechten te bezwaren
              ten gunste van een derde of bij wijze van contractsoverneming (ex
              artikel 6:159 BW) door een derde te laten overnemen, dan wel door
              middel van een fusie (ex artikel 2:309 BW) of splitsing (ex
              artikel 2:334a BW). De Klant gaat onherroepelijk en
              onvoorwaardelijk akkoord met een zodanige overdracht, bezwaring,
              contractsoverneming, fusie of splitsing en zal daaraan
              onherroepelijk en onvoorwaardelijk medewerking verlenen. Een
              eventuele overdracht van zeggenschap over PowerHive® en haar
              activa geeft de Klant geen recht tot beëindiging van de
              Overeenkomst.
            </p>
          </li>
          <li>
            <p>
              In geval van een verhuizing, contractsoverneming ex artikel 6:159
              BW of bedrijfsbeëindiging door de Klant, kan PowerHive® de
              Overeenkomst wijzigen overeenkomstig de nieuwe situatie c.q.
              omstandigheden. PowerHive® is gerechtigd eventuele kosten ten
              gevolge van deze contractwijziging(en) op de Klant te verhalen.
            </p>
          </li>
        </ol>

        <h2>Artikel 5. Tarieven en betaling</h2>
        <ol>
          <li>
            <p>
              PowerHive hanteert prijzen in euro's, exclusief btw en exclusief
              overige belastingen.
            </p>
          </li>
          <li>
            <p>
              De administratie van PowerHive vormt sluitend bewijsmateriaal voor
              de door Klant te betalen bedragen voor de diensten van het
              PowerHive-platform.
            </p>
          </li>
          <li>
            <p>
              Klant gaat akkoord met het betalen van alle relevante tarieven en
              belastingen gelinkt aan het gebruik van de producten en diensten
              van PowerHive;
            </p>
          </li>
          <li>
            <p>
              PowerHive zal de kosten automatisch in rekening brengen en
              verrekenen met het openstaande saldo dat door de Klant aangehouden
              wordt op het PowerHive platform.
            </p>
          </li>
          <li>
            <p>
              PowerHive mag de prijzen van zijn diensten en producten op zijn
              website en in andere uitingen altijd eenzijdig wijzigen.
            </p>
          </li>
          <li>
            <p>
              PowerHive mag de prijzen voor leveren van de diensten op het
              PowerHive platform ten alle tijden wijzigen. PowerHive zal
              prijsaanpassingen meedelen aan de Klant, 1 maand voorafgaand aan
              de ingang ervan.
            </p>
          </li>
          <li>
            <p>
              Klant mag de overeenkomst met PowerHive ongedaan maken wanneer hij
              het niet eens is met de prijswijziging.
            </p>
          </li>
          <li>
            <p>
              Klant is niet gerechtigd tot opschorting van enige betaling en
              evenmin tot verrekening van verschuldigde bedragen.
            </p>
          </li>
          <li>
            <p>
              Indien Klant zijn huidige (betalings-)verplichtingen onder deze
              overeenkomst of een andere overeenkomst met PowerHive niet of niet
              volledig is nagekomen, is Klant onmiddellijk in verzuim en is
              PowerHive gerechtigd deze overeenkomst (of een andere overeenkomst
              tussen klant en PowerHive) zonder rechterlijke tussenkomst te
              ontbinden en/of de leveringen van elektriciteit en/of het
              uitvoeren van diensten onmiddellijk te staken, met inachtneming
              van het bepaalde in Artikel 5.11.
            </p>
          </li>
          <li>
            <p>
              Aangeven dat de Klant heeft geen toegang meer tot portaal al
              hebben, of alleen lezen;
            </p>
          </li>
          <li>
            <p>
              Bij uitblijven van betaling of niet-tijdige betaling door de
              Klant, wordt zonder verdere ingebrekestelling de wettelijke
              handelsrente over het openstaande bedrag verschuldigd. Blijft
              betaling na aanmaning of ingebrekestelling achterwege, dan mag
              PowerHive de vordering ter incasso uit handen geven, waarbij de
              Klant aansprakelijk is voor alle daaruit voortvloeiende kosten,
              inclusief kosten voor ingehuurde externe experts;
            </p>
          </li>
          <li>
            <p>
              Alvorens PowerHive overgaat tot uitoefening van haar in Artikel
              5.11 genoemde recht(en), zal zij haar voornemen hiertoe
              schriftelijk aan Klant verkondigen. PowerHive zal geen gebruik
              maken van genoemd(e) recht(en) indien Klant binnen drie (3)
              werkdagen na dagtekening van de schriftelijke aankondiging alsnog
              volledig aan zijn verplichtingen heeft voldaan.
            </p>
          </li>
          <li>
            <p>
              De schriftelijke aankondiging als bedoeld in Artikel 5.12 laat het
              ingetreden verzuim van Klant onverlet.
            </p>
          </li>
          <li>
            <p>
              In het geval van ontbinding op grond van dit artikel blijft Klant
              gehouden tot nakoming van enige op Klant rustende verplichting
              ingevolgde deze overeenkomst en is de vordering van PowerHive ter
              zake onmiddellijk opeisbaar. Indien definitieve meetgegevens en
              allocaties niet beschikbaar zijn, zal de vordering in zoverre
              gebaseerd zijn op een redelijke schatting en volgt een definitieve
              afrekening op het moment de meetwaarden wel door de
              netbeheerder/meetbedrijf zijn vastgesteld;
            </p>
          </li>
          <li>
            <p>
              Bij wanbetaling behoudt PowerHive zich het recht voor om toegang
              tot diensten op te schorten of te beëindigen en/of de overeenkomst
              met onmiddellijke ingang en zonder nadere formaliteiten te
              ontbinden, met dien verstande dat de Klant nog steeds gehouden is
              aan eerder aangegane verplichtingen.
            </p>
          </li>
          <li>
            <p>
              Bij het beëindigen van de overeenkomst, het leveren van diensten
              en/of levering van energie, is PowerHive op geen enkele manier
              aansprakelijk voor (gevolg) schade in welke vorm of hoeveelheid
              dan ook.
            </p>
          </li>
        </ol>

        <h2>Artikel 6. Kredietwaardigheid</h2>
        <ol>
          <li>
            <p>
              PowerHive is gerechtigd bij aanvang en gedurende de looptijd van
              de overeenkomst, daaronder begrepen de periode tussen het aangaan
              van de overeenkomst en aanvang van de levering onder de
              overeenkomst, van Klant zekerheid te verlangen voor een tijdige en
              volledige betaling door Klant van de door deze afgenomen diensten,
              indien PowerHive daarvoor in redelijkheid gronden aanwezig acht.
            </p>
          </li>
          <li>
            <p>
              Als zekerheid kan PowerHive onder meer vooruitbetaling verlangen,
              dan wel hogere borgstelling of een bankgarantie. Klant draagt de
              volledige kosten van de zekerheidsstelling.
            </p>
          </li>
          <li>
            <p>
              De aard, omvang en de voorwaarden waaronder de te stellen
              zekerheden worden verstrekt zijn vastgelegd in het PowerHive
              Handboek.
            </p>
          </li>
        </ol>

        <h2>Artikel 7. Opschortingsrecht</h2>
        <ol>
          <li>
            <p>
              De Klant doet hierbij afstand van het recht om de nakoming van
              enige uit deze overeenkomst voortvloeiende verbintenis op te
              schorten.
            </p>
          </li>
        </ol>

        <h2>Artikel 8. Verrekening</h2>
        <ol>
          <li>
            <p>
              De Klant doet afstand van zijn recht om een schuld aan PowerHive
              te verrekenen met een vordering op PowerHive.
            </p>
          </li>
        </ol>

        <h2>Artikel 9. Uitvoering van de overeenkomst</h2>
        <ol>
          <li>
            <p>
              PowerHive verbindt zich tot de beschikbaarstelling aan Klant van
              de in de overeenkomst vastgestelde levering van diensten en
              energie gedurende de looptijd van de overeenkomst, met
              inachtneming van het in dit artikel bepaalde.
            </p>
          </li>
          <li>
            <p>
              PowerHive voert de overeenkomst naar beste inzicht en vermogen en
              volgens de eisen van goed vakmanschap uit.
            </p>
          </li>
          <li>
            <p>
              PowerHive mag de afgesproken dienstverlening in zijn geheel of
              deels laten uitvoeren door anderen.
            </p>
          </li>
          <li>
            <p>
              De uitvoering van de overeenkomst gebeurt in overleg en na een
              schriftelijk akkoord/ondertekening van het contract/de
              overeenkomsten betaling van een eventueel voorschot/borg/storting
              op platform door de Klant.
            </p>
          </li>
          <li>
            <p>
              De Klant moet ervoor zorgen dat PowerHive op tijd kan beginnen aan
              de uitvoering van de overeenkomst. Zorgt de Klant er niet voor dat
              PowerHive tijdig kan beginnen, dan komen de daaruit voortvloeiende
              extra kosten voor rekening van de Klant.
            </p>
          </li>
        </ol>

        <h2>
          Artikel 10. Gevolgen van de tekortkoming in de nakoming van de
          overeenkomst door de Klant
        </h2>
        <ol>
          <li>
            <p>
              Onverminderd de voor PowerHive® uit de wet voortvloeiende
              bevoegdheden in geval van een tekortkoming in de nakoming van één
              of meer verplichtingen door de Klant als neergelegd in de
              Overeenkomst, deze Algemene Voorwaarden en/of de
              Productvoorwaarden, is PowerHive® bevoegd: de Levering op te
              schorten; of de Overeenkomst te ontbinden.
            </p>
          </li>
          <li>
            <p>
              PowerHive® is eveneens bevoegd de Levering op te schorten en/of
              de Overeenkomst te ontbinden, indien de Klant uit hoofde van een
              ATO geen recht (meer) heeft op Aansluiting, geen goede
              Meetinrichting (meer) heeft en/of geen Erkend
              Meetverantwoordelijke voor zijn Meetinrichting en meetgegevens
              (meer) heeft.
            </p>
          </li>
          <li>
            <p>
              De in dit artikel bedoelde opschorting van de Levering wordt pas
              ongedaan gemaakt op het moment dat de Klant niet meer tekortschiet
              in de nakoming van zijn verplichtingen als neergelegd in de
              Overeenkomst, de Productvoorwaarden en deze Algemene Voorwaarden
              en de kosten en/of schade die voor PowerHive® redelijkerwijs
              voortvloeien uit de opschorting en hervatting van de Levering,
              alsmede eventueel door PowerHive® in verband daarmee geleden
              schade, geheel zijn voldaan. Bovendien kan PowerHive® aan de
              hervatting van de Levering voorwaarden verbinden.
            </p>
          </li>
          <li>
            <p>
              Het gebruik door PowerHive® van haar bevoegdheden als bedoeld in
              artikel 9 en artikel 10 van deze Algemene Voorwaarden leidt niet
              tot aansprakelijkheid van PowerHive® voor de eventueel daaruit
              voortvloeiende schade.
            </p>
          </li>
        </ol>

        <h2>Artikel 11. Installatie(s) en Meetinrichting</h2>
        <ol>
          <li>
            <p>
              De Klant staat ervoor in en garandeert dat zijn Installatie te
              allen tijde voldoet aan de wettelijke vereisten.
            </p>
          </li>
          <li>
            <p>
              Onverminderd het elders in deze Algemene Voorwaarden bepaalde, is
              PowerHive® niet aansprakelijk indien geen of onvoldoende gas
              en/of elektriciteit wordt geleverd ten gevolge van het niet of
              niet goed functioneren van de Installatie(s), een Aansluiting op
              een Net en/of het ontbreken van een ATO.
            </p>
          </li>
          <li>
            <p>
              De Klant staat ervoor in en garandeert dat voor zijn
              Aansluiting(en) voor zijn rekening en risico een Meetinrichting
              wordt geplaatst en onderhouden volgens de wettelijke vereisten
              omtrent een Meetinrichting en een Erkend Meetverantwoordelijke
              wordt aangewezen. Indien de Klant tekortschiet in de nakoming van
              deze verbintenis, is hij gehouden de door PowerHive® werkelijk
              geleden schade te vergoeden. Ook zal de Klant maandelijks een
              direct opeisbare boete van EUR 1.000 (duizend euro) verbeuren voor
              zover de Klant tekortschiet in de nakoming van deze verbintenis.
              Onverminderd het vorenstaande, heeft PowerHive eveneens de
              bevoegdheid de Overeenkomst te beëindigen indien de Klant
              tekortschiet in de nakoming van deze verbintenis.
            </p>
          </li>
          <li>
            <p>
              In geval van twijfel over de goede werking van de Meetinrichting
              en/of de juistheid van de meetgegevens kan elk der Partijen de
              Erkend Meetverantwoordelijke voor de Meetinrichting en meting bij
              de Klant, om opheldering verzoeken. Blijft twijfel over de
              juistheid van de Meetinrichting en/of meetgegevens bestaan, dan
              kan elk der Partijen verlangen dat de Meetinrichting en/of
              meetgegevens worden onderzocht door een voor beide Partijen
              aanvaardbare andere Erkend Meetverantwoordelijke of een voor beide
              Partijen aanvaardbare andere deskundige. Indien Partijen niet
              uiterlijk binnen twee (2) weken gezamenlijk een andere Erkend
              Meetverantwoordelijke of andere deskundige vinden, zal PowerHive®
              deze aanwijzen.
            </p>
          </li>
          <li>
            <p>
              Indien uit het onderzoek door de Erkend Meetverantwoordelijke of
              andere deskundige volgt dat de Meetinrichting geacht wordt goed te
              hebben gewerkt en/of de meting geacht wordt juist te zijn, zijn de
              kosten van dit onderzoek voor rekening van de Partij op wiens
              verzoek dit onderzoek is verricht. Indien uit het onderzoek volgt
              dat de Meetinrichting niet goed heeft gewerkt en/of de meting niet
              juist was, zijn de kosten van dit onderzoek voor rekening van de
              Klant.
            </p>
          </li>
          <li>
            <p>
              Indien blijkt dat de Meetinrichting niet goed heeft gewerkt en/of
              de meting niet juist is, stelt PowerHive® de omvang van de
              Levering vast aan de hand van de uitkomsten van het onderzoek.
              Herberekening zal plaatsvinden over de periode dat de
              Meetinrichting niet goed heeft gewerkt en/of de meting onjuist is
              geweest. In geval van fraude zal echter in overleg met de
              Netbeheerder respectievelijk de Neteigenaar herberekening over de
              volledige periode plaatsvinden.
            </p>
          </li>
          <li>
            <p>
              Indien het onderzoek geen hanteerbare maatstaf oplevert voor het
              vaststellen van de omvang van de Levering, is PowerHive®, na
              overleg met de Netbeheerder respectievelijk de Neteigenaar en de
              Erkend Meetverantwoordelijke (voor de Meetinrichting en meting bij
              de Klant), bevoegd de omvang van de Levering over het
              desbetreffende tijdvak in redelijkheid te schatten op basis van de
              ter beschikking van PowerHive® staande gegevens hieromtrent.
            </p>
          </li>
          <li>
            <p>
              Indien uit hoofde van de ATO of anderszins een onderzoek van de
              Meetinrichting en/of meetgegevens wordt verlangd, is de Klant
              verplicht dit PowerHive® onverwijld mede te delen en PowerHive®
              na afloop van het onderzoek onverwijld te informeren over de
              resultaten van het onderzoek.
            </p>
          </li>
        </ol>

        <h2>Artikel 12. Bepaling van de omvang van de Levering</h2>
        <ol>
          <li>
            <p>
              De omvang van de Levering wordt in de Meetinrichting op het
              Leveringspunt gemeten. De omvang van de Levering wordt bepaald aan
              de hand van de meetgegevens of het door de Netbeheerder / Erkend
              Meetverantwoordelijke opgegeven SJV / meetgegevens. De Klant
              verleent aan PowerHive® haar volledige medewerking zodat
              PowerHive® over alle relevante meetgegevens kan beschikken en
              opdat de omvang van de Levering goed kan worden vastgesteld.
            </p>
          </li>
          <li>
            <p>
              Indien PowerHive® niet tijdig kan beschikken over de voor hem
              relevante gegevens of indien bij het opnemen van de Meetinrichting
              dan wel bij het verwerken van de meetgegevens een kennelijke fout
              is gemaakt, is PowerHive® bevoegd de omvang van de Levering te
              schatten naar de ter beschikking van PowerHive® staande gegevens
              hieromtrent, onverminderd de plicht van PowerHive® om, zo
              mogelijk, het werkelijk geleverde alsnog overeenkomstig het
              bepaalde in artikel 9.1 van deze Algemene Voorwaarden vast te
              stellen en dat in rekening te brengen.
            </p>
          </li>
          <li>
            <p>
              Door het aangaan van de Overeenkomst machtigt de Klant PowerHive®
              tot het opvragen, verwerken, bewaren en delen van meetgegevens.
            </p>
          </li>
        </ol>

        <h2>Artikel 13. Informatieverstrekking door de Klant</h2>
        <ol>
          <li>
            <p>
              De Klant stelt alle informatie, gegevens en bescheiden die
              relevant zijn voor de correcte uitvoering van de overeenkomst
              tijdig en in gewenste vorm en op gewenste wijze beschikbaar aan
              PowerHive.
            </p>
          </li>
          <li>
            <p>
              De Klant staat in voor de juistheid en volledigheid van de ter
              beschikking gestelde informatie, gegevens en bescheiden, ook
              indien deze van derden afkomstig zijn, voor zover uit de aard van
              de overeenkomst niet anders voortvloeit.
            </p>
          </li>
          <li>
            <p>
              Wanneer en voor zover de Klant dit verzoekt, retourneert PowerHive
              de betreffende bescheiden.
            </p>
          </li>
          <li>
            <p>
              Stelt de Klant niet, niet tijdig of niet behoorlijk de door
              PowerHive redelijkerwijs verlangde informatie, gegevens of
              bescheiden beschikbaar en loopt de uitvoering van de overeenkomst
              hierdoor vertraging op, dan komen de daaruit voortvloeiende extra
              kosten voor rekening van de Klant.
            </p>
          </li>
        </ol>

        <h2>Artikel 14. Overdracht</h2>
        <ol>
          <li>
            <p>
              Het is een KLANT niet toegestaan de rechten en verplichtingen uit
              de overeenkomst aan een derde over te dragen zonder voorafgaande
              en uitdrukkelijke schriftelijke toestemming van de andere Partij.
            </p>
          </li>
        </ol>

        <h2>Artikel 15. Intellectuele eigendom</h2>
        <ol>
          <li>
            <p>
              Alle intellectuele eigendomsrechten (IE-rechten) van het
              PowerHive-platform zijn exclusief eigendom van PowerHive. De
              gebruiker krijgt enkel de gebruiksrechten die in deze Algemene
              Voorwaarden en bij wet specifiek zijn verleend. Deze rechten zijn
              niet-exclusief, niet overdraagbaar aan derden en niet
              sublicentieerbaar.
            </p>
          </li>
          <li>
            <p>
              PowerHive behoudt zich het recht voor om technische maatregelen te
              implementeren ter bescherming van het PowerHive-platform. Het is
              gebruikers verboden om dergelijke technische beveiligingen te
              omzeilen of te verwijderen. De gebruiker zal geen IE-rechten of
              enig ander recht met betrekking tot het PowerHive-platform
              aanvragen of proberen te verkrijgen.
            </p>
          </li>
          <li>
            <p>
              De gebruiker zal PowerHive onmiddellijk schriftelijk op de hoogte
              stellen als een onbevoegde derde toegang verkrijgt tot het
              PowerHive-platform en/of tot auteursrechtelijk beschermd materiaal
              of vertrouwelijke informatie van PowerHive.
            </p>
          </li>
          <li>
            <p>
              De gebruiker zal de IE-rechten, het PowerHive-platform en/of de
              reputatie van PowerHive niet schaden, misbruiken of in diskrediet
              brengen.
            </p>
          </li>
          <li>
            <p>
              Indien de gebruiker inbreuk maakt op de IE-rechten of op een
              andere manier onrechtmatig handelt met betrekking tot de
              IE-rechten, is de gebruiker direct - zonder enige formaliteit,
              inclusief maar niet beperkt tot een ingebrekestelling - een boete
              van EUR 25.000,- verschuldigd voor elke afzonderlijke inbreuk op
              de IE-rechten en daarnaast EUR 10.000,- voor elke dag of gedeelte
              van een dag dat de inbreuk voortduurt, onverminderd eventuele
              schadeclaims van PowerHive in verband met de inbreuk. Het totale
              boetebedrag dat op grond van dit artikel 15.5 op de gebruiker
              wordt verhaald, mag niet worden verrekend met de prijzen en
              vergoedingen die de gebruiker op grond van de overeenkomst(en) met
              PowerHive verschuldigd is.
            </p>
          </li>
          <li>
            <p>
              Indien de gebruiker kennis krijgt van een betwisting van de
              geldigheid van een IE-recht of van een inbreuk daarop, zal de
              gebruiker PowerHive hiervan onverwijld schriftelijk op de hoogte
              stellen. Tenzij anders schriftelijk overeengekomen tussen
              partijen, mag de gebruiker zelf geen maatregelen nemen of een
              inbreuk op IE-rechten erkennen, maar dient hij op eerste verzoek
              van PowerHive alle medewerking te verlenen bij het nemen van
              maatregelen tegen inbreuken op IE-rechten of bij andere juridische
              procedures tegen dergelijke inbreuken. Tenzij anders schriftelijk
              overeengekomen tussen partijen, mag de gebruiker geen toezeggingen
              doen aan of instemmen met een schikking met de eisende partij.
            </p>
          </li>
          <li>
            <p>
              De gebruiker garandeert dat geen rechten van derden zich verzetten
              tegen de beschikbaarstelling aan PowerHive van apparatuur,
              software, materialen bestemd voor websites (zoals afbeeldingen,
              tekst, muziek, domeinnamen, logo's, hyperlinks etc.),
              databestanden of andere materialen, waaronder ontwerpmateriaal,
              met het doel van gebruik, bewerking, installatie of integratie
              (bijvoorbeeld in een website). De gebruiker vrijwaart PowerHive
              tegen elke aanspraak van een derde die stelt dat een dergelijke
              beschikbaarstelling, gebruik, bewerking, installatie of integratie
              inbreuk maakt op enig recht van die derde.
            </p>
          </li>
          <li>
            <p>
              De gebruiker zal PowerHive verdedigen, vrijwaren en schadeloos
              stellen tegen alle claims, eisen, rechtszaken of procedures die
              tegen PowerHive worden ingesteld, en zal alle aansprakelijkheid,
              vonnissen, schade, kosten of onkosten die PowerHive aan de
              procederende partij moet betalen, inclusief redelijke
              advocaatkosten die voortvloeien uit een inbreuk op een
              intellectueel eigendom
            </p>
          </li>
        </ol>

        <h2>Artikel 16. Aansprakelijkheid PowerHive</h2>
        <ol>
          <li>
            <p>
              PowerHive is alleen aansprakelijk voor schade die de Klant lijdt
              wanneer die schade is veroorzaakt door opzet of bewuste
              roekeloosheid van PowerHive.
            </p>
          </li>
          <li>
            <p>
              Wanneer PowerHive aansprakelijk is voor schade, dan geldt dat
              alleen voor directe schade die verband houdt met de uitvoering van
              een onderliggende overeenkomst.
            </p>
          </li>
          <li>
            <p>
              PowerHive is niet aansprakelijk voor indirecte schade, zoals
              gevolgschade, gederfde winst of schade aan derden.
            </p>
          </li>
          <li>
            <p>
              De totale aansprakelijkheid van PowerHive in verband met een
              toerekenbare tekortkoming in de nakoming van de Overeenkomst
              Toegang PowerHive of een andere rechtsgrond, is beperkt tot
              directe schade en zal niet meer bedragen dan de onder de
              betreffende Overeenkomst Toegang PowerHive in rekening gebrachte
              bedragen over de laatste zes (6) maanden (exclusief BTW), met een
              maximale aansprakelijkheid voor directe schade van EUR 250.000,-.
            </p>
          </li>
          <li>
            <p>
              De aansprakelijkheid van PowerHive voor schade als gevolg van
              overlijden, lichamelijk letsel of wegens materiële beschadiging
              van zaken zal in totaal nooit meer bedragen dan EUR 1.250.000,-
              (één miljoen tweehonderdvijftigduizend Euro).
            </p>
          </li>
          <li>
            <p>
              Klant zal PowerHive vrijwaren, verdedigen en schadeloos stellen
              tegen en met betrekking tot alle vorderingen, eisen, rechtszaken
              en/of procedures die tegen PowerHive worden ingesteld met
              betrekking tot de Gegevens van Participant, en zal alle
              aansprakelijkheid, vonnissen, schade, kosten of onkosten die
              PowerHive aan de procederende Partij moet betalen, alsook
              redelijke daarmee samenhangende advocaatkosten, aan PowerHive
              vergoeden. De in dit artikel bedoelde uitsluitingen en beperkingen
              komen te vervallen indien en voor zover de schade het gevolg is
              van opzet of bewuste roekeloosheid van de bedrijfsleiding van
              PowerHive.
            </p>
          </li>
          <li>
            <p>
              Het vorderingsrecht van Klant met betrekking tot de diensten van
              het PowerHive -platform of anderszins uit hoofde van de
              Overeenkomst Toegang PowerHive vervalt in ieder geval uiterlijk
              drie (3) maanden na de dag waarop Participant bekend werd of in
              redelijkheid geacht kan worden bekend te zijn geworden met een
              dergelijke vordering.
            </p>
          </li>
          <li>
            <p>
              Het bepaalde in dit artikel alsmede alle andere beperkingen en
              uitsluitingen van aansprakelijkheid genoemd in deze Algemene
              Voorwaarden gelden mede ten gunste van alle (rechts)personen
              waarvan PowerHive zich bij de uitvoering van de Overeenkomst
              Toegang PowerHive bedient.
            </p>
          </li>
          <li>
            <p>
              Alle afbeeldingen, foto's, kleuren, tekeningen, omschrijvingen op
              de website of in een catalogus zijn slechts indicatief en kunnen
              niet leiden tot enige vergoeding, ontbinding of opschorting. Elk
              recht van de Klant op schadevergoeding van PowerHive vervalt 12
              maanden na de gebeurtenis waaruit de aansprakelijkheid direct of
              indirect voortvloeit. Hiermee wordt niet uitgesloten het bepaalde
              in artikel 6:89 BW.
            </p>
          </li>
        </ol>

        <h2>
          Artikel 17. Looptijd en Ontbinding van de Overeenkomst PowerHive
        </h2>
        <ol>
          <li>
            <p>
              De Klant mag de overeenkomst ongedaan maken wanneer PowerHive
              toerekenbaar tekortschiet in de nakoming van zijn verplichtingen,
              tenzij deze tekortkoming de ontbinding niet rechtvaardigt vanwege
              haar bijzondere aard of geringe betekenis.
            </p>
          </li>
          <li>
            <p>
              Is de nakoming van de verplichtingen door PowerHive nog mogelijk,
              dan kan ontbinding pas plaatsvinden nadat PowerHive in verzuim is.
            </p>
          </li>
          <li>
            <p>
              PowerHive mag de overeenkomst met de Klant ongedaan maken, wanneer
              de Klant zijn verplichtingen uit de overeenkomst niet volledig of
              niet tijdig nakomt, dan wel wanneer PowerHive kennis heeft genomen
              van omstandigheden die hem goede grond geven om aan te nemen dat
              de Klant zijn verplichtingen niet zal nakomen.
            </p>
          </li>
        </ol>

        <h2>Artikel 18. Overmacht</h2>
        <ol>
          <li>
            <p>
              In aanvulling op artikel 6:75 BW geldt dat een tekortkoming van
              PowerHive door de Klant niet aan PowerHive kan worden toegerekend
              wanneer er sprake is van overmacht.
            </p>
          </li>
          <li>
            <p>
              Onder overmacht als bedoeld in dit artikel wordt in ieder geval
              verstaan elke gebeurtenis of omstandigheid buiten de controle van
              de Partijen, die in redelijkheid niet had kunnen worden voorkomen.
              Deze omstandigheden betreffen, maar zijn niet beperkt tot,
              natuurgeweld, oorlog, gewelddadige acties, calamiteiten,
              stakingen, maatregelen van enige binnenlandse of buitenlandse
              overheid of een toezichthoudende instantie, boycotacties
              ongevallen of een gebeurtenis of een maatregel van de landelijke
              netbeheerder of enige andere netbeheerder van het
              elektriciteitstransportnet waardoor het transport van
              elektriciteit niet vrij en ongestoord heeft kunnen plaatsvinden,
              bijvoorbeeld als gevolg van het niet of niet goed functioneren van
              een elektriciteitsinstallatie, een aansluiting of een
              elektriciteitstransportnet, dan wel enige tekortkoming van
              landelijke netbeheerder of enige andere netbeheerder van het
              elektriciteitstransportnet in de nakoming van zijn/hun
              verplichtingen jegens de Klant of PowerHive, dan wel als gevolg
              van enig andere storing(en) in de technische keten van de
              elektriciteitsvoorziening.
            </p>
          </li>
          <li>
            <p>
              Wanneer zich een overmachtsituatie voordoet waardoor PowerHive 1
              of meer verplichtingen naar de Klant niet kan nakomen, dan worden
              die verplichtingen opgeschort totdat PowerHive kan nakomen.
            </p>
          </li>
          <li>
            <p>
              Vanaf het moment dat een overmachtsituatie ten minste 30
              kalenderdagen heeft geduurd, mogen zowel de Klant als PowerHive de
              overeenkomst schriftelijk in zijn geheel of deels ongedaan maken.
              PowerHive hoeft in een overmachtsituatie geen vergoeding aan de
              Klant te betalen, ook niet wanneer PowerHive hiervan voordeel
              heeft.
            </p>
          </li>
        </ol>

        <h2>Artikel 19. Vastlegging en gebruik persoonsgegevens</h2>
        <ol>
          <li>
            <p>
              PowerHive® legt in het kader van het aanbod, totstandkoming en/of
              uitvoering van de Overeenkomst en/of andere diensten gegevens van
              de Klant vast.
            </p>
          </li>
          <li>
            <p>
              PowerHive® maakt gebruik van het toegankelijke meetregister van
              Netbeheerders om de meterstanden respectievelijk het verbruik bij
              met name een verhuizing en een Leverancierswisseling zo
              gemakkelijk en eenduidig mogelijk te kunnen vaststellen. In de
              Informatiecode elektriciteit en gas, die geldig is per 1 juni
              2023, is opgenomen welke gegevens in het toegankelijk meetregister
              zijn opgenomen en door wie deze gegevens kunnen worden ingezien.
            </p>
          </li>
          <li>
            <p>
              De Klant heeft het recht kennis te nemen van de gegevens die op
              grond van artikel 13.3 van deze Algemene Voorwaarden van hem
              worden vastgelegd. Hij dient daartoe een schriftelijk verzoek,
              vergezeld van een kopie van een geldig legitimatiebewijs, aan
              PowerHive® te richten.
            </p>
          </li>
          <li>
            <p>
              Lees ons Privacybeleid voor informatie over hoe wij uw
              persoonlijke informatie verzamelen, gebruiken en delen. Ons
              Privacybeleid maakt door verwijzing deel uit van deze Voorwaarden.
            </p>
          </li>
        </ol>

        <h2>Artikel 20. Wijziging algemene voorwaarden</h2>
        <ol>
          <li>
            <p>
              PowerHive mag deze algemene voorwaarden eenzijdig en te allen
              tijde wijzigen.
            </p>
          </li>
          <li>
            <p>
              Bij wijzigingen van omstandigheden, waaronder begrepen, maar niet
              beperkt tot aanpassing van wet- of regelgeving (waaronder de
              Elektriciteitswet), is PowerHive gerechtigd één of meer bepalingen
              uit de Algemene Voorwaarden te wijzigen.
            </p>
          </li>
          <li>
            <p>
              PowerHive zal klant van een wijziging schriftelijk en tijdig,
              tenminste 1 maand vóór inwerkingtreding, in kennis stellen en
              daarbij aangeven vanaf welk moment de wijzigingen in werking
              treden.
            </p>
          </li>
          <li>
            <p>
              Een Klant mag bij een ingrijpende wijziging van de algemene
              voorwaarden de onderliggende overeenkomst opzeggen.
            </p>
          </li>
        </ol>

        <h2>Artikel 21. Overgang van rechten</h2>
        <ol>
          <li>
            <p>
              De Klant kan geen rechten uit een overeenkomst met PowerHive aan
              anderen overdragen zonder schriftelijke toestemming van PowerHive.
            </p>
          </li>
        </ol>

        <h2>Artikel 22. Gevolgen nietigheid of vernietigbaarheid</h2>
        <ol>
          <li>
            <p>
              Wanneer 1 of meerdere bepalingen van deze algemene voorwaarden
              nietig of vernietigbaar blijken, dan heeft dit geen invloed op de
              overige bepalingen van deze voorwaarden.
            </p>
          </li>
          <li>
            <p>
              Een bepaling die nietig of vernietigbaar is, wordt in dat geval
              vervangen door een bepaling die het dichtst in de buurt komt van
              wat PowerHive bij het opstellen van de voorwaarden op dat punt
              voor ogen had.
            </p>
          </li>
        </ol>

        <h2>Artikel 23. Toepasselijk recht en bevoegde rechter</h2>
        <ol style={{ marginBottom: '3rem' }}>
          <li>
            <p>
              Op deze algemene voorwaarden en iedere onderliggende overeenkomst
              tussen de Klant en PowerHive is Nederlands recht van toepassing.
            </p>
          </li>
          <li>
            <p>
              Eventuele uit de Overeenkomst voortvloeiende geschillen tussen
              Partijen, dan wel uit deze Algemene Voorwaarden voortvloeiende
              geschillen, dan wel uit de Productvoorwaarden voortvloeiende
              geschillen, dan wel uit nadere overeenkomsten die daarvan het
              gevolg zijn, die niet in onderling overleg opgelost kunnen worden,
              worden uitsluitend beslecht door de rechtbank te Rotterdam.
            </p>
          </li>
        </ol>

        <h2>Bijlage: Begripsomschrijvingen</h2>
        <p>
          De begrippen die in de Overeenkomst, deze Algemene Voorwaarden en de
          Productvoorwaarden met een hoofdletter zijn geschreven, zijn
          gedefinieerd. Begrippen die het enkelvoud aangeven zullen het meervoud
          omvatten en omgekeerd. In deze Algemene Voorwaarden wordt verstaan
          onder:
        </p>
        <ol>
          <li>
            <p>
              <strong>Aansluiting: </strong>één of meer verbindingen tussen een
              Net (beheerd door een Netbeheerder dan wel een ander dan die
              Netbeheerder) en een onroerende zaak als bedoeld in artikel 16,
              onderdelen a tot en met f van de Wet waardering onroerende zaken.
            </p>
          </li>
          <li>
            <p>
              <strong>ATO: </strong>een overeenkomst die de Klant heeft gesloten
              met de (regionale) Netbeheerder respectievelijk een Neteigenaar
              met betrekking tot - onder andere - de Aansluiting en het
              transport van elektriciteit of gas naar het punt waarop de
              Installatie op het Net van de Netbeheerder respectievelijk de
              Neteigenaar is aangesloten.
            </p>
          </li>
          <li>
            <p>
              <strong>Klant(s): </strong>de (rechts)persoon die een Overeenkomst
              met PowerHive® heeft afgesloten of voornemens is een Overeenkomst
              met PowerHive® af te sluiten en die beschikt over een aansluiting
              voor elektriciteit met een totale maximale doorlaatwaarde groter
              of meer dan 3*80A. Of een groep Klanten van
              kleinverbruikaansluitingen zoals bedoeld in artikel 95n van de
              Elektriciteitswet 1998. Of een groep Klanten waarvan één of meer
              aansluitingen met een doorlaatwaarde kleiner dan of gelijk aan 3 *
              80 A en ten minste één aansluiting heeft met een doorlaatwaarde
              groter dan 3 * 80 A.
            </p>
          </li>
          <li>
            <p>
              <strong>Algemene Voorwaarden: </strong>de onderhavige algemene
              voorwaarden voor de Levering van elektriciteit en/of gas aan
              Zakelijke Grootverbruikers.
            </p>
          </li>
          <li>
            <p>
              <strong>Erkend Meetverantwoordelijke: </strong>degene die conform
              het bepaalde in of krachtens de Meetcode elektriciteit, zoals deze
              geldt per 1 april 2023, of Meetcode gas RNB, zoals deze geldt per
              23 maart 2023, erkend is en onder andere bevoegd is de
              Meetinrichting te plaatsen en de Meetgegevens van
              Grootverbruikaansluitingen op te nemen.
            </p>
          </li>
          <li>
            <p>
              <strong>Grootverbruikaansluiting: </strong>een Aansluiting voor
              elektriciteit met een totale maximale doorlaatwaarde groter of
              meer dan 3*80A en/of een Aansluiting voor gas met een totale
              maximale capaciteit groter of meer dan 40 m3(n) per uur.
            </p>
          </li>
          <li>
            <p>
              <strong>Installatie: </strong>de elektrische installatie en/of het
              gastechnisch materiaal omvattende het samenstel van leidingen en
              bijbehoren van leidingen te rekenen na de Meetinrichting bezien
              vanuit het Net of een daarmee tussen de Netbeheerder en de Klant
              overeengekomen gelijk te stellen plaats van overgang met inbegrip
              van de aansluitleiding naar een gasverbruikend apparaat indien
              deze aansluitleiding door de Klant is aangebracht.
            </p>
          </li>
          <li>
            <p>
              <strong>Leverancierswisseling: </strong>de door de Netbeheerder
              tot stand gebrachte switch van de Klant van zijn voorgaande
              leverancier van elektriciteit en/of gas naar PowerHive®, of van
              PowerHive® naar een andere leverancier van elektriciteit en/of
              gas.
            </p>
          </li>
          <li>
            <p>
              <strong>Levering: </strong>de beschikbaarstelling op het Net, niet
              zijnde het transport, van de tussen PowerHive® en de Klant
              overeengekomen hoeveelheid elektriciteit en/of gas.
            </p>
          </li>
          <li>
            <p>
              <strong> Overeenkomst: </strong> de overeenkomst tussen
              PowerHive® en de Klant inzake de koop, verkoop en levering van
              elektriciteit en/of gas en aanverwante diensten, inclusief
              bijlage(n).
            </p>
          </li>
          <li>
            <p>
              <strong>Meetinrichting: </strong>het gehele samenstel van
              apparatuur dat tot doel heeft de uitgewisselde hoeveelheid
              elektriciteit en/of gas te meten.Net: een (transport)net als
              omschreven in artikel 1, lid 1, sub i van de Elektriciteitswet
              1998, dan wel als omschreven in artikel 1, lid 1, sub d van de
              Gaswet.
            </p>
          </li>
          <li>
            <p>
              <strong>Netbeheerder: </strong>degene die op grond van de
              artikelen 10, 13 of 14 van de Elektriciteitswet 1998 en/of op
              grond van de artikelen 2, 5 of 6 van de Gaswet is aangewezen voor
              het beheer van één of meer Netten.
            </p>
          </li>
          <li>
            <p>
              <strong>Neteigenaar: </strong>degene aan wie de eigendom
              toebehoort van het Net als bedoeld in artikel 10 lid 3 jo. artikel
              15 van de Elektriciteitswet 1998 en/of artikel 2 lid 1 van de
              Gaswet, waarop de Installatie is aangesloten.
            </p>
          </li>
          <li>
            <p>
              <strong>Onbalans: </strong>het verschil tussen het
              Energieprogramma en de gerealiseerde som van afname op alle of
              enkele netaansluitpunt(en) / verzamelpunt(en) van een
              Programmaverantwoordelijke, gedurende een bepaalde tijdsperiode.
              Wordt deze term gebruikt?
            </p>
          </li>
          <li>
            <p>
              <strong>Partij: </strong>de Klant en PowerHive® zijn ieder een
              Partij. Gezamenlijk worden zij aangeduid als Partijen.
            </p>
          </li>
          <li>
            <p>
              <strong>Perceel: </strong>de roerende of onroerende zaak, gedeelte
              of samenstel daarvan ten behoeve waarvan de Levering geschiedt of
              zal geschieden.
            </p>
          </li>
          <li>
            <p>
              <strong>Productvoorwaarden: </strong>de door PowerHive®
              gehanteerde productvoorwaarden voor elektriciteit en/of gas
              grootverbruik zakelijk, bestaande uit, maar niet
              noodzakelijkerwijs beperkt tot, 'Productvoorwaarden Vast',
              'Productvoorwaarden Var-Klik', 'Productvoorwaarden Variabel' en
              'Productvoorwaarden Dynamisch'). De Productvoorwaarden zijn te
              vinden op de website van PowerHive® (
              <Link to="www.PowerHive.nl">www.PowerHive.nl</Link>).
            </p>
          </li>
          <li>
            <p>
              <strong>Programmaverantwoordelijkheid: </strong>de
              verantwoordelijkheid om programma's met betrekking tot de
              productie, het transport en het verbruik van elektriciteit en gas
              op te stellen of te doen opstellen ten behoeve van de
              netbeheerders en zich met inachtneming van de voorwaarden, bedoeld
              in artikel 31 Elektriciteitswet 1998, te gedragen overeenkomstig
              die programma's.
            </p>
          </li>
          <li>
            <p>
              <strong>Programmaverantwoordelijke: </strong>een natuurlijk
              persoon of rechtspersoon, niet zijnde een Netbeheerder, die door
              de Netbeheerder van het landelijk hoogspanningsnet is erkend als
              Programmaverantwoordelijke conform de Netcode elektriciteit, zoals
              deze geldt per 10 juni 2023.
            </p>
          </li>
          <li>
            <p>
              <strong>Standaardjaarverbruik ("SJV"): </strong>een schatting van
              het gemiddelde energieverbruik van de Klant.
            </p>
          </li>
          <li>
            <p>
              <strong>Standaardjaarinvoeding ("SJI"): </strong> een schatting
              van de gemiddelde energie opwek van de Klant.
            </p>
          </li>
          <li>
            <p>
              <strong>PowerHive®:</strong>PowerHive B.V., handelend onder de
              naam PowerHive, gevestigd te Woerden. Zie
              <Link to="www.PowerHive.energy">www.PowerHive.energy</Link>
            </p>
          </li>
          <li>
            <p>
              <strong>Zakelijk Grootverbruiker: </strong>de natuurlijke persoon
              handelend in de uitoefening van een beroep of bedrijf niet zijnde
              een consument als bedoeld in artikel 2 Richtsnoeren Redelijke
              Opzegvergoedingen Vergunninghouders, de eenmanszaak, de
              personenvennootschap, de rechtspersoon, de stichting, de
              vereniging, het kerkgenootschap of het overheidsorgaan waarmee
              PowerHive® een Overeenkomst heeft gesloten en die beschikken over
              een aansluiting voor elektriciteit met een totale maximale
              doorlaatwaarde groter of meer dan 3*80A. Of een groep Klanten van
              kleinverbruikaansluitingen zoals bedoeld in artikel 95n van de
              Elektriciteitswet 1998. Of een groep Klanten waarvan één of meer
              aansluitingen met een doorlaatwaarde kleiner dan of gelijk aan 3 *
              80 A en/of kleiner dan of gelijk aan 40m3 (n) per uur en ten
              minste één aansluiting heeft met een doorlaatwaarde groter dan 3 *
              80 A.
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default TermsAndConditions;
