import HeroSection from './AboutUsPage/HeroSection';
import FoldTwo from './AboutUsPage/FoldTwo';
import FoldThree from './AboutUsPage/FoldThree';
import HeaderWebsite from '../components/HeaderWebsite';
import FooterWebsite from '../components/FooterWebsite';
import CookieConsent from './CookieConstent';

const AboutUsPages = () => {
    return (
        <div className='aboutus-page'>
            <HeaderWebsite />
            <HeroSection />
            {/* <CookieConsent /> */}
            <FoldTwo />
            <FoldThree />
            <FooterWebsite />
        </div>

    );
};
export default AboutUsPages;
