import React from "react"
import logo from '../../assests/images/homePageImages/logo.svg';
import './style.scss';
import { Link, useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";

const PrivacyStatement = () => {
    const navigate = useNavigate()
    return (
        <div className="privacy-statement">
            <div className="privacy-header">
            <div className="btn-back cursor-pointer" onClick={()=>navigate(-1)}><IoArrowBack color="#fff" size={20} className="mb-1 me-2"/>Go Back</div>
                <div className="logo-image">
                    <img src={logo} alt="logo" />
                </div>
                <div className="container">
                    <h1>PRIVACYVERKLARING POWERHIVE PLATFORM</h1>
                </div>
            </div>
            <div className="container privacy-statement-container">
                <p>Deze privacyverklaring bevat informatie over de wijze waarop PowerHive B.V., gevestigd aan Prins Bernardlaan 15,
                    3441XG, Woerden (hierna: “wij” of “PowerHive”), persoonsgegevens verwerkt in het kader van haar bedrijfsvoering. Daaronder is mede begrepen de verwerking van gegevens via onze website www.powerhive.energy</p>
                <p>Wij zijn voor de verwerking van deze gegevens de zogenoemde verwerkingsverantwoordelijke in de zin van de
                    Algemene Verordening Gegevensbescherming (“AVG”). Wij verwerken uw gegevens zorgvuldig en in
                    overeenstemming met geldende privacywetgeving, waaronder de AVG.</p>
                <p>Indien u na het doornemen van onze privacyverklaring, of in algemenere zin, vragen hebt of contact met ons wenst
                    op te nemen dan kan dit via de aan het einde van deze privacyverklaring vermelde contactgegevens.</p>
                <h2>Persoonsgegevens die wij verwerken</h2>
                <p>Wij verwerken persoonsgegevens van (contactpersonen bij) onze voormalige, actuele alsmede potentiële klanten,
                    leveranciers en overige relaties, van sollicitanten, alsmede van websitebezoekers. Wij verkrijgen uw gegevens (i)
                    van u zelf, (ii) van derden (zoals bijvoorbeeld klanten en ketenpartners) of (iii) uit openbare bronnen, zoals
                    bijvoorbeeld de Kamer van Koophandel.</p>
                <ul>
                    <p>Hieronder vindt u een overzicht van de persoonsgegevens die wij mogelijk verwerken:</p>
                    <li><p>basisinformatie zoals uw voor- en achternaam, titel, geslacht;</p></li>
                    <li><p>contactgegevens zoals uw e-mailadres, postadres, telefoonnummer, (eventueel) het bedrijf waarvoor u
                        werkt en uw functie;</p></li>
                    <li><p>financiële informatie, zoals IBAN/bankrekeningnummer;</p></li>
                    <li><p>IP-adres en statistische gegevens over uw gebruik van onze website;</p></li>
                    <li><p>persoonsgegevens die u ons geeft als u bij ons solliciteert, zoals een sollicitatiebrief, CV, geboortedatum,
                        opleidings- en loopbaangegevens;</p></li>
                    <li><p>alle overige persoonsgegevens die wij van of over u verkrijgen en die wij gebruiken voor de hieronder
                        genoemde doeleinden.</p></li>
                </ul>
                <p>Wij verwerken niet altijd alle bovenstaande gegevens, dit is afhankelijk van het soort relatie, de dienst waar het
                    om gaat en (indien van toepassing) de toestemming die u ons heeft gegeven.</p>

                <h2>Met welk doel wij persoonsgegevens verwerken</h2>
                <p>PowerHive verwerkt bovenstaande persoonsgegevens voor een of meer van de volgende doeleinden (mede
                    afhankelijk van onze relatie met u):</p>
                <ul>
                    <li><strong>Marketing en acquisitiedoeleinden</strong><br /><p>Wij verwerken uw persoonsgegevens mogelijk voor het verzenden van nieuwsbrieven, uitnodigingen voor onze
                        (online) evenementen en overige marketingcommunicatie die voor u relevant kan zijn.</p></li>
                    <li><strong>Het uitvoeren van een overeenkomst</strong><br /><p>Wij verwerken uw gegevens voor zover dat nodig is in het kader van de door ons verleende diensten.</p></li>
                    <li><strong>Het voldoen aan wettelijke verplichtingen</strong><br /><p>Wij verwerken uw gegevens voor zover dat nodig is om te voldoen aan wettelijke verplichtingen. Afhankelijk van
                        onze relatie met u verplicht de wet ons om bijvoorbeeld informatie te verstrekken aan de belastingdienst,
                        openbare registers en toezichthouders.</p></li>
                    <li><strong>Sollicitaties en recruitment</strong><br /><p>De gegevens die u met ons deelt indien u bij ons solliciteert (via e-mail/post, onze website of via sociale media) of
                        die wij via recruiters ontvangen, verwerken we om contact met u op te nemen ten behoeve van onze
                        sollicitatieprocedure en om uw sollicitatie te behandelen. Ook verwerken we gegevens in het kader van (een
                        inschrijving voor) een van onze bijeenkomsten/evenementen.</p></li>
                    <li><strong>Gebruik en verbetering van onze website</strong><br /><p>Wij verwerken statistische gegevens over het bezoek aan en gebruik van onze website. Dit doen wij om de inhoud,
                        indeling en het gebruik van onze website te analyseren en te verbeteren.</p></li>
                    <li><strong>Overige verwerkingen</strong><br /><p>Wij verwerken uw gegevens ook om eventuele vragen of klachten te behandelen.</p></li>
                    <li><strong> Op basis van welke grondslag wij persoonsgegevens verwerken</strong><br /><p>Wij verwerken uw persoonsgegevens op basis van een dan wel meerdere van de volgende wettelijke grondslagen:</p>
                        <ul>
                            <li><p>de uitvoering van een overeenkomst met u of om de overeenkomst met u te kunnen sluiten;</p></li>
                            <li><p>om te kunnen voldoen aan wettelijke verplichtingen;</p></li>
                            <li><p>een gerechtvaardigd belang;</p></li>
                            <li><p>uw toestemming</p></li>
                        </ul>
                    </li>
                </ul>

                <h2>Het delen van uw persoonsgegevens</h2>
                <p>In sommige gevallen delen wij uw persoonsgegevens met derden. Dit zullen wij enkel doen wanneer wij dit
                    noodzakelijk achten of wanneer wij hiertoe verplicht zijn op grond van wet- en regelgeving. Wij delen uw gegevens
                    uitsluitend met betrouwbare partijen en zorgen ervoor dat het proces van delen van deze gegevens met passende
                    waarborgen is omkleed. Wij delen uw persoonsgegevens mogelijk met:</p>
                <ul>
                    <li><p>overheidsinstanties zoals de belastingdienst;</p></li>
                    <li><p>opdrachtgevers en klanten van PowerHive;</p></li>
                    <li><p>ketenpartners en overige dienstverleners zoals banken, verzekeraars, accountants, belastingadviseurs en
                        overige adviseurs;</p></li>
                    <li><p>externe leveranciers zoals ICT-dienstverleners, hosting- en clouddienstverleners dan wel andere
                        leveranciers aan wie wij ondersteunende diensten uitbesteden.</p></li>
                </ul>
                <p>Wanneer de derde waarmee uw gegevens worden gedeeld aangemerkt wordt als een verwerker in de zin van de
                    AVG, zullen wij bovendien een verwerkersovereenkomst sluiten met deze derde.</p>

                <h2>Doorgifte persoonsgegevens buiten de Europese Unie</h2>
                <p>Indien noodzakelijk kunnen persoonsgegevens worden doorgegeven aan partijen buiten de Europese Economische
                    Ruimte (“EER”). Wanneer wij dit zelf rechtstreeks doen dan zullen wij de maatregelen treffen die nodig zijn om te
                    voldoen aan deze privacyverklaring en de toepasselijke (privacy)wetgeving.</p>
                <h2>Bewaartermijnen</h2>
                <p>Wij bewaren uw persoonsgegevens in overeenstemming met de AVG en specifieke dwingende wettelijke
                    bewaartermijnen. De gegevens worden niet langer bewaard dan strikt noodzakelijk is om de doelen te bereiken
                    waarvoor de gegevens verzameld zijn en om aan wettelijke verplichtingen te voldoen.</p>
                <h2>Uw privacyrechten</h2>
                <p>Op grond van de AVG heeft u een aantal rechten. U heeft het recht ons te verzoeken om inzage, rectificatie,
                    wissing, beperking en overdraagbaarheid van uw persoonsgegevens. Ook heeft u het recht bezwaar te maken
                    tegen de verwerking van uw persoonsgegevens. Indien u gebruik wenst te maken van deze rechten kunt u contact
                    met ons opnemen. Onze contactgegevens vindt u aan het einde van deze privacyverklaring.</p>
                <p>Het is mogelijk dat wij niet (kunnen) voldoen aan uw verzoek. Bijvoorbeeld omdat de verwerking van bepaalde
                    persoonsgegevens vereist is om aan onze wettelijke verplichtingen te voldoen, of vanwege andere uitzonderingen,
                    zoals de rechten en vrijheden van derden of de legitieme belangen van PowerHive. Mochten we aan een bepaald
                    verzoek geen gehoor (kunnen) geven, dan zullen we dit uiteraard gemotiveerd aan u kenbaar maken.</p>
                <p>Voor zover de verwerking van uw persoonsgegevens gebaseerd is op toestemming, heeft u te allen tijde het recht
                    deze toestemming weer in te trekken. Een eventuele intrekking van uw toestemming doet geen afbreuk aan de
                    rechtmatigheid van de eerdere verwerkingen op basis daarvan.</p>
                <p>U heeft ook het recht een klacht in te dienen bij de toezichthoudende autoriteit: de Autoriteit Persoonsgegevens
                    (“AP”). Meer informatie hierover is te vinden op de website van de AP
                    (<Link to="https://autoriteitpersoonsgegevens.nl/">https://autoriteitpersoonsgegevens.nl/</Link>). Uiteraard zouden wij het fijn vinden als u eerst contact met ons op
                    zou nemen, zodat wij kunnen kijken of wij er gezamenlijk uit kunnen komen</p>

                <h2>Beveiliging van uw persoonsgegevens</h2>
                <p>Wij nemen de bescherming van uw persoonsgegevens serieus en nemen passende beveiligingsmaatregelen om
                    misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan.</p>
                <p>Als u het idee heeft dat uw gegevens toch niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neemt u
                    dan contact op via onze contactgegevens.</p>

                <h2>Cookies</h2>
                <p>Wij maken op onze website gebruik van cookies en/of vergelijkbare technieken. Een cookie is een klein
                    tekstbestandje dat opgeslagen wordt op het device (computer, tablet of smartphone) waarmee u onze website
                    bezoekt. Wij gebruiken cookies om inhoud en advertenties te personaliseren, om functies voor sociale media aan
                    te bieden en om het websiteverkeer te analyseren. Wij gebruiken functionele cookies, analytische cookies, tracking
                    cookies en social media plugins.</p>
                <p>De functionele cookies die wij gebruiken zijn noodzakelijk voor het deugdelijk functioneren van onze website en
                    het gebruiksgemak. Ze zorgen er bijvoorbeeld voor dat de website goed werkt en onthouden voorkeuren</p>
                <p>Met behulp van analytische cookies verzamelen wij statistische gegevens over het gebruik van onze website, zodat
                    wij onze dienstverlening kunnen verbeteren. Wij gebruiken analytische cookies om inzicht te krijgen in uw website activiteiten om de gebruikservaring van onze diensten blijvend te analyseren en te verbeteren.</p>
                <p>Door middel van tracking cookies verzamelen wij informatie over het surfgedrag van bezoekers zodat wij op maat
                    gemaakte content en advertenties kunnen aanbieden. Hiervoor is toestemming van de bezoeker vereist.</p>
                <p>Via uw browser kunt u het plaatsen van cookies uitschakelen. Het kan dan wel zo zijn dat bepaalde
                    functionaliteiten van onze website niet meer goed werken.</p>

                <h2>Wijzigingen in deze privacyverklaring</h2>
                <p>Wij kunnen deze privacyverklaring van tijd tot tijd wijzigen. Wijzigingen zullen op onze website worden
                    gepubliceerd. Het is daarom aan te raden om deze privacyverklaring regelmatig te raadplegen om van eventuele
                    wijzigingen op de hoogte te blijven. Wij zullen ons best doen wijzigingen ook apart aan te kondigen.</p>

                <h3>Contactgegevens</h3>
                <p>PowerHive</p>
                <p>Prins Bernardlaan 15 <br />
                    3441XG Woerden</p>
                <p>E:<Link to="mailto:contact@powerhive.energy">contact@powerhive.energy</Link>  </p>
            </div>

        </div>
    );
};
export default PrivacyStatement;