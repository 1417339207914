import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  Col,
  FormGroup,
  Input,
  Row,
  Label,
  InputGroup,
  InputGroupText,
  Button,
} from 'reactstrap';
import error from '../../assests/images/icons/Error.png';
import { Link, useNavigate } from 'react-router-dom';
import { RESET_PASS_URL, PROCESS_BASE_URL } from '../../config/constants';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { processAPI } from '../../utilities/api';
import { customerAPI, endpoints } from '../../utilities/Endpoints';
import Loader from '../../components/common/Loader';

const PersonalDetails = ({
  data = '',
  setChangePassword = {},
  fetchData = {},
}) => {
  const { t } = useTranslation();
  const [focused, setFocused] = useState(false);
  const [editDetails, setEditDetails] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  let regexPhone = /^\d{7,10}$/;
  let regexLettersOnly = /^[a-zA-Z]+$/;
  let lastNameRegex = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;

  const companyId = sessionStorage.getItem('companyId');
  const accessToken = sessionStorage.getItem('accessToken');
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(regexLettersOnly, t('Only alphabets allowed'))
      .max(200, t('Max. 200 characters allowed'))
      .min(1, t('Min. 1 character required'))
      .required(t('First Name is required')),
    lastName: Yup.string()
      .matches(lastNameRegex, t('Only alphabets and space allowed'))
      .max(200, t('Max. 200 characters allowed')),
    phone: Yup.string()
      .matches(regexPhone, t('Invalid Phone number'))
      .min(7, t('Min. 7 digits'))
      .max(10, t('Max. 10 digits')),
  });
  const formik = useFormik({
    initialValues: {
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      phone: data?.updatedContact
        ? data?.updatedContact
        : data?.phone
          ? data?.phone
          : '',
    },
    validationSchema: validationSchema,
    onSubmit: values => handleSubmit(values),
  });
  const { errors, touched, values, setFieldTouched } = formik;
  const firstName = data.firstName ? String(data.firstName).substring(0) : '';
  const lastName = data.lastName ? String(data.lastName).substring(0) : '';
  const phone = data.mobile ? data.mobile.substring(0) : '';
  const handleFocus = () => {
    setFocused(true);
  };
  const handleInputBlur = e => {
    if (!values?.phone) {
      setFocused(false);
    }
    setFieldTouched('phone', true);
  };
  const handleSubmit = async values => {
    setLoading(true);
    try {
      const modifiedValues = {
        firstName: values?.firstName,
        lastName: values?.lastName || '',
        phone: values?.phone,
      };
      const response = await processAPI.post(
        endpoints.updateUser,
        modifiedValues,
        {
          headers: {
            companyId: companyId,
            accessToken: accessToken,
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      if (response.status == 200) {
        setEditDetails(false);
        toast.success(t('Personal details updated successfully'));
        const timeout = setTimeout(() => {
          fetchData();
        }, 2000);
        return () => clearTimeout(timeout);
      }
    } catch (err) {
      toast.error(t('Unable to edit details.'));
      console.log('Error updating details', err);
    }
    setLoading(false);
  };
  const handlePasswordChange = () => {
    window.location.href = RESET_PASS_URL;
    sessionStorage.clear();
  };

  useEffect(() => {
    if (
      values?.firstName == firstName &&
      values?.lastName == lastName &&
      values?.phone == phone
    ) {
      setDisabled(true);
    } else if (errors?.firstName || errors.lastName || errors.phone) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [values, errors]);

  return (
    <form onSubmit={formik.handleSubmit} className="py-xl-5 tab-details">
      {loading && <Loader isOpen={loading} />}
      <Row className="form-container personal-details">
        <Col md={6}>
          <FormGroup floating className={`${!editDetails ? 'read-only' : ''}`}>
            <Input
              id="firstName"
              name="firstName"
              placeholder="First Name"
              type="text"
              onChange={e => {
                let str = e.target.value;
                if (str.charAt(0) == ' ') {
                  return;
                }
                formik.setFieldValue('firstName', e.target.value);
              }}
              disabled={!editDetails}
              onBlur={formik.handleBlur}
              value={values.firstName}
              className={
                errors.firstName && touched.firstName ? 'has-error' : ''
              }
            />
            <Label for="firstName">{t('First Name')}</Label>
            {errors.firstName && touched.firstName && (
              <div className="error-icon">
                <img src={error} />
              </div>
            )}
            <div className="text-danger error-msg">
              {errors.firstName && touched.firstName ? errors.firstName : ''}
            </div>
          </FormGroup>
        </Col>{' '}
        <Col md={6} className="">
          <FormGroup floating className={`${!editDetails ? 'read-only' : ''}`}>
            <Input
              id="lastName"
              name="lastName"
              placeholder="Last Name"
              type="text"
              className={errors.lastName && touched.lastName && 'has-error'}
              onChange={e => {
                let str = e.target.value;
                if (str.charAt(0) == ' ') {
                  return;
                }
                formik.setFieldValue('lastName', e.target.value);
              }}
              disabled={!editDetails}
              onBlur={formik.handleBlur}
              value={values.lastName}
            />
            <Label for="lastName">{t('Last Name')}</Label>
            {errors.lastName && touched.lastName && (
              <div className="error-icon">
                <img src={error} />
              </div>
            )}
            <div className="text-danger error-msg">
              {errors.lastName && touched.lastName ? errors.lastName : ''}
            </div>
          </FormGroup>
        </Col>
        <Col md={6} className={`${!editDetails ? 'read-only' : ''}`}>
          <div
            className={`floating-input ${focused || values.phone != '' ? 'focused' : ''}`}>
            <InputGroup
              className={`${errors.phone && touched.phone ? 'has-error' : ''}`}>
              <InputGroupText>+31</InputGroupText>
              <InputGroupText className="p-0 seperator"></InputGroupText>
              <Input
                id="phone"
                name="phone"
                type="text"
                className="phone-input"
                onChange={e => {
                  let str = e.target.value;
                  if (str.charAt(0) == ' ') {
                    return;
                  }
                  formik.setFieldValue('phone', e.target.value);
                }}
                disabled={!editDetails}
                onBlur={e => handleInputBlur(e)}
                value={values.phone}
                onFocus={handleFocus}
              />
              <Label for="phone" className="input-label">
                {t('Phone Number')}
              </Label>
            </InputGroup>
            {errors.phone && touched.phone && (
              <div className="error-icon">
                <img src={error} />
              </div>
            )}
            <div className="text-danger error-msg">
              {errors.phone && touched.phone ? errors.phone : ''}
            </div>
          </div>
        </Col>
        <Col md={6} className="">
          <FormGroup floating className="">
            <Input
              id="email"
              name="email"
              placeholder="Email Address"
              type="text"
              readOnly
              value={values.email}
            />
            <Label for="email">{t('Email Address')}</Label>
          </FormGroup>
          <div className="cstm-button text-end font-bold fs-14">
            <Link
              className="btn-link"
              // target="_blank"
              // to={RESET_PASS_URL}
              // onClick={() => setChangePassword(true)}
              to={'#'}
              target='_blank'
              onClick={handlePasswordChange}>
              {t('Change password')}
            </Link>
          </div>
        </Col>
      </Row>
      <div className="w-100 d-flex justify-content-end edit-container">
        {editDetails && (
          <Button
            type="button"
            className="btn-previous px-3 mx-3"
            onClick={() => {
              formik.setValues(formik.initialValues);
              setEditDetails(!editDetails);
            }}>
            {t('Cancel Changes')}
          </Button>
        )}
        {!editDetails ? (
          <Button
            className="btn-submit"
            type="button"
            onClick={e => {
              e.preventDefault();
              setEditDetails(!editDetails);
            }}>
            {t('Edit Details')}
          </Button>
        ) : (
          <Button
            className="btn-submit"
            type="submit"
            disabled={isDisabled}
            // onClick={() => setEditDetails(!editDetails)}
          >
            {t('Save Changes Edit')}
          </Button>
        )}
      </div>
    </form>
  );
};

export default PersonalDetails;
