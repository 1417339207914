import React, { useState } from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from 'reactstrap';
import './styles.scss';

const FoldSix = ({source=''}) => {
  const [open, setOpen] = useState('0');
  const toggle = id => {
    if (open == id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  const data = [
    {
      title:
        ' Wat maakt PowerHive uniek in vergelijking met andere energieoplossingen?',
      value: `PowerHive onderscheidt zich met een alles-in-één platform waarmee klanten direct toegang krijgen tot de energiemarkt, zonder tussenkomst van leverancier. Daarnaast kun je ook gemakkelijk je installaties (bijvoorbeeld zonnepanelen, windmolens of een batterij) aanmelden bij PowerHive voor optimale aansturing en bieden we een transparante marktplaats voor het verhandelen van hernieuwbare energiecertificaten.`,
      para1: `Onze focus ligt op transparantie, flexibiliteit en eenvoud voor onze klanten. Hiermee bieden we één geïntegreerde oplossing die kosten bespaart en jouw energieportfolio optimaliseert. Geen ingewikkelde contracten, altijd aanpasbaar en volledig in control.`,
    },
    {
      title: 'Hoe kan PowerHive mijn energiekosten verlagen?',
      value: `Door directe toegang tot groothandelsmarkten te bieden, snijdt
      PowerHive de tussenpersonen weg en minimaliseert zo de kosten.
      Bovendien bieden we geavanceerde data-analyse, forecasting en slimme
      aansturing van energie-installaties om energieverbruik en kosten te
      optimaliseren, wat resulteert in aanzienlijke kostenbesparingen voor
      onze klanten.`,
      para1: `Dit doen we via het PowerHive platform waar je moeiteloos en snel je
      energiezaken regelt. Onze innovatieve AI-platform regelt alle zaken
      voor jou. Hierdoor zijn we veel efficiënter zijn dan een
      traditionele energieleverancier.`,
    },
    {
      title: 'Hoe kan ik mijn energieportfolio beheren met PowerHive?',
      value: `Met PowerHive kunnen klanten moeiteloos hun energieportfolio beheren
      via ons intuïtieve platform. Dankzij automatisering en geavanceerde
      tools verhandelen we automatisch dagelijks jouw benodigde energie
      zonder dat je daar zelf iets voor hoeft te doen. Met PowerHive
      koppel je gemakkelijk je installaties aan op je account en zal onze
      AI-algoritme deze slim aansturen. Daarmee optimaliseren we je
      energiebehoefte op basis van marktsignalen om zo kosten te besparen.`,
    },
    {
      title: `Zijn er risico's verbonden aan het gebruik van PowerHive?`,
      value: `Hoewel PowerHive risico's zoals marktvolatiliteit en operationele
      risico's kan helpen beheren, blijft energiehandel inherent
      risicovol. Deze risico’s worden altijd ruim ingeprijsd in een
      regulier energiecontract. Bij PowerHive is dit risico voor jou, maar
      met geavanceerde tools en inzichten helpt PowerHive om deze risico's
      te beheren en te minimaliseren waardoor je meestal minder betaald
      onderaan de streep.`,
    },
    {
      title: `Hoe zit het met de technische ondersteuning en klantenservice van
        PowerHive?`,
      value: `PowerHive biedt uitgebreide technische ondersteuning en klantenservice om klanten te helpen bij het gebruik van ons platform. Ons toegewijde team staat dagelijks klaar om vragen te beantwoorden, problemen op te lossen en technische ondersteuning te bieden, zodat klanten altijd verzekerd zijn van een soepele ervaring.`,
    },
    {
      title: `Wat zijn de kosten van het gebruik van PowerHive?`,
      value: `PowerHive is volop in ontwikkeling, dit houdt in dat je als klant nu al kunt profiteren van de meeste voordelen terwijl we nog verder aan het ontwikkelen zijn. Deze ontwikkeling betekent dat wij op dit moment werken met maatwerkoplossingen en per klant kijken naar een individuele oplossing. In ons streven naar markt transparantie werken we toe naar een transparant prijsmodel dat voor iedereen inzichtelijk en begrijpelijk is.`,
    },
    {
      title: `Hoe kan ik aan de slag gaan met PowerHive?`,
      value: `Het starten met PowerHive is eenvoudig. Neem contact met ons op voor een kennismakingsgesprek en een demonstratie van ons platform. Ons team zal jou begeleiden bij de implementatie en configuratie, zodat jij snel kunt profiteren van de voordelen van PowerHive voor jouw bedrijf.`,
    },
  ];
  return (
    <div className="container help-section" >
      {source == 'web-app'?'':<div className="heading-container-foldtwo">
        <h1 id="header-2">Veelgestelde vragen</h1>
      </div>}
      <Accordion open={open} toggle={toggle} className='pt-0'>
        {data.map((item, index) => (
          <AccordionItem
            className={`${open == index ? 'active-item' : ''} mb-md-4 mb-3`}>
            <AccordionHeader targetId={`${index}`}>
              <p className="mb-0 me-1">{item?.title}</p>
            </AccordionHeader>
            <AccordionBody accordionId={`${index}`}>
              {item?.value}
              <br />
              <br />
              {item?.para1}
            </AccordionBody>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};

export default FoldSix;
