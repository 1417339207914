import React, { useRef } from 'react';
import '../pages/HomePage/style.scss';
import HeroSection from './HomePage/HeroSection';
import FoldTwo from './HomePage/FoldTwo';
import FoldThree from './HomePage/FoldThree';
import FoldFour from './HomePage/FoldFour';
import FoldFive from './HomePage/FoldFive';
import FoldSix from './HomePage/FoldSix';
import Help from '../components/Help';
import HeaderWebsite from '../components/HeaderWebsite';
import FooterWebsite from '../components/FooterWebsite';
import OurFeauters from './HomePage/OurFeauters';
import CookieConsent from './CookieConstent';
const HomePages = () => {
    const foldTwoRef = useRef(null);

    return (
        <div className='home-page'>
            <HeaderWebsite />
            <HeroSection foldTwoRef={foldTwoRef} />
            {/* <CookieConsent /> */}
            <div ref={foldTwoRef}>
                <FoldTwo />
            </div>
            <FoldThree />
            <OurFeauters />
            {/* <FoldSix /> */}
            <Help/>
            <FooterWebsite />
        </div>
    );
};

export default HomePages;
