import { Card, CardBody } from 'reactstrap';
import Help from '../components/Help';
import { useTranslation } from 'react-i18next';

const UserHelp = () => {
  const { t } = useTranslation();
  return (
    <div className="user-help">
      <Card className="cstm-card my-3 mx-3">
        <CardBody className="p-md-4  p-3 position-relative">
          <div className="mb-4">
            <Help source="web-app" />
          </div>
          <div className="footer-help">
            <p>
              {t('Need More Help? Mail Us at')}{' '}
              <a href="mailto:contact@powerhive.energy" className="btn-link">
                contact@powerhive.energy
              </a>
            </p>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
export default UserHelp;
