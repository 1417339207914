import React, { useEffect } from 'react';
import HomePages from '../pages/HomePages';
import HeaderWebsite from '../components/HeaderWebsite';
import FooterWebsite from '../components/FooterWebsite';
import { Route, Routes } from 'react-router-dom';
import AboutUsPages from '../pages/AboutUsPages';
import ServicesPages from '../pages/ServicesPages';
import ContactPages from '../pages/ContactUsPages';
import TermsAndConditions from '../pages/StatementPage/TermsAndConditions';
import PrivacyStatement from '../pages/StatementPage/PrivacyStatement';
import ComingSoonPages from '../pages/ComingSoonPages';
import {SERVER_NAME} from '../config/constants'

const PublicRoutes = () => {

  useEffect(() => {
    if(SERVER_NAME != 'localhost'){
      const script = document.createElement('script');
      script.src = 'https://consent.cookiebot.com/uc.js';
      script.id = 'Cookiebot';
      script.setAttribute('data-cbid', 'c63f212d-e6a6-48a7-b5f8-a4d6eb11fead');
      script.setAttribute('data-blockingmode', 'auto');
      script.type='text/javascript';
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  
  }, []);

  return (
    <Routes>
      <Route path="/" element={<HomePages />} />
      <Route path="/about-us" element={<AboutUsPages />} />
      <Route path="/services" element={<ServicesPages />} />
      <Route path="/contact-us" element={<ContactPages />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/terms&Conditions" element={<TermsAndConditions />} />
      <Route path="/privacy-statement" element={<PrivacyStatement />} />
      <Route path="/coming-soon" element={<ComingSoonPages />} />
    </Routes>
  );
};

export default PublicRoutes;
