import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from 'reactstrap';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '../../utilities/function';
import { useNavigate } from 'react-router-dom';

const Summary = ({ formik = {}, setSubmitDisable = {} }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { values } = formik;
  const [isDeclarationChecked, setIsDeclarationChecked] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const handleDeclarationChange = event => {
    setIsDeclarationChecked(event.target.checked);
    if (isTermsChecked) {
      setSubmitDisable(false);
    }
  };

  const handleTermsChange = event => {
    setIsTermsChecked(event.target.checked);
    if (isDeclarationChecked) {
      setSubmitDisable(false);
    }
  };
  useEffect(() => {
    if (isTermsChecked && isDeclarationChecked) {
      setIsVisible(false);
      setSubmitDisable(false);
    } else {
      setSubmitDisable(true);
    }
  }, [isTermsChecked, isDeclarationChecked]);

  useEffect(() => {
    const element = document.getElementById('terms&Conditions');
    if (element && !isTermsChecked && !isDeclarationChecked) {
      // element.scrollIntoView({ behavior: 'smooth' });
      const rect = element.getBoundingClientRect();
      if (rect.top >= 0 && rect.bottom <= window.innerHeight)
        setIsVisible(true);
    } else {
      setIsVisible(false);
    }
    // if(values.passport != null){
    //   setFile(values.passport)
    // }
  }, []);
  // console.log('values',values)
  return (
    <div className="summary">
      <div className="header">
        <h3 className="ps-3 title">{t('Summary')}</h3>
      </div>
      <div className="details-container">
        <Card className="company-details">
          <CardBody>
            <CardTitle className="mb-4">
              <h4 className="font-bold">{t('Company Details')}</h4>
            </CardTitle>
            <div className="d-flex flex-wrap summary-box row">
              <div className="col-auto" style={{ width: '150px' }}>
                <span>{t('Company Name')}:</span>
                <p style={{ maxWidth: '150px' }}>
                  {values?.companyName || '-'}
                </p>
              </div>
              <div className="col-auto" style={{ width: '150px' }}>
                <span>{t('KvK Number')}:</span>
                <p>{values?.KvkNumber || '-'}</p>
              </div>
              <div className="col-auto" style={{ width: '150px' }}>
                <span>{t('Town / City')}:</span>
                <p>{values?.city || '-'}</p>
              </div>
              <div className="col-auto" style={{ width: '150px' }}>
                <span>{t('Street Address')}:</span>
                <p style={{ maxWidth: '100px' }}>
                  {values?.streetAddress || '-'}
                </p>
              </div>
              <div className="col-auto" style={{ width: '120px' }}>
                <span>{t('Housenumber')}:</span>
                <p>{values?.houseNumber || '-'}</p>
              </div>
              <div className="col-auto" style={{ width: '100px' }}>
                <span>{t('Suffix')}:</span>
                <p style={{ maxWidth: '100px' }}>{values?.suffix || '-'}</p>
              </div>
              <div className="col-auto" style={{ width: '100px' }}>
                <span>{t('ZIP Code')}:</span>
                <p>{values?.zipCode || '-'}</p>
              </div>
            </div>
          </CardBody>
        </Card>
        <Card className="personal-details mt-3">
          <CardBody>
            <CardTitle className="mb-4">
              <h4 className="font-bold">{t('Personal Details')}</h4>
            </CardTitle>
            <div className="d-flex flex-wrap summary-box row">
              <div className="col-auto" style={{ width: '150px' }}>
                <span>{t('First Name')}:</span>
                <p>{values?.firstName || '-'}</p>
              </div>
              <div className="col-auto" style={{ width: '150px' }}>
                <span>{t('Last Name')}: </span>
                <p>{values?.lastName || '-'}</p>
              </div>
              <div className="col-auto" style={{ width: '250px' }}>
                <span>{t('Email Address')}:</span>
                <p>{values?.email || '-'}</p>
              </div>
              <div className="col-auto" style={{ width: '150px' }}>
                <span>{t('Phone Number')}:</span>
                <p>{values?.phone ? '+31' + values?.phone : '-'}</p>
              </div>
            </div>
          </CardBody>
        </Card>
        <Card className="connection-details mt-3">
          <CardBody>
            <CardTitle className="mb-4">
              <h4 className="font-bold">{t('Connections')}</h4>
            </CardTitle>
            <div className="connection-container">
              <Table className="summary-box" borderless>
                <thead>
                  <tr>
                    <td valign="top">
                      <span>{t('Conn.')}</span>
                    </td>
                    <td valign="top">
                      <span>{t('Houseno.')}</span>
                    </td>
                    <td valign="top">
                      <span>{t('ZIP Code')}</span>
                    </td>
                    <td valign="top">
                      <span>{t('Town/City')}</span>
                    </td>
                    <td valign="top">
                      <span>EAN</span>
                    </td>
                    <td valign="top" width="10%">
                      <span>{t('Consumption')} Volume</span>
                    </td>
                    <td valign="top" width="10%">
                      <span>{t('Production')} Volume</span>
                    </td>
                    <td valign="top">
                      <span>{t('Capacity')}</span>
                    </td>
                    <td valign="top" style={{ minWidth: '100px' }}>
                      <span>{t('Start Date')}</span>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {values.connections &&
                    values.connections.map((connection, index) => (
                      <tr key={index}>
                        <td>
                          <p>{index + 1 || '-'}</p>
                        </td>
                        <td>
                          <p>{connection?.cHouseNumber || '-'}</p>
                        </td>
                        <td>
                          <p className="text-uppercase">
                            {connection?.cZipCode || '-'}
                          </p>
                        </td>
                        <td>
                          <p className="text-capitalize">
                            {connection?.cCity || '-'}
                          </p>
                        </td>
                        <td>
                          <p>{connection?.EAN || '-'}</p>
                        </td>

                        <td>
                          <p>{formatNumber(connection?.consumption) || '-'}</p>
                        </td>
                        <td>
                          <p>{formatNumber(connection?.production) || '-'}</p>
                        </td>
                        <td>
                          <p>{connection?.capacity || '-'}</p>
                        </td>
                        <td>
                          <p>
                            {moment(connection?.date).format('DD MMM YYYY')}
                          </p>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
        <Card className="plan-details mt-3">
          <CardBody>
            <CardTitle className="mb-4">
              <h4 className="font-bold">{t('Your Plan')}</h4>
            </CardTitle>
            <div className="d-flex flex-wrap summary-box row">
              <div className="col-auto" style={{ width: '150px' }}>
                <span>{t('Subscription Plan')}: </span>
                <p>{t(`${values?.plan?.productName}`)}</p>
              </div>
              <div className="col-auto">
                <span>
                  {values?.plan?.monthlySubscription
                    ? t('costs-monthly-summary')
                    : t('costs-yearly-summary')}
                  :
                </span>
                <p>
                  €&nbsp;{values?.plan?.amount} / per {t('connection')}
                  {/* {values?.plan?.monthlySubscription ? t('month') : t('year')}  */}
                </p>
              </div>
              <div className="col-auto">
                <span>{t('Transaction fees')}:</span>
                <p>€ {values?.plan?.transactionFee} / MWh</p>
              </div>
              <div className="col-auto">
                <span>{t('Cancellation Term')}:</span>
                <p>1 {t('month')}</p>
              </div>
            </div>
          </CardBody>
        </Card>
        <Card className="payment-details-boarding mt-3">
          <CardBody>
            <CardTitle className="mb-4">
              <h4 className="font-bold">{t('Payment Details')}</h4>
            </CardTitle>
            <div className="d-flex flex-wrap summary-box row">
              <div className="col-auto" style={{ width: '150px' }}>
                <span>{t('Name')}:</span>
                <p>{values?.paymentDetails?.bankAccountName || '-'}</p>
              </div>
              <div className="col-auto">
                <span>IBAN:</span>
                <p>{values?.paymentDetails?.IBAN || '-'}</p>
              </div>
            </div>
          </CardBody>
        </Card>
        <Card
          className={`${isVisible ? 'terms-error' : ''} terms mt-3`}
          id="terms&Conditions">
          <CardBody>
            <div className="d-flex flex-column">
              <FormGroup check>
                <Input
                  type="checkbox"
                  id="declaration"
                  name="declaration"
                  onChange={handleDeclarationChange}
                />
                <Label check for="declaration">
                  {t(
                    'I hereby confirm that I am a legal respresentative of all the listed connections and can be hold accountable for any incorrect information.',
                  )}
                </Label>
              </FormGroup>
              <FormGroup check>
                <Input
                  type="checkbox"
                  id="terms"
                  name="terms"
                  onChange={handleTermsChange}
                />
                <Label check for="terms">
                  {t(
                    'By confirming the registration at PowerHive you agree with the',
                  )}
                  <span role="button" className="btn-link">
                    {' '}
                    <a href={'/terms&Conditions'}  target='_blank'  className='btn-link'>{t('Terms and Conditions')}</a>
                  </span>{' '}
                  {t('of the PowerHive platform.')}{' '}
                </Label>
              </FormGroup>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Summary;
