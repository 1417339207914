import { createSlice } from '@reduxjs/toolkit';

export const AuthReducer = createSlice({
  name: 'auth',
  initialState: {
    userName: '',
    email: '',
    accessToken: '',
    idToken:'',
    companyId:'',
    isLoggedIn: false,
  },
  reducers: {
    setUserDetails: (state, action) => {
      const { userName, email, accessToken,idToken,companyId } = action.payload;
      state.userName = userName;
      state.email = email;
      state.accessToken = accessToken;
      state.idToken=idToken;
      state.companyId=companyId;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
  },
});

export const { setUserDetails, setIsLoggedIn } = AuthReducer.actions;

export default AuthReducer.reducer;
