import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import verifyIcon from '../../assests/images/icons/Verify.svg'

const SuccessModal = ({ isOpen = '', message = '' }) => {
  return (
    <>
      <Modal isOpen={isOpen} centered backdrop className="mw-350">
        <ModalBody className="p-md-5 p-3">
          <div className="d-grid justify-content-center">
            <h3 className={`mb-0 font-bold`}>New Password Set</h3>
            <div className="text-center my-md-5">
            <img src={verifyIcon} height={100}/>
            </div>
          </div>
          <p className="font-regular text-gray text-center mb-0 px-lg-4">
            {message}
          </p>
        </ModalBody>
      </Modal>
    </>
  );
};

export default SuccessModal;
