import React from 'react';
import './style.scss';
import Image1 from '../../assests/images/servicesPageImages/servicesImage1.svg';
import Image2 from '../../assests/images/servicesPageImages/servicesImage2.svg';
import Image3 from '../../assests/images/servicesPageImages/servicesImage3.svg';
import Image4 from '../../assests/images/servicesPageImages/servicesImage4.png';

const FoldTwo = () => {
    return (
        <div className='container' id='fold22'>
            <div className='container sub-fold left-content'>
                <div className='content'>
                    <h2 className="content-header">Inzicht en voorspellen</h2>
                    <p className="content-para">Het PowerHive platform gebruikt geavanceerde data-analyse voor diepgaande inzichten in jouw energieprofiel. Daarnaast krijg je marktanalyses die duidelijk laten zien wat er op de markt speelt. Hiermee zorgen we voor de meest optimale inkoopstrategie.</p>
                </div>
                <img src={Image1} alt="Analytics & Forecasting" className="sub-fold-img" />
            </div>
            <div className='container sub-fold right-content'>
                <div className='content'>
                    <h2 className="content-header">Energie Marktplaats</h2>
                    <p className="content-para">PowerHive biedt klanten directe toegang tot de energie groothandelsmarkten. Zowel voor dagelijkse inkopen als het vastzetten van prijzen voor de lange termijn. Dit is altijd tegen de daadwerkelijke marktprijzen zonder dure premies van leveranciers.</p>
                </div>
                <img src={Image2} alt="Energy Marketplace" className="sub-fold-img" />
            </div>
            <div className='container sub-fold left-content'>
                <div className='content'>
                    <h2 className="content-header">Energie Management</h2>
                    <p className="content-para">PowerHive helpt je om aanstuurbare energiebronnen zoals batterijen of zonne-energie optimaal te benutten. Verbind je apparaten eenvoudig met ons platform en wij zorgen voor de rest. Door slimme optimalisatie zorgen we voorkomen we onnodige kosten.</p>
                </div>
                <img src={Image3} alt="Energy Optimization" className="sub-fold-img" />
            </div>
            <div className='container sub-fold right-content'>
                <div className='content'>
                    <h2 className="content-header">Certificering</h2>
                    <p className="content-para">Met PowerHive krijg je toegang tot een marktplaats waarin hernieuwbare energiecertificaten, zoals Garanties van Oorsprong , worden verhandeld. Transparant en automatisch. Zo kunt jij jouw duurzaamheidsdoelen bereiken met gemak en vertrouwen</p>
                </div>
                <img src={Image4} alt="Certification" className="sub-fold-img" />
            </div>
        </div>
    );
};
export default FoldTwo;
