import React from 'react';
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';
import Header from './../components/Header';
import FooterWebsite from '../components/FooterWebsite';
import NotFound from '../components/common/NotFound';

const Layout = () => {
    const location = useLocation();
    const publicPaths = ['/', '/about-us', '/services', '/contact-us', '/terms-and-conditions', '/privacy-statement', '/coming-soon','/terms&Conditions']; // List of public route paths
    const showHeaderFooter = !publicPaths.includes(location.pathname);

    return (
        <>
            {showHeaderFooter && <Header />}
            <Outlet />
        </>
    );
};

export default Layout;
