import React, { useEffect, useMemo, useState } from 'react';
import { BsCircleFill, BsSquareFill } from 'react-icons/bs';
import { Card, CardBody, CardTitle } from 'reactstrap';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  ReferenceLine,
} from 'recharts';
import EmptyIcon from '../../assests/images/miscellaneous/search.svg';
import NoData from '../../assests/images/miscellaneous/file.svg';
import moment from 'moment';
import { formatNumber } from '../../utilities/function';
import { useTranslation } from 'react-i18next';

const VolumeOverview = ({ loading = '', data = '' }) => {
  const {t} = useTranslation()

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{moment(new Date(label)).format('DD MMM')}</p>
          <p>
            <BsSquareFill
              size={20}
              color={`${payload[0]?.color}`}
              className="me-2"
            />
            {formatNumber(payload[0]?.payload?.forecastedVolume)}&nbsp;
            {payload[0]?.unit}
          </p>
          <p>
            <BsSquareFill
              size={20}
              color={`${payload[1]?.color}`}
              className="me-2"
            />
            {formatNumber(payload[1]?.payload?.imbalanceInKwh)}&nbsp;
            {payload[0]?.unit}
          </p>
        </div>
      );
    }

    return null;
  };
  return (
    <div>
      {' '}
      <Card className="cstm-card mt-3">
        <CardBody className="pt-md-4 pt-3 px-2">
          {loading || !data || data.length <= 0 ? (
            <div className="no-data-view fs-16">
              <img
                src={!loading ? NoData : EmptyIcon}
                alt="No Data"
                height={100}
              />
              {loading ? (
                <span className="font-regular text-center">
                  {t('Forecast is being generated.')}
                  <br /> {t('This usually appears the next day')}
                </span>
              ) : (
                <span className="font-regular text-center">{t('No data yet')}</span>
              )}
            </div>
          ) : (
            <>
              <CardTitle className="items-center justify-content-end mx-md-4 ">
                <BsCircleFill
                  color="#FBBB36"
                  size={20}
                  className="me-md-2 me-1"
                />
                <span>{t('Forecast')}&nbsp;({data[0]?.volumeUnit})</span>
                <BsCircleFill
                  color="#001427"
                  size={20}
                  className="mx-md-2 mx-1"
                />
                <span>{t('Imbalance')}&nbsp;({data[0]?.volumeUnit})</span>
              </CardTitle>
              <div className="pt-2" style={{ height: '400px' }}>
                <ResponsiveContainer width="100%" height="95%">
                  <BarChart
                    width="100%"
                    height={350}
                    data={data}
                    barSize={7}
                    margin={{ top: 5, right: 10, left: 10, bottom: 15 }}>
                    <CartesianGrid strokeDasharray="5 3" vertical={false} />
                    <XAxis
                      dataKey="transactionDate"
                      axisLine={false}
                      tickLine={false}
                      tickSize={15}
                      interval={0}
                      angle={-90}
                      padding={{ left: 0, right: 0 }}
                      tickFormatter={tick =>
                        moment(new Date(tick)).format('DD/MM')
                      }
                    />
                    <YAxis
                      axisLine={false}
                      tickLine={false}
                      tickCount={6}
                      tickSize={10}
                      tickFormatter={tick =>
                        tick != 0 ? formatNumber(tick) : '0'
                      }
                    />
                    <Tooltip content={CustomTooltip} cursor={false} />
                    <Bar
                      dataKey="forecastedVolume"
                      fill="#FBBB36"
                      className="cursor-pointer position-relative"
                      radius={20}
                      minPointSize={2}
                      unit={data[0]?.volumeUnit||'kWh'}
                    />
                    <Bar
                      dataKey="imbalanceInKwh"
                      fill="#001427"
                      className="cursor-pointer position-relative"
                      radius={20}
                      minPointSize={2}
                      unit={data[0]?.volumeUnit||'kWh'}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default VolumeOverview;
