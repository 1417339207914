import { Card, CardBody, CardFooter } from 'reactstrap';
import { formatNumber } from '../../utilities/function';
import { useTranslation } from 'react-i18next';

const AmountCard = ({
  title = '',
  text = '',
  amount = 0,
  icon = '',
  totalAmount = 0,
}) => {
  const {t} = useTranslation()
  return (
    <Card className="cstm-card balance-card">
      <CardBody className="d-grid p-lg-4 p-3">
        <div className='mt-3'>
          <span className="font-light fs-14">{t(`${title}`)}</span>
          <h4 className="font-bold">
            {title == 'Deposit-wallet' ? formatNumber(amount,'currency') : formatNumber(totalAmount,'currency')}
            {icon ? <span className="ms-2">{icon}</span> : ''}
          </h4>
        </div>
      </CardBody>
      <CardFooter>
        <p className="mb-0">{t(`${text}`)}</p>
      </CardFooter>
    </Card>
  );
};
export default AmountCard;
