import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import visibleIcon from '../assests/images/icons/View.png';
import invisibleIcon from '../assests/images/icons/invisible.png';
import PasswordStrengthIndicator from '../components/common/PasswordStrengthIndicator';

const CreatePassword = () => {
  const [passwordVisibility, setPasswordVisibility] = useState({
    newPassword: 'password',
    confirmPassword: 'password',
  });

  const togglePasswordVisibility = type => {
    setPasswordVisibility(prevState => ({
      ...prevState,
      [type]: prevState[type] === 'password' ? 'text' : 'password',
    }));
  };

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string().required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], "Passwords don't match")
      .required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
    },
  });

  const { values, errors, touched } = formik;
  const { newPassword, confirmPassword } = values;
  const [isDisabled, setDisabled] = useState(true);

  useEffect(() => {
    const noErrors = errors && errors.length > 0 ? false : true;
    if (
      noErrors &&
      values?.newPassword !== '' &&
      values?.confirmPassword !== ''
    ) {
      if (values?.newPassword == values?.confirmPassword) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      setDisabled(true);
    }
  }, [values, errors]);

  return (
    <div className="auth-layout">
      <Row className="w-100 m-md-4 m-2">
        <Col lg={7} xl={6} xs={12}>
          <Card className="bg-auth"><div className='bg-auth-footer'>{t('Make energy work for you')}</div></Card>
        </Col>
        <Col lg={5} xl={6} xs={12} className="auth-container p-md-4">
          <Card className="auth-card password-page">
            <CardBody className="py-lg-4 py-3">
              <h2 className="text-center font-bold mb-3">Set Password</h2>
              <p className="text-center font-regular mx-md-4 text-light">
                Choose a strong password wisely. Mix it up with numbers, symbols
                for maximum protection.
              </p>
              <form
                onSubmit={formik.handleSubmit}
                className="mx-md-4 mx-1 h-75">
                <div style={{ margin: '1.5rem 0 1.25rem 0' }}>
                  <FormGroup
                    className="position-relative mb-md-4 password-input"
                    floating>
                    <Input
                      id="newPassword"
                      type={passwordVisibility.newPassword}
                      name="newPassword"
                      placeholder="New Password"
                      value={newPassword}
                      onChange={formik.handleChange}
                    />
                    <Label for="newPassword">New Password</Label>
                    <div className="toggle-password position-absolute">
                      <img
                        src={
                          passwordVisibility.newPassword === 'password'
                            ? invisibleIcon
                            : visibleIcon
                        }
                        className=""
                        onClick={() => togglePasswordVisibility('newPassword')}
                        alt="Toggle Password Visibility"
                      />
                    </div>
                    {touched.newPassword && errors.newPassword && (
                      <div className="text-danger fs-14">
                        {errors.newPassword}
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup
                    className="position-relative password-input"
                    floating>
                    <Input
                      id="confirmPassword"
                      type={passwordVisibility.confirmPassword}
                      name="confirmPassword"
                      placeholder="Re-Enter New Password"
                      value={confirmPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <Label for="confirmPassword">Re-Enter New Password</Label>
                    <div className="toggle-password position-absolute">
                      <img
                        src={
                          passwordVisibility.confirmPassword === 'password'
                            ? invisibleIcon
                            : visibleIcon
                        }
                        className=""
                        onClick={() =>
                          togglePasswordVisibility('confirmPassword')
                        }
                        alt="Toggle Password Visibility"
                      />
                    </div>
                    {touched.confirmPassword && errors.confirmPassword && (
                      <div className="text-danger fs-14">
                        {errors.confirmPassword}
                      </div>
                    )}
                  </FormGroup>
                </div>
                <PasswordStrengthIndicator password={newPassword} />
                <div
                  className="d-flex justify-content-center"
                  style={{ marginBottom: '1rem' }}>
                  <Button
                    disabled={isDisabled}
                    className={`${isDisabled ? 'btn-disabled' : 'btn-submit'} w-50`}
                    type="submit">
                    Set Password
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CreatePassword;
