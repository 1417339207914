import React, { useEffect, useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

function CustomMonthYearSelector({
  loading='',
  currentMonthIndex = '',
  currentYear = '',
  setCurrentMonthIndex = {},
  setCurrentYear = {},
  setHistory={}
}) {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const presentYear = new Date().getFullYear();
  const presentMonth = new Date().getMonth();

  const [IsDisabled, setIsDisabled] = useState(false);

  const updateMonthAndYear = (newMonthIndex, newYear) => {
    setCurrentMonthIndex(newMonthIndex);
    setCurrentYear(newYear);
  };

  const handlePrevMonth = () => {
    if (currentMonthIndex === 0) {
      updateMonthAndYear(11, currentYear - 1);
    } else {
      updateMonthAndYear(currentMonthIndex - 1, currentYear);
    }
  };
  const handleNextMonth = () => {
    if (currentMonthIndex === 11) {
      updateMonthAndYear(0, currentYear + 1);
    } else if (
      currentMonthIndex === presentMonth &&
      currentYear == presentYear
    ) {
      setIsDisabled(true);
    } else {
      updateMonthAndYear(currentMonthIndex + 1, currentYear);
    }
  };
  useEffect(() => {
    if (currentMonthIndex !== presentMonth) {
      setIsDisabled(false);
    } else {
      if (currentMonthIndex === presentMonth && currentYear == presentYear) {
        setIsDisabled(true);
      }
    }
  }, [currentMonthIndex]);
  return (
    <div className="items-center">
      <span role="button" onClick={handlePrevMonth} className={`${loading ? 'click-disabled' : ''}`}>
        <FaChevronLeft color="#FBBB36" className="me-1" />
      </span>
      <span className="month-selector-box">
        {monthNames[currentMonthIndex]}
      </span>
      <span className="month-selector-box me-1">{currentYear}</span>
      <span
        role="button"
        className={`${IsDisabled||loading ? 'click-disabled' : ''}`}
        onClick={handleNextMonth}>
        <FaChevronRight color="#FBBB36" className="" />
      </span>
    </div>
  );
}

export default CustomMonthYearSelector;
