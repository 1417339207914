import './ServicesPage/style.scss';
import HeroSection from './ServicesPage/HeroSection';
import FoldTwo from './ServicesPage/FoldTwo';
import HeaderWebsite from '../components/HeaderWebsite';
import FooterWebsite from '../components/FooterWebsite';
import CookieConsent from './CookieConstent';
const ServicesPages = () => {
    return (
        <div className='services-page'>
            <HeaderWebsite />
            <HeroSection />
            {/* <CookieConsent /> */}
            <FoldTwo />
            <FooterWebsite />
        </div>

    );
};
export default ServicesPages;