import {
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import OnBoarding from '../pages/OnBoarding';
import Login from '../pages/Login';
import CreatePassword from '../pages/CreatePassword';
import ForgotPassword from '../pages/ForgotPassword';
import NotFound from '../components/common/NotFound';

const AuthRoutes = () => {
  
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/get-started" element={<OnBoarding />} />
      <Route path="/create-password" element={<CreatePassword />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/" element={<Navigate to={'/'} replace={true} />} />
      <Route path='*' element={<NotFound/>}/>
    </Routes>
  );
};
export default AuthRoutes;
