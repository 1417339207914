import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from 'reactstrap';
import { RiPencilFill } from 'react-icons/ri';
import EditConnectionModal from './EditConnectionModal';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '../../utilities/function';

const MyConnections = ({ data = '', fetchData = {} }) => {
  const { t } = useTranslation();
  const [editConnection, setEditConnection] = useState(false);
  const [connectionDetails, setConnectionDetails] = useState([]);

  const [open, setOpen] = useState('-1');
  const toggle = id => {
    if (open == id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  const handleConnectionEdit = id => {
    setEditConnection(true);
    setConnectionDetails(data[id]);
  };
  const handleClose = () => {
    setEditConnection(false);
  };
  return (
    <div className="user-connections">
      <Accordion open={open} toggle={toggle}>
        {data &&
          data.map((item, index) => (
            <AccordionItem
              className={`${open == index ? 'active-item' : ''} mb-md-4 mb-3`}
              key={item?.connectionId}>
              <AccordionHeader targetId={`${index}`} className="row m-0 p-0">
                <div className="fs-16 items-center col-xl-4 col-6">
                  <span className="header-accordion">{t('Connection')}:</span>{' '}
                  <span className=" font-bold ms-3 position-relative text-capitalize-">
                    {/* {item?.name} */}
                    {item?.name
                      ? item?.name
                      : item?.streetAddress +
                        '' +
                        item?.houseNo +
                        '' +
                        (item.suffix != null ? item.suffix : '')}

                    {open == index && item?.connectionStatus != 'Inactive' && (
                      <RiPencilFill
                        size={18}
                        color="#FBBB36"
                        className="mb-1 mx-1 edit-icon"
                        onClick={() => handleConnectionEdit(index)}
                      />
                    )}
                  </span>
                </div>
                <div className="fs-16 col-xl-4 col-6">
                  <span className="header-accordion">{t('Status')}:</span>
                  <span
                    className={`${item?.connectionStatus == 'Active' ? 'text-success' : item?.connectionStatus == 'Inactive' ? 'text-danger' : 'text-theme'} font-bold ms-3 text-capitalize`}>
                    {item?.connectionStatus != null
                      ? t(`${item?.connectionStatus}`)
                      : 'N/A'}
                  </span>
                </div>
              </AccordionHeader>
              {open === index && <div className="border-bottom-header"></div>}
              <AccordionBody accordionId={`${index}`}>
                <div className="d-flex flex-wrap summary-box row ">
                  <div className="col-3 items-center py-1">
                    <span>{t('Street Address')}:</span>
                    <p
                      style={{ maxWidth: '150px' }}
                      className="ms-1 text-capitalize">
                      {item?.streetAddress || '-'}
                    </p>
                  </div>
                  <div className="col-3 items-center py-1">
                    <span>{t('House No.')}:</span>
                    <p className="ms-1">{item?.houseNo || '-'}</p>
                  </div>
                  <div className="col-3 items-center py-1">
                    <span>{t('Suffix')}:</span>
                    <p className="ms-1">{item?.suffix || '-'}</p>
                  </div>
                  <div className="col-3 items-center py-1">
                    <span>{t('ZIP Code')}:</span>
                    <p
                      style={{ maxWidth: '100px' }}
                      className="ms-1 text-uppercase">
                      {item?.postalCode || '-'}
                    </p>
                  </div>
                  <div className="col-3 items-center py-1">
                    <span>{t('Town / City')}:</span>
                    <p className="ms-1 text-capitalize">
                      {item?.townCity || '-'}
                    </p>
                  </div>
                  <div className="col-3 items-center py-1">
                    <span>EAN:</span>
                    <p style={{ maxWidth: '150px' }}>
                      {item?.eanNumber || '-'}
                    </p>
                  </div>
                  <div className="col-3 items-center py-1">
                    <span>{t('Consumption Vol')}:</span>
                    <p className="ms-1">
                      {formatNumber(item?.consumptionVol||0) || '-'}&nbsp;
                      {item?.consumptionVol != null ? 'kWh' : ''}
                    </p>
                  </div>
                  <div className="col-3 items-center py-1">
                    <span>{t('Production Vol')}:</span>
                    <p className="ms-1">
                      {formatNumber(item?.productionVol||0) || '-'}&nbsp;
                      {item?.productionVol != null ? 'kWh' : ''}
                    </p>
                  </div>
                  <div className="col-3 items-center py-1">
                    <span>{t('Capacity')}:</span>
                    <p className="ms-1">{item?.capacity|| '-'}</p>
                  </div>
                  <div className="col-3 items-center py-1">
                    <span>{t('Start Date')}:</span>
                    <p className="ms-1">
                      {item?.startDate != null
                        ? moment(new Date(item?.startDate)).format(
                            'DD MMM YYYY',
                          )
                        : '-'}
                    </p>
                  </div>
                </div>
              </AccordionBody>
            </AccordionItem>
          ))}
      </Accordion>
      {editConnection && (
        <EditConnectionModal
          data={connectionDetails}
          isOpen={editConnection}
          fetchData={fetchData}
          handleClose={handleClose}
        />
      )}
    </div>
  );
};

export default MyConnections;
