import './ContactUsPage/style.scss';
import ContactForm from './ContactUsPage/ContactForm';
import HeaderWebsite from '../components/HeaderWebsite';
import FooterWebsite from '../components/FooterWebsite';
import CookieConsent from './CookieConstent';
const ServicesPages = () => {
  return (
    <div className="contact-us-page">
      <HeaderWebsite />
      <ContactForm />
      {/* <CookieConsent /> */}
      <FooterWebsite />
    </div>
  );
};
export default ServicesPages;
