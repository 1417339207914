import './styles.scss';
import { Button, Col, Container, Row } from 'reactstrap';
import SideBar from '../../components/SideBar';
import { lazy, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { api, processAPI } from '../../utilities/api';
import Loader from '../../components/common/Loader';
import CompanySearch from './CompanySearch';
import PlanDetails from './PlanDetails';
import PaymentDetails from './PaymentDetails';
import ConnectionDetails from './Connections';
import UserDetails from './UserDetails';
import Summary from './Summary';
import ThankYouPage from './ThankYouPage';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '../../utilities/function';
import { endpoints } from '../../utilities/Endpoints';
import { IS_FEATURED, SERVER_NAME } from '../../config/constants';

const OnBoarding = () => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [isDisabled, setDisabled] = useState(true);
  const [prevStep, setPreviousStep] = useState(0);
  const [submitDisable, setSubmitDisable] = useState(true);
  const [isLoading, setLoading] = useState(false);
  let regexPhone = /^\d{10}$/;
  let regexLettersOnly = /^[a-zA-Z]+$/;
  let regexNumberOnly = /^\d+$/;
  let regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  let IBANRegex = /^NL\d{2}[A-Z]{4}\d{10}$/;
  let lastNameRegex = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;

  const validationSchema = Yup.object().shape({
    companyName: Yup.string()
      .min(5, t('Min. 5 characters required'))
      .required(t('Company Name is required')),
    activityDescription:Yup.string(),
    streetAddress: Yup.string().required(t('Street Address is required')),
    KvkNumber: Yup.number()
      .typeError(t('Only Numbers allowed'))
      .required(t('KvK Number is required')),
    houseNumber: Yup.number()
      .typeError(t('Only Numbers allowed'))
      .required(t('House Number is required')),
    suffix: Yup.string(),
    city: Yup.string().required(t('City is required')),
    zipCode: Yup.string().required(t('ZIP Code is required')),
    firstName: Yup.string()
      .matches(regexLettersOnly, t('Only alphabets allowed'))
      .min(1, t('Min. 1 character required'))
      .max(200, t('Max. 200 characters allowed'))
      .required(t('First Name is required')),
    lastName: Yup.string()
      .matches(lastNameRegex, t('Only alphabets and space allowed'))
      .max(200, t('Max. 200 characters allowed')),
    email: Yup.string()
      .matches(regexEmail, t('Invalid email address'))
      .required(t('Email Address is required')),
    phone: Yup.string()
      .matches(regexPhone, t('Invalid Phone number'))
      .min(10, t('Min. 10 digits'))
      .max(10, t('Max. 10 digits'))
      .required(t('Phone Number is required')),
    connections: Yup.array().of(
      Yup.object().shape({
        streetAddress: Yup.string()
          .max(150, t('Max. 150 characters allowed'))
          .required(t('Street Address is required')),
        code:Yup.string(),
        EAN: Yup.string()
          .matches(regexNumberOnly, t('Enter a valid number'))
          .required(t('EAN is required')),
        cHouseNumber: Yup.number()
          .typeError(t('Only Numbers allowed'))
          .required(t('House Number is required')),
        cSuffix: Yup.string(),
        consumption: Yup.string()
          .matches(regexNumberOnly, {
            message: `${t('Only Numbers allowed')}`,
            excludeEmptyString: true,
          })
          .test({
            name: 'min',
            message: 'Min. Volume- 0',
            test: value => {
              if (!value) return true;
              const sanitizedValue = value
                .replace(/\./g, '')
                .replace(/,/g, '.');
              const numericValue = parseFloat(sanitizedValue);
              return numericValue >= 0;
            },
          })
          .test({
            name: 'max',
            message: `Max Volume- ${formatNumber(10000000)}`,
            test: value => {
              if (!value) return true;
              const sanitizedValue = value
                .replace(/\./g, '')
                .replace(/,/g, '.');
              const numericValue = parseFloat(sanitizedValue);
              return numericValue <= 10000000;
            },
          })
          .required(t('Consumption is required')),
        production: Yup.string()
          .matches(regexNumberOnly, {
            message: `${t('Only Numbers allowed')}`,
            excludeEmptyString: true,
          })
          .test({
            name: 'min',
            message: 'Min. Volume- 0',
            test: value => {
              if (!value) return true;
              const sanitizedValue = value
                .replace(/\./g, '')
                .replace(/,/g, '.');
              const numericValue = parseFloat(sanitizedValue);
              return numericValue >= 0;
            },
          })
          .test({
            name: 'max',
            message: `Max Volume- ${formatNumber(10000000)}`,
            test: value => {
              if (!value) return true;
              const sanitizedValue = value
                .replace(/\./g, '')
                .replace(/,/g, '.');
              const numericValue = parseFloat(sanitizedValue);
              return numericValue <= 10000000;
            },
          })

          .required(t('Production is required')),
        cZipCode: Yup.string().required(t('ZIP Code is required')),
        cCity: Yup.string().required(t('City is required')),
        capacity: Yup.string().required(t('Capacity is required')),
        date: Yup.string().required(t('Please select a date')),
      }),
    ),
    paymentDetails: Yup.object().shape({
      bankAccountName: Yup.string().required(t('Name is required')),
      IBAN: Yup.string()
        .matches(IBANRegex, t('Please enter a valid IBAN Number'))
        .required(t('IBAN is required')),
    }),
  });
  const formik = useFormik({
    initialValues: {
      companyName: '',
      activityDescription:'',
      streetAddress: '',
      KvkNumber: '',
      houseNumber: null,
      suffix: '',
      city: '',
      zipCode: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      connections: [],
      paymentDetails: { bankAccountName: '', IBAN: '' },
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async values => {
    setLoading(true);
    if (!values) {
      return;
    }
    try {
      let payload = JSON.parse(JSON.stringify(values));
      let userEmail = payload.email;
      delete payload.plan.amount;
      delete payload.plan.productName;
      delete payload.plan.transactionFee;
      if(SERVER_NAME == 'production' || IS_FEATURED == 'false'){
        delete payload.activityDescription;
        payload.connections = payload.connections = payload.connections.map(({ code, ...rest }) => rest);
      }
      const response = await processAPI.post(
        endpoints.register,
        JSON.stringify([payload]),
      );
      if (response?.status == 200) {
        toast.success(t(response?.data?.message));
        let userId = response?.data?.userId;
        setActiveStep(activeStep + 1);
        const sendActivationMail = await api.post(
          endpoints.sendActivationMail,
          { email: userEmail, userId: userId },
        );
        if (sendActivationMail.status == 201) {
          toast.success(t('Activation mail sent successfully'));
        } else {
          toast.error(
            t('Error sending activation mail. Please contact contact@powerhive.energy for more information.'),
          );
        }
      } else {
        toast.error(response?.data?.value || 'Unable to register');
      }

    } catch (error) {
      console.log('Error registering user', error);
      toast.error(
        error?.response?.data || error?.message ||
          'Internal server Error',
      );
    }

    setLoading(false);
  };

  let steps = [
    {
      step: 0,
      component: <CompanySearch formik={formik} setDisabled={setDisabled} />,
      btnText: `${t('Next Step')}`,
      label: 'company-search',
    },
    {
      step: 1,
      component: <UserDetails formik={formik} setDisabled={setDisabled} />,
      btnText: `${t('Next Step')}`,
      label: 'user-details',
    },
    {
      step: 2,
      component: (
        <ConnectionDetails formik={formik} setDisabled={setDisabled} />
      ),
      btnText: `${t('Next Step')}`,
      label: 'connections',
    },
    {
      step: 3,
      component: <PlanDetails formik={formik} setDisabled={setDisabled} />,
      btnText: `${t('Next Step')}`,
      label: 'choose-plan',
    },
    {
      step: 4,
      component: <PaymentDetails formik={formik} setDisabled={setDisabled} />,
      btnText: `${t('Next Step')}`,
      label: 'payment-details',
    },
    {
      step: 5,
      component: (
        <Summary formik={formik} setSubmitDisable={setSubmitDisable} />
      ),
      btnText: 'Submit',
      label: 'summary',
    },
    {
      step: 6,
      component: <ThankYouPage />,
      btnText: 'Save',
      label: 'thankyou',
    },
  ];
  const handleNextStep = e => {
    e.preventDefault();
    if (activeStep !== steps.length) {
      setPreviousStep(activeStep);
      setActiveStep(activeStep + 1);
    }
  };
  const handlePreviousStep = () => {
    setActiveStep(activeStep - 1);
  };
  useEffect(() => {
    localStorage.setItem('step', activeStep);
    window.location.hash = steps[activeStep]?.label;
    if (activeStep == 0) {
      formik.setValues(formik.initialValues);
    }
  }, [activeStep]);

  return (
    <>
      {isLoading && <Loader />}
      <form
        onSubmit={e => {
          e.preventDefault();
          formik.handleSubmit();
        }}>
        {activeStep != 0 ? (
          <>
            <div className="onboarding-layout">
              <Row className="mt-lg-4 mt-md-3 p-md-0 mx-md-0">
                <Col md={3} className="p-md-0">
                  <SideBar activeStep={activeStep} prevStep={prevStep} />
                </Col>

                <Col md={9} className="p-md-0">
                  {steps[activeStep].component}
                </Col>
              </Row>
            </div>
            <div
              className={`${activeStep == steps.length - 1 ? 'd-none' : 'onboarding-footer-container'} `}>
              <div
                className={`onboarding-footer d-flex justify-content-between`}>
                {![0].includes(activeStep) && (
                  <Button
                    className="my-2 btn-previous px-md-3 items-center"
                    onClick={handlePreviousStep}>
                    <span className="me-2 mb-1-">
                      <svg
                        width="6"
                        height="12"
                        viewBox="0 0 6 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M5.75 0.5L0.25 6L5.75 11.5L5.75 0.5Z"
                          fill="#212121"
                        />
                      </svg>
                    </span>
                    <span>{t('Previous Step')}</span>
                  </Button>
                )}
                {activeStep == 5 ? (
                  <Button
                    className={`${submitDisable ? 'btn-disabled' : 'btn-submit'}  my-2  px-md-3`}
                    disabled={submitDisable}
                    type="submit">
                    {steps[activeStep].btnText}
                    <span className="ms-2">
                      <svg
                        width="6"
                        height="12"
                        viewBox="0 0 6 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M0.25 11.5L5.75 6L0.25 0.5L0.25 11.5Z"
                          fill="#212121"
                        />
                      </svg>
                    </span>
                  </Button>
                ) : (
                  <Button
                    className={`${isDisabled ? 'btn-disabled' : 'btn-submit'} my-2  px-md-3`}
                    onClick={e => handleNextStep(e)}
                    disabled={isDisabled}
                    type="button">
                    {steps[activeStep].btnText}
                    <span className="ms-2">
                      <svg
                        width="6"
                        height="12"
                        viewBox="0 0 6 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M0.25 11.5L5.75 6L0.25 0.5L0.25 11.5Z"
                          fill="#212121"
                        />
                      </svg>
                    </span>
                  </Button>
                )}
              </div>
            </div>
          </>
        ) : (
          <CompanySearch setActiveStep={setActiveStep} formik={formik} />
        )}
      </form>
      {isLoading && <Loader isOpen={isLoading} />}
    </>
  );
};
export default OnBoarding;
