import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../../utilities/api';
import { FcCheckmark } from 'react-icons/fc';
import Search from '../../assests/images/miscellaneous/search.svg';
import { useTranslation } from 'react-i18next';
import { endpoints } from '../../utilities/Endpoints';
import Loader from '../../components/common/Loader';

const PlanDetails = ({ formik = {}, setDisabled = {} }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [monthlySubscription, setmonthlySubscription] = useState(true);
  const [loading, setLoading] = useState(false);
  const [planChange, setPlanChange] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({
    id: '',
    productName: '',
    monthlySubscription: '',
    amount: '',
    transactionFee:0,
  });
  const [planList, setPlanList] = useState({});

  const handleChangePlan = async () => {
    let plan = monthlySubscription ? false : true;
    setmonthlySubscription(plan);
    setDisabled(false);
    if (planList.length == 1) {
      let netAmount = plan
        ? planList[0]?.productCost
        : planList[0]?.productCost * 12;
      setSelectedPlan({
        id: planList[0]?.id,
        productName: planList[0]?.productName,
        monthlySubscription: plan,
        amount: netAmount,
        transactionFee:planList[0]?.transactionFee
      });
      formik.setFieldValue('plan', {
        id: planList[0]?.id,
        productName: planList[0]?.productName,
        monthlySubscription: plan,
        amount: netAmount,
        transactionFee:planList[0]?.transactionFee,
      });
      setDisabled(false);
    } else {
      setSelectedPlan({ productName: '', monthlySubscription: '', amount: '',transactionFee:0 });
      setDisabled(true);
    }
  };
  const handleSelectPlan = plan => {
    let netAmount = monthlySubscription
      ? plan?.productCost
      : plan?.productCost * 12;
    setSelectedPlan({
      id: plan?.id,
      productName: plan?.productName,
      monthlySubscription: monthlySubscription,
      amount: netAmount,
      transactionFee:plan?.transactionFee
    });
    formik.setFieldValue('plan', {
      id: plan?.id,
      productName: plan?.productName,
      monthlySubscription: monthlySubscription,
      amount: netAmount,
      transactionFee:plan?.transactionFee
    });
  };
  const getPlanDetails = async () => {
    setLoading(true);
    setDisabled(true);

    try {
      const response = await api.get(endpoints.productDetails);
      if (response?.status == 200) {
        setPlanList(response?.data?.products);
        let data = response?.data?.products;
        if (data?.length == 1) {
          if (( formik.values.plan == null || formik.values.plan == undefined)) {
            setDisabled(false);
            handleSelectPlan(data[0]);
          }
          else{
            formik.setFieldValue('plan', {
              id: formik.values.plan.id,
              productName: formik.values.plan.productName,
              monthlySubscription: formik.values.plan.monthlySubscription,
              amount: formik.values.plan.amount,
              transactionFee:formik.values.plan.transactionFee
            });
          }
        }
      } else {
        toast.error('Error getting plans.Please try again later');
        navigate('/auth/get-started');
      }
    } catch (error) {
      console.log('Error getting Plan details', error);
      toast.error('Internal server error. Please try again later.');
    }
    setLoading(false);
    setDisabled(false);

  };

  useEffect(() => {
    if (formik.values.plan && formik.values.plan != null) {
      setSelectedPlan({
        id: formik?.values?.plan?.id,
        productName: formik?.values?.plan?.productName,
        monthlySubscription: formik?.values?.plan?.monthlySubscription,
        amount: formik?.values?.plan?.amount,
        transactionFee:formik?.values?.plan?.transactionFee
      });
      setmonthlySubscription(formik?.values?.plan?.monthlySubscription);
      setDisabled(false);
    }
    else {
      setDisabled(true);
    }
   
  }, [formik.values]);

  useEffect(() => {
    getPlanDetails();
  }, []);

  return (
    <div className="plan-details">
      <div className="header d-flex justify-content-between">
        <h3 className="ps-3 title">{t('Choose plan')}</h3>
        <div className="plan-selector-switch ms-1 d-flex flex-grow-1 justify-content-end align-items-center">
          <span
            className={`${monthlySubscription ? 'font-bold' : 'font-regular'} me-1 `}>
            {t('Monthly')}
          </span>
          <label className="switch" htmlFor="switch-inp">
            <input
              id="switch-inp"
              type="checkbox"
              onChange={handleChangePlan}
              checked={monthlySubscription ? false : true}
            />
            <span className="slider round"></span>
          </label>
          <span
            className={`${monthlySubscription == false ? 'font-bold' : 'font-regular'} ms-1`}>
            {t('Yearly')}
          </span>
        </div>
      </div>
      <div className="plan-container ">
        {planChange && <Loader isOpen={planChange} />}
        {loading ? (
          <>
            {' '}
            <div className="no-data-view">
              <img src={Search} className="" height={100} />
              <span className="font-regular text-center">
                {t('Getting Plans')}...
              </span>
            </div>
          </>
        ) : (
          <Row className="">
            {planList?.length &&
              planList.map(plan => (
                <Col md={4} key={plan?.id} className="minH-100">
                  <Card
                    className={`${selectedPlan?.id == plan?.id || planList.length == 1 ? 'selected-plan' : ''} cstm-card minH-100`}>
                    <CardBody>
                      <h5 className="mb-md-3 mb-2  font-bold">
                        {t(`${plan?.productName}`) || 'N/A'}
                      </h5>
                      <h2 className="my-3 font-bold">
                        €
                        {monthlySubscription
                          ? Math.round(plan?.productCost)
                          : plan?.productCost * 12}
                        <small className="font-regular font-sm">
                          / per {t('connection')}{' '}
                          {monthlySubscription ? t('month') : t('yearly')}
                        </small>
                      </h2>
                      <Button
                        className={`${selectedPlan?.id == plan?.id || planList.length == 1 ? 'btn-selected' : 'btn-submit'}  w-100 mw-100 my-2 font-bold`}
                        onClick={() => handleSelectPlan(plan)}>
                        {selectedPlan.id == plan?.id || planList.length == 1
                          ? t('Selected Plan')
                          : t('Select Plan')}
                      </Button>
                      {plan?.defaultFeatures && (
                        <p className="mt-3 font-bold">
                          {t(`${plan?.defaultFeatures}`)}
                        </p>
                      )}
                      {plan?.productFeatures &&
                        plan.productFeatures.map((feature, index) => (
                          <div className="plan-features mt-md-3">
                            <ul className="ps-0">
                              <li className="font-regular fw-bolder">
                                <div className="d-flex">
                                  <div className="me-md-2 me-1">
                                    <FcCheckmark size={20} />
                                  </div>
                                  <p className="d-inline font-regular text-wrap mb-0">
                                    {t(`${feature}`)}
                                  </p>
                                </div>
                              </li>
                            </ul>
                          </div>
                        ))}
                    </CardBody>
                  </Card>
                </Col>
              ))}
          </Row>
        )}
      </div>
    </div>
  );
};
export default PlanDetails;
