import React, { useEffect } from 'react';
import { Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import error from '../../assests/images/icons/Error.png';
import { useTranslation } from 'react-i18next';

const PaymentDetails = ({ formik = {}, setDisabled = {} }) => {
  const {t} = useTranslation()
  const { values, errors, touched } = formik;
  const areAllFieldsFilled = () => {
    for (const key in values.paymentDetails) {
      if (
        !values.paymentDetails[key] ||
        (errors.paymentDetails && errors.paymentDetails[key])
      ) {
        return false;
      }
    }
    return true;
  };

  const handleValidationChange = () => {
    if (areAllFieldsFilled() && !errors.paymentDetails) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };
  useEffect(() => {
    handleValidationChange();
  }, [formik.values.paymentDetails, formik.errors.paymentDetails]);

  return (
    <div className="payment-details-boarding">
      <div className="header">
        <h3 className="ps-3 title">{t('Payment Details')}</h3>
        <p className='mb-2 ps-3'>
          {t('This is used for security purposes by registering which bank account will be used to deposit or withdraw money. No money will be withdrawn from the account.')}
        </p>
      </div>
      <div className="details-container">
        <Card className="cstm-card ">
          <CardBody>
            <Row className='pt-2'>
              <Col md={6}>
                <FormGroup floating>
                  <Input
                    id="paymentDetails.bankAccountName"
                    name="paymentDetails.bankAccountName"
                    placeholder="Name"
                    type="text"
                    className={
                      errors.paymentDetails &&
                      errors.paymentDetails.bankAccountName &&
                      touched.paymentDetails &&
                      touched.paymentDetails.bankAccountName
                        ? 'has-error'
                        : ''
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={values.paymentDetails.bankAccountName}
                  />
                  <Label for="paymentDetails.bankAccountName">{t('Name')}</Label>
                  {errors.paymentDetails &&
                    errors.paymentDetails.bankAccountName &&
                    touched.paymentDetails &&
                    touched.paymentDetails.bankAccountName && (
                      <div className="error-icon">
                        <img src={error} />
                      </div>
                    )}
                  {errors.paymentDetails &&
                    errors.paymentDetails.bankAccountName &&
                    touched.paymentDetails &&
                    touched.paymentDetails.bankAccountName && (
                      <div className="text-danger error-msg">
                        {errors.paymentDetails.bankAccountName}
                      </div>
                    )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup floating>
                  <Input
                    id="paymentDetails.IBAN"
                    name="paymentDetails.IBAN"
                    placeholder="IBAN"
                    type="text"
                    className={
                      errors.paymentDetails &&
                      errors.paymentDetails.IBAN &&
                      touched.paymentDetails &&
                      touched.paymentDetails.IBAN &&
                      'has-error'
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={values.paymentDetails.IBAN}
                  />
                  <Label for="paymentDetails.IBAN">IBAN</Label>
                  {errors.paymentDetails &&
                    errors.paymentDetails.IBAN &&
                    touched.paymentDetails &&
                    touched.paymentDetails.IBAN && (
                      <div className="error-icon">
                        <img src={error} />
                      </div>
                    )}
                  <div className="text-danger error-msg">
                    {errors.paymentDetails &&
                    errors.paymentDetails.IBAN &&
                    touched.paymentDetails &&
                    touched.paymentDetails.IBAN
                      ? errors.paymentDetails.IBAN
                      : ''}
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default PaymentDetails;
