import {Nav, NavItem } from 'reactstrap';
import userIcon from '../assests/images/icons/user.svg';
import pinIcon from '../assests/images/icons/location.svg';
import currencyIcon from '../assests/images/icons/currency.png';
import paymentIcon from '../assests/images/icons/payment.png';
import summaryIcon from '../assests/images/icons/eye.png';
import completedIcon from '../assests/images/icons/completed.png';
import pinIconDark from '../assests/images/icons/pin-dark.png';
import currencyIconDark from '../assests/images/icons/currency-dark.png';
import paymentIconDark from '../assests/images/icons/payment-dark.png';
import summaryIconDark from '../assests/images/icons/summary.png';
import { useTranslation } from 'react-i18next';

const SideBar = ({ activeStep = ''}) => {
  const {t} = useTranslation()
  return (
    <div className="sidebar mb-3">
      <Nav vertical className="p-md-3 p-3 ">
        <NavItem className="py-2" style={{ borderBottom: '1px solid #E7E7E7' }}>
          <h5 className='mb-0 reg-title'>{t('Get Onboarded')}</h5>
        </NavItem>
        <NavItem className="nav-title position-relative ">
          {activeStep == 1 && <div className="border-left"></div>}
          <div className="d-flex align-items-start">
            <img
              src={`${activeStep == 1 ? userIcon : completedIcon}`}
              className="me-3"
            />
            <div>
              <h5>{t('Step')} - 1</h5>
              <h6 className="sub-title">{t('Nice to meet you')}</h6>
            </div>
          </div>
          {activeStep == 1 ? <div className="triangle-right"></div> : ''}
        </NavItem>
        <NavItem className="nav-title position-relative">
          {activeStep == 2 ? <div className="border-left"></div> : ''}

          <div className="d-flex align-items-start">
            <img
              src={`${activeStep == 2 ? pinIconDark : [3, 4, 5,6].includes(activeStep) ? completedIcon : pinIcon} `}
              className="me-3 icon"
            />
            <div>
              <h5>{t('Step')} - 2</h5>
              <h6 className="sub-title ">{t('Where does the power go')}</h6>
            </div>
          </div>
          {activeStep == 2 && <div className="triangle-right"></div>}
        </NavItem>
        <NavItem className="nav-title position-relative">
          {activeStep == 3 && <div className="border-left"></div>}

          <div className="d-flex align-items-start">
            <img
              src={`${activeStep == 3 ? currencyIconDark : [4, 5,6].includes(activeStep) ? completedIcon : currencyIcon}`}
              className="me-3"
            />
            <div>
              <h5>{t('Step')} - 3</h5>
              <h6 className="sub-title">{t('Choose plan')}</h6>
            </div>
          </div>
          {activeStep == 3 && <div className="triangle-right"></div>}
        </NavItem>
        <NavItem className="nav-title position-relative">
          {activeStep == 4 && <div className="border-left"></div>}

          <div className="d-flex align-items-start">
            <img
              src={`${activeStep == 4 ? paymentIconDark : [5,6].includes(activeStep) ? completedIcon : paymentIcon}`}
              className="me-3"
            />
            <div>
              <h5>{t('Step')} - 4</h5>
              <h6 className="sub-title">{t('Payment Details')}</h6>
            </div>
          </div>
          {activeStep == 4 && <div className="triangle-right"></div>}
        </NavItem>
        <NavItem className="nav-title position-relative pb-0">
          {activeStep == 5 && <div className="border-left"></div>}

          <div className="d-flex align-items-start">
            <img
              src={`${activeStep == 5 ? summaryIconDark : [6].includes(activeStep) ? completedIcon : summaryIcon}`}
              className="me-3"
            />
            <div>
              <h5>{t('Step')} - 5</h5>
              <h6 className="sub-title">{t('Summary')}</h6>
            </div>
          </div>
          {activeStep == 5 && <div className="triangle-right"></div>}
        </NavItem>
      </Nav>
    </div>
  );
};
export default SideBar;
