import React from 'react';
import './style.scss';
import icon1 from '../../assests/images/servicesPageImages/servicesIcon1.svg';
import icon2 from '../../assests/images/servicesPageImages/servicesIcon2.svg';
import icon3 from '../../assests/images/servicesPageImages/servicesIcon3.svg';


const HeroSection = () => {
    return (
        <div className="container" id="fold21">
            <div className="container hero-section-services">
                <h1 id="header-21">Jouw co-pilot voor energie</h1>
                <div className='sub-container'>
                    <p id="sub-header-21"> PowerHive brengt alle aspecten van energiebeheer samen op één intuïtief en eenvoudig te gebruiken platform. Door directe toegang tot de energiemarkt, kun je energie kopen en verkopen tegen de meest gunstige prijzen. Met ons gebruiksvriendelijke platform en geavanceerde technologie wordt het slim aansturen van je installaties een fluitje van een cent. Zeg vaarwel tegen traditionele energieleveranciers en neem de controle over uw energiestrategie. Bij PowerHive regel je alles zelf, snel en eenvoudig.</p>
                </div>
            </div>

            <div className="container" id='save-energy-container'>
                <div className="container" id="save-energy">
                    <img src={icon1} alt="Image 1" />
                    <div className="content">
                        <h3>Bespaar tijd</h3>
                        <p>Stop met het vergelijken van energiecontracten en koop je stroom in zoals een energieleverancier</p>
                    </div>
                </div>
                <div className="container" id="save-energy">
                    <img src={icon2} alt="Image 2" />
                    <div className="content">
                        <h3>Hoger rendement</h3>
                        <p>Koop energie direct op de markt zonder dure premies van een leverancier</p>
                    </div>
                </div>
                <div className="container" id="save-energy">
                    <img src={icon3} alt="Image 3" />
                    <div className="content">
                        <h3>Simpel en transparant</h3>
                        <p>Je krijgt wat je ziet, zonder lastige contracten en hoge prijzen</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeroSection;