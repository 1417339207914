import axios from 'axios';
import {APP_BASE_URL, APP_BASE_URL_V2, APP_PORTAL_URL,PAYMENT_BASE_URL,PROCESS_BASE_URL } from '../config/constants';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { logout } from './function';


const api = axios.create({
  baseURL: APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': true,
  },
});
const processAPI = axios.create({
  baseURL: PROCESS_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': true,
  },
});
const paymentAPI = axios.create({
  baseURL:PAYMENT_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': true,
  },
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error && error?.response?.status == 401 && !(window.location.pathname).includes('/auth')) {
      logout()
      toast.error(`${t('Token has expired')}`)
    }
    return Promise.reject(error);
  },
);
paymentAPI.interceptors.response.use(
  response => response,
  error => {
    if (error && error?.response?.status == 401 && !(window.location.pathname).includes('/auth')) {
      logout()
      toast.error(`${t('Token has expired')}`)
    }
    return Promise.reject(error);
  },
);
processAPI.interceptors.response.use(
  response => response,
  error => {
    if (error && error?.response?.status == 401 && !(window.location.pathname).includes('/auth')) {
      logout()
      toast.error(`${t('Token has expired')}`)
    }
    return Promise.reject(error);
  },
);

export { api,processAPI,paymentAPI };
