import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import MyBalance from '../../components/UserDashboard/MyBalance';
import EpexSpot from '../../components/UserDashboard/EpexSpot';
import HistoryTransaction from '../../components/UserDashboard/HistoryTransaction';
import Analytics from '../../components/UserDashboard/Analytics';
import Expenses from '../../components/UserDashboard/Expenses';
import ReactSelect, { components } from 'react-select';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import CustomTooltip from '../../components/common/CustomeTooltip';
import { useNavigate } from 'react-router-dom';
import { api, paymentAPI, processAPI } from '../../utilities/api';
import Loader from '../../components/common/Loader';
import { useTranslation } from 'react-i18next';
import { endpoints } from '../../utilities/Endpoints';
import { IS_FEATURED, SERVER_NAME } from '../../config/constants';
import HistoryPayments from '../../components/UserDashboard/HistoryPayments';

const Dashboard = () => {
  const naviagte = useNavigate();
  const { t } = useTranslation();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(1);
  const [presentMonth, setPresentMonth] = useState(new Date().getMonth() + 1);
  const [presentYear, setPresentYear] = useState(new Date().getFullYear());
  const [loading, setLoading] = useState(false);
  // const [isWalletActive, setWalletActive] = useState(false);
  const [walletDetails, setWalletDetails] = useState({
    balance: 0,
    daysRemaining: 0,
  });

  const idToken =
    sessionStorage.getItem('idToken') || localStorage.getItem('idToken');
  const accessToken =
    sessionStorage.getItem('accessToken') ||
    localStorage.getItem('accessToken');
  const companyId =
    sessionStorage.getItem('companyId') || localStorgae.getItem('companyId');

  // const month = presentMonth < 10 ? '0' + presentMonth : presentMonth;

  const options = [
    { value: '1', label: `${t('This month')}` },
    { value: '2', label: `${t('Last month')}` },
  ];
  const customStyles = {
    control: provided => ({
      ...provided,
      height: '30px',
      minHeight: '30px',
      borderColor: 'transparent',
      boxShadow: 'none',
      cursor: 'pointer',
      '&:hover': {
        height: '30px',
        minHeight: '30px',
        borderColor: 'transparent',
        boxShadow: 'none',
        cursor: 'pointer',
      },
      '&:active': {
        boxShadow:
          ' rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
      },
    }),
    valueContainer: provided => ({
      ...provided,
      height: '30px',
      minHeight: '30px',
      minWidth: '90px',
      marginBottom: '0.25rem',
      color: '#757575',
    }),
    singleValue: provided => ({
      ...provided,
      color: '#757575',
    }),
    menu: provided => ({
      ...provided,
      borderColor: 'transparent',
      borderRadius: '5px 5px',
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      marginTop: '5px',
      borderRadius: '5px',
      cursor: 'pointer',
    }),
    indicatorsContainer: provided => ({
      ...provided,
      height: '30px',
      minHeight: '30px',
    }),
    option: provided => ({
      ...provided,
      backgroundColor: 'transparent',
      cursor: 'pointer',
      padding: '4px 8px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    }),
  };
  useEffect(() => {
    if (selectedOption == 1) {
      setPresentMonth(new Date().getMonth() + 1);
      setPresentYear(new Date().getFullYear());
    } else {
      setPresentMonth(presentMonth - 1);
      setPresentYear(new Date().getFullYear());
    }
  }, [selectedOption]);
  const getBalanceAmount = async () => {
    setLoading(true);
    try {
      if (IS_FEATURED == 'true') {
        const response = await paymentAPI.get(
          `${endpoints.balancePaymentAPI}?companyId=${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              accessToken: accessToken,
            },
          },
        );
        if (response?.status == 200) {
          let resData = response?.data;
          setWalletDetails({
            balance: resData?.powerhiveAmount,
            daysRemaining: resData?.daysRemaining,
          });
          sessionStorage.setItem('spendableAmount',resData?.spendableAmount)
        }
      } else {
        const companyId =
          sessionStorage.getItem('companyId') ||
          localStorage.getItem('comapnyId');
        const response = await api.get(`${endpoints.balance}`, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            companyId: companyId,
          },
        });
        if (response?.status == 200) {
          let resData = response?.data;
          setWalletDetails({
            balance: resData?.balance,
            daysRemaining: resData?.estimatedNumDays,
          });
        }
      }
    } catch (error) {
      console.log('Error getting balance amount', error);
      setWalletDetails({
        balance: 0,
        daysRemaining: 0,
      });
    }
    setLoading(false);
  };
  const navigateToWallet = () => {
    naviagte('/user/app/wallet');
    sessionStorage.setItem('active-tab', 'wallet');
  };

  useEffect(() => {
    getBalanceAmount();
 
  }, []);
  return (
    <div className="user-dashboard py-2- pt-3">
      {loading && <Loader isOpen={loading} />}
      <CustomTooltip>
        <Row className="m-0 px-lg-2 px-1">
          <Col className="my-balance gy-2 " xl={4} md={6}>
            <h5 className="title pb-1">{t('My Balance')}</h5>
            <MyBalance
              balance={walletDetails?.balance}
              estDays={walletDetails?.daysRemaining}
              type="dashboard"
            />
          </Col>
          <Col className="epex-spot gy-2" xl={4} md={6}>
            <div className="items-center justify-content-between pb-1">
              <h5 className="title ">{t('Epex Spot')}</h5>
              <div className="forecast-date fs-14">{t('Today')}</div>
            </div>
            <EpexSpot />
          </Col>
          <Col
            className="history-transactions gy-2 history-md"
            xl={4}
            lg={6}
            sm={12}>
            <div className="items-center justify-content-between pb-1">
              <h5 className="title">{t('History Transaction')}</h5>
              <span
                className="btn-link fs-14 font-bold"
                role="button"
                onClick={navigateToWallet}>
                {t('View All')}
              </span>
            </div>
            {IS_FEATURED == 'true' ? (
              <HistoryPayments />
            ) : (
              <HistoryTransaction />
            )}
          </Col>
          <Col className="expenses d-xl-none d-inline   gy-2" lg={6} sm={12}>
            <div className="items-center justify-content-between py-1">
              <h5 className="title">{t('All Expenses')}</h5>
              <div className="forecast-date- fs-14 user-select">
                <ReactSelect
                  options={options}
                  defaultValue={options[0]}
                  hideSelectedOptions
                  components={{
                    DropdownIndicator: () =>
                      isMenuOpen ? (
                        <MdArrowDropUp size={25} />
                      ) : (
                        <MdArrowDropDown size={25} />
                      ),
                    ClearIndicator: () => '',
                    IndicatorSeparator: () => null,
                  }}
                  styles={customStyles}
                  isSearchable={false}
                  className="cursor-pointer"
                  onChange={e => setSelectedOption(e?.value)}
                  onMenuOpen={() => setMenuOpen(true)}
                  onMenuClose={() => setMenuOpen(false)}
                />
              </div>
            </div>
            <Expenses month={presentMonth} year={presentYear} />
          </Col>
          <Col className="analytics gy-2" xl={8}>
            <div className="items-center justify-content-between py-1">
              <h5 className="title ">{t('Analytics')}</h5>
              <div className="forecast-date fs-14 user-select-">
                {t('Last week')}
              </div>
            </div>
            <Analytics />
          </Col>
          <Col className="expenses d-xl-inline d-none gy-2" xl={4}>
            <div className="items-center justify-content-between py-1">
              <h5 className="title">{t('All Expenses')}</h5>
              <div className="forecast-date- fs-14 user-select">
                <ReactSelect
                  options={options}
                  defaultValue={options[0]}
                  hideSelectedOptions
                  components={{
                    DropdownIndicator: () =>
                      isMenuOpen ? (
                        <MdArrowDropUp size={25} />
                      ) : (
                        <MdArrowDropDown size={25} />
                      ),
                    ClearIndicator: () => '',
                    IndicatorSeparator: () => null,
                  }}
                  styles={customStyles}
                  isSearchable={false}
                  className="cursor-pointer"
                  onChange={e => setSelectedOption(e?.value)}
                  onMenuOpen={() => setMenuOpen(true)}
                  onMenuClose={() => setMenuOpen(false)}
                />
              </div>
            </div>
            <Expenses month={presentMonth} year={presentYear} />
          </Col>
        </Row>
      </CustomTooltip>
      {/* {!isWalletActive && (
        <KYCPopup isOpen={!isWalletActive} handleClose={handleClose} />
      )} */}
    </div>
  );
};

export default Dashboard;
