import React, { useState, useEffect } from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
import Icon1 from '../../assests/images/homePageImages/Home - Forecasting & Analytics.svg';
import Icon2 from '../../assests/images/homePageImages/Home - Energy Trading.svg';
import Icon3 from '../../assests/images/homePageImages/Home - Energy Management.svg';
import Icon4 from '../../assests/images/homePageImages/Home - Certification & Administration.svg';

const FoldTwo = () => {
    const redirectionLoad = (e) => {
        e.preventDefault();
        localStorage.setItem('scrollToTop', 'true');
        document.location.href = e.target.href;
    };

    useEffect(() => {
        if (localStorage.getItem('scrollToTop') === 'true') {
            window.scrollTo(0, 0);
            localStorage.removeItem('scrollToTop');
        }
    }, []);
    return (
        <div className="container" id="fold2">
            <div className="heading-container-foldtwo">
                <h1 id="header-2">Alles in één oplossing voor energie</h1>
            </div>
            <div className="container energyfold">
                <div className="container" id="energy">
                    <img src={Icon1} alt="Image 1" />
                    <div className="content">
                        <h3>Inzicht & Voorspelling</h3>
                        <p>Duidelijk overzicht van je toekomstige verbruik en wat er gebeurd op de markt. Zo heb jij alle informatie om je energierekening te optimaliseren.</p>
                    </div>
                </div>
                <div className="container" id="energy">
                    <img src={Icon2} alt="Image 2" />
                    <div className="content">
                        <h3>Energie Marktplaats</h3>
                        <p>Koop in zonder tussenkomst van leverancier. PowerHive biedt direct toegang tot de groothandel zonder dure premies en winstmarges.</p>                    </div>
                </div>
                <div className="container" id="energy">
                    <img src={Icon3} alt="Image 3" />
                    <div className="content">
                        <h3>Energie optimalisatie</h3>
                        <p>Slimme aansturing door het EMS van PowerHive zorgt voor meer controle over je energierekening en een verbetert rendement van je installaties.</p>
                    </div>
                </div>
                <div className="container" id="energy">
                    <img src={Icon4} alt="Image 4" />
                    <div className="content">
                        <h3>Certificering & administratie</h3>
                        <p>PowerHive biedt de eerste marktplaats voor groene stroom certificaten. Je kunt hier direct in- of verkopen zonder tussenkomst van dure brokers.</p>                    </div>
                </div>
                <div className="button-container" id="fold-2-button">
                    <Link id="join" to="/services" onClick={redirectionLoad}>Lees Verder</Link>
                </div>
            </div>
        </div>
    );
};

export default FoldTwo;