import React, { useEffect, useState } from 'react';
import './style.scss';
import LinkedInLogo from '../../assests/images/aboutUsPageImages/LinkedInLogo.svg';
import LinkedInImage1 from '../../assests/images/aboutUsPageImages/florian.png';
import LinkedInImage2 from '../../assests/images/aboutUsPageImages/sinduja-img.png';
import LinkedInImage3 from '../../assests/images/aboutUsPageImages/contactimg.svg';
import { Link } from 'react-router-dom';

const FoldThree = () => {
    const [hoveredContent, setHoveredContent] = useState(null);

    useEffect(() => {
        const updateOverlay = (id, isHovered) => {
            const overlay = document.getElementById(`${id}-overlay`);
            if (overlay) {
                overlay.style.opacity = isHovered ? '1' : '0';
                overlay.style.backgroundImage = isHovered ? `url(${LinkedInLogo})` : 'none';
                overlay.style.backgroundRepeat = 'no-repeat';
                overlay.style.backgroundPosition = 'center';
            }
        };

        if (hoveredContent !== null) {
            updateOverlay(hoveredContent, true);
        }

        return () => {
            if (hoveredContent !== null) {
                updateOverlay(hoveredContent, false);
            }
        };
    }, [hoveredContent]);

    const handleMouseEnter = (id) => {
        setHoveredContent(id);
    };

    const handleMouseLeave = () => {
        setHoveredContent(null);
    };

    return (
        <div className='container' id='fold13'>
            <div className="heading-container">
                <h1 id="header-13">Ons Team</h1>
            </div>
            <div className='container' id='image-container'>
                <Link to="https://www.linkedin.com/in/florianarndt/" target="_blank" ><div
                    className='content'
                    id='content1'
                    onMouseEnter={() => handleMouseEnter('content1')}
                    onMouseLeave={handleMouseLeave}
                >
                    
                    <img src={LinkedInImage1} alt='linkedimage1' className='photoCard' />
                    <div className='overlay' id='content1-overlay'></div>
                    <div className='content-container'>
                        <h1>Florian Arndt</h1>
                        <p>Founder & CEO</p>
                    </div>
                </div></Link>
                <Link to="https://www.linkedin.com/in/sinduja-yoganantham/" target="_blank"><div
                    className='content'
                    id='content2'
                    onMouseEnter={() => handleMouseEnter('content2')}
                    onMouseLeave={handleMouseLeave}
                >
                    <img src={LinkedInImage2} alt='linkedimage2' className='photoCard' />
                    <div className='overlay' id='content2-overlay'></div>
                    <div className='content-container'>
                        <h1>Sinduja Yoganantham</h1>
                        <p>Head of Software Development</p>
                    </div>
                </div></Link>
                <Link to="https://www.linkedin.com/company/powerhive-nl/" target="_blank"><div
                    className='content'
                    id='content3'
                    onMouseEnter={() => handleMouseEnter('content3')}
                    onMouseLeave={handleMouseLeave}
                >
                    <img src={LinkedInImage3} alt='linkedimage3' className='photoCard' />
                    <div className='overlay' id='content3-overlay'></div>
                    <div className='content-container'>
                        <h1>Kom jij ons versterken?</h1>
                        <p>Neem contact met ons op!</p>
                    </div>
                </div></Link>
            </div>
        </div>
    );
};

export default FoldThree;
