import React, { useEffect, useState } from 'react';
import { MdDashboard } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';
import { IoWallet } from 'react-icons/io5';
import OrdersIcon from '../../assests/images/icons/orders.svg';
import OrdersDark from '../../assests/images/icons/orders-dark.svg';
import TransactionLight from '../../assests/images/icons/TransactionLight.png';
import TransactionDark from '../../assests/images/icons/trans_dark.png';
import ConfirmationPopup from './ConfirmationPopup';
import { IoMdHelpCircle } from 'react-icons/io';
import { useTranslation } from 'react-i18next';

const Sidebar = () => {
  const {t} = useTranslation()
  const [active, setActive] = useState('');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const setActiveTab = () => {
      switch (pathname) {
        case '/':
        case '/user/app/dashboard':
          setActive('dashboard');
          navigate('/user/app/dashboard');
          break;
        case '/user/app/wallet':
          setActive('wallet');
          break;
        case '/user/app/orders':
          setActive('orders');
          break;
        case '/user/app/transactions':
          setActive('transactions');
          break;
        default:
          setActive('');
          break;
      }
    };
    setActiveTab();
  }, [pathname, navigate]);

  const handleNavItem = (tab, path) => {
    const isEditing = sessionStorage.getItem('isEditing');
    sessionStorage.setItem('next-path', path);
    sessionStorage.setItem('next-tab', tab);
    if (isEditing == 'true' && tab != 'orders') {
      setIsOpen(true);
      return;
    }
    sessionStorage.setItem('active-tab', tab);
    setActive(tab);
    navigate(path);
  };
  const handleProceed = () => {
    sessionStorage.removeItem('isEditing');
    setIsOpen(false);
    let path = sessionStorage.getItem('next-path');
    let tab = sessionStorage.getItem('next-tab');
    sessionStorage.setItem('active-tab', tab);
    setActive(tab);
    navigate(path);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const navItems = [
    {
      id: 'dashboard',
      icon: (
        <MdDashboard
          size={25}
          color={`${active == 'dashboard' ? '#001427' : '#757575'}`}
        />
      ),
      text: 'Dashboard',
    },
    {
      id: 'wallet',
      icon: (
        <IoWallet
          size={25}
          color={`${active == 'wallet' ? '#001427' : '#757575'}`}
        />
      ),
      text: 'Wallet',
    },
    {
      id: 'orders',
      icon: (
        <img src={active === 'orders' ? OrdersDark : OrdersIcon} alt="Orders" />
      ),
      text: t('Orders'),
    },
    {
      id: 'transactions',
      icon: (
        <img
          src={active === 'transactions' ? TransactionDark : TransactionLight}
          alt="Transactions"
        />
      ),
      text: t('Transactions'),
    },
  ];

  return (
    <div className="user-sidebar mb-3">
      <Nav vertical className="p-md-3 p-3 ">
        {navItems.map(item => (
          <NavItem
            key={item.id}
            className="user-nav-item position-relative"
            onClick={() => handleNavItem(item.id, `/user/app/${item.id}`)}
            role="button">
            {active === item.id && <div className="border-left"></div>}
            <div className="items-center">
              <span className="me-3 nav-icon">{item.icon}</span>
              <h6
                className={`${active === item.id ? 'text-dark font-bold' : 'text-gray font-regular'} mb-0 pt-2`}>
                {item.text}
              </h6>
            </div>
          </NavItem>
        ))}
      </Nav>
      {isOpen && (
        <ConfirmationPopup
          isOpen={isOpen}
          type="confirm"
          title="Save Changes"
          content={t('You are attempting to switch to other tab. Are you sure want to proceed without saving?')}
          confirmationText2="OK"
          confirmationText1="Cancel"
          icon={<IoMdHelpCircle size={25} color="#212121" />}
          handleClose={handleClose}
          handleSave={handleProceed}
        />
      )}
    </div>
  );
};

export default Sidebar;
