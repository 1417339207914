import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import error from '../assests/images/icons/Error.png';

const ForgotPassword = () => {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email Address is required'),
  });
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      handleSubmit();
    },
  });
  const handleSubmit = async () => {
    
  };

  return (
    <div className="auth-layout">
      <Row className="w-100 m-md-4 m-2">
        <Col lg={7} xl={6} xs={12}>
          <Card className="bg-auth"><div className='bg-auth-footer'>{t('Make energy work for you')}</div></Card>
        </Col>
        <Col lg={5} xl={6} xs={12} className="auth-container p-md-4">
          <Card className="auth-card ">
            <CardBody className="py-lg-5 py-3">
              <h2 className="text-center font-bold mb-3">Forgot Password</h2>
              <p className="text-center font-regular mx-md-4 text-light">
                Reset Password link will be sent to the below email address.
                Kindly ensure to also check your spam folder.
              </p>
              <form className="mx-md-4 mx-1" onSubmit={formik.handleSubmit}>
                <div style={{ margin: '2.5rem 0 8rem 0' }}>
                  <FormGroup className="position-relative " floating>
                    <Input
                      id="email"
                      type="text"
                      name="email"
                      placeholder="Email Address"
                      className={
                        formik.errors.email &&
                        formik.touched.email &&
                        'has-error'
                      }
                      onChange={formik.handleChange}
                    />
                    <Label htmlFor="email">Email Address</Label>
                    <div className="text-danger error-msg position-absolute">
                      {formik.errors.email && formik.touched.email
                        ? formik.errors.email
                        : ''}
                    </div>
                    {formik.errors.email && formik.touched.email && (
                      <div className="error-icon top-50">
                        <img src={error} />
                      </div>
                    )}
                  </FormGroup>
                </div>
                <div className="d-flex justify-content-between align-items-end">
                  <div>
                    <a href="/auth/login" className="text-dark font-bold ">
                      Back to Login
                    </a>
                  </div>
                  <div className="d-flex justify-content-center align-items-end">
                    <Button className=" btn-submit" type="submit">
                      Reset Password
                    </Button>
                  </div>
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default ForgotPassword;
