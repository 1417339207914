import React, { Suspense, lazy, useEffect, useState } from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from 'react-router-dom';
import AuthRoutes from './routes/AuthRoutes';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import Loader from './components/common/Loader';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import AppRoutes from './routes/AppRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoggedIn } from './redux/AuthReducer';
import Layout from './routes/MainRoute';
import PublicRoutes from './routes/PublicRoutes';
import NotFound from './components/common/NotFound';
import ErrorBoundary from './components/common/ErrorBoundary';


function App() {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector(store => store?.userInfo);
  useEffect(() => {
    if (sessionStorage.getItem('accessToken')) {
      dispatch(setIsLoggedIn(true));
    } else {
      dispatch(setIsLoggedIn(false));
    }
  }, [isLoggedIn]);

  return (
    <div className="App">
      <ErrorBoundary fallback='Error'>
      <Suspense fallback={<Loader />}>
        <Router>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route
                index
                element={isLoggedIn ? <Navigate to="/dashboard" /> : <PublicRoutes />}
              />
              <Route path="/user/*" element={isLoggedIn && <AppRoutes />} />
              <Route path="/auth/*" element={!isLoggedIn && <AuthRoutes />} />
              <Route path="/*" element={<PublicRoutes />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </Suspense>
      </ErrorBoundary>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={true}
        rtl={false}
        theme="dark"
      />
    </div>
  );
}

export default App;
