import React from 'react';
import './style.scss';
import signature from '../../assests/images/aboutUsPageImages/signature-ceo.svg';
import logo1 from '../../assests/images/aboutUsPageImages/aboutlogo-1.svg';
import logo2 from '../../assests/images/aboutUsPageImages/aboutlogo-2.svg';
import logo3 from '../../assests/images/aboutUsPageImages/aboutlogo-3.svg';
import logo4 from '../../assests/images/aboutUsPageImages/aboutlogo-4.svg';
import logo5 from '../../assests/images/aboutUsPageImages/aboutlogo-5.svg';


const HeroSection = () => {
    return (
        <div className='container' id='fold1'>
            <div className="head">
                <h1 id="header-11">Energie aan het werk voor jou</h1>
            </div>
            <div className="container" id='sub-head'>
                <h2 id="sub-header-11">
                    In de energiemarkt ontbreekt het nog aan de disruptieve kracht van merken zoals Apple, Uber of Airbnb. PowerHive staat klaar om deze markt te veranderen met een revolutionair energieplatform, waarbij klanten zelf de touwtjes in handen kunnen nemen. Ons doel? Een robuuster energiesysteem realiseren tegen lagere kosten                </h2>
            </div>
            <div className='container' id='banner'>
                <img src={signature} alt='sign' />
                <h3>Florian Arndt</h3>
                <p>Director of PowerHive</p>
            </div>
            {/*<div className='container' id='logo-container'>
                <img src={logo1} alt='logo-1' />
                <img src={logo2} alt='logo-2' />
                <img src={logo3} alt='logo-3' />
                <img src={logo4} alt='logo-4' />
                <img src={logo5} alt='logo-5' />
            </div>*/}
        </div>
    );
}

export default HeroSection;