import {
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import addIcon from '../../assests/images/icons/addIcon.svg';
import binIcon from '../../assests/images/icons/bin.svg';
import NoDataPage from '../../components/NoDataPage';
import calendar from '../../assests/images/icons/calendar.svg';
import { useEffect, useState } from 'react';
import error from '../../assests/images/icons/Error.png';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/light.css';
import moment from 'moment';
import AddConnectionPopup from '../../components/OnBoarding/AddConnectionPopup';
import { getConnectionDetails } from '../../pages/OnBoarding/function';
import { useTranslation } from 'react-i18next';
import { SERVER_NAME } from '../../config/constants';

const ConnectionDetails = ({ formik = {}, setDisabled = {} }) => {
  const { values, errors } = formik;
  const [isOpen, setOpen] = useState(false);
  const [eanNumbers, setEanNumbers] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddConnection = () => {
    setOpen(true);
  };
  const handleRemoveConnection = index => {
    const updatedConnections = [...formik.values.connections];
    let ean = eanNumbers.filter(
      item => item != formik?.values?.connections[index]?.EAN,
    );
    setEanNumbers(ean);
    updatedConnections.splice(index, 1);
    formik.setFieldValue('connections', updatedConnections);
  };
  const checkAllFieldsFilled = () => {
    let isAllFilled = false;
    isAllFilled = values.connections.every(connection => {
      return (
        (connection.streetAddress !== '' &&
          connection.EAN !== '' &&
          connection.houseNumber !== '' &&
          connection.consumption !== '' &&
          connection.production !== '' &&
          connection.zipCode !== '' &&
          connection.city !== '' &&
          connection.capacity !== '' &&
          connection.date != '') ||
        connection.date != null
      );
    });

    const noErrors =
      errors.connections && errors.connections.length > 0 ? false : true;

    if (isAllFilled && noErrors) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };
  const formatNumberWithCommas = (number, field) => {
    let value;
    if (typeof number != 'number') {
      value = number.replace(/[^\d.]/g, '');
    } else {
      value = number;
    }
    return Number(value).toLocaleString('nl-NL');
    // formik.setFieldValue(field,formattedValue)
  };
  useEffect(() => {
    checkAllFieldsFilled();
    if (formik.values.connections.length == 0) {
      setDisabled(true);
    }
  }, [values, errors]);
  useEffect(() => {
    if (values?.connections.length == 0) {
      setDisabled(true);
      getEANDeatils();
    }
  }, []);

  const getEANDeatils = async () => {
    setLoading(true);
    let postalCode = formik.values.zipCode;
    let houseNumber = Number(formik.values.houseNumber);
    let suffix = formik.values.suffix;
    try {
      let response = await getConnectionDetails(
        postalCode,
        houseNumber,
        suffix,
      );
      if (response?.status === 200) {
        const fetchedConnections = response.data || [];
        if (fetchedConnections.length != 0) {
          const updatedConnections = fetchedConnections.map(connection => ({
            streetAddress: values?.streetAddress || '',
            EAN: connection?.EAN || '', // Set EAN from fetched data
            cHouseNumber: Number(values?.houseNumber) || '',
            cSuffix: values?.suffix || '',
            consumption:
              connection.consumptionVolume != 0 &&
              connection?.consumptionVolume != undefined
                ? String(connection.consumptionVolume)
                : connection.consumptionVolume == 0
                  ? '0'
                  : '',
            production:
              connection.productionVolume != 0 &&
              connection?.productionVolume != undefined
                ? String(connection.productionVolume)
                : connection.productionVolume == 0
                  ? '0'
                  : '',
            cZipCode: values?.zipCode || '',
            cCity: values?.city || '',
            code:values?.code,
            capacity:
              connection.capacity != 0 && connection?.capacity != undefined
                ? String(connection.capacity)
                : connection.capacity == 0
                  ? 0
                  : '',
            date: '',
          }));
          let EANNumbers = updatedConnections.map(item => item?.EAN);
          setEanNumbers(EANNumbers);
          formik.setFieldValue('connections', updatedConnections);
        } 
        // else {
        //   formik.setFieldValue('connections', [
        //     {
        //       streetAddress: values?.streetAddress,
        //       EAN: '',
        //       cHouseNumber: Number(values?.houseNumber),
        //       cSuffix: values?.suffix || '',
        //       consumption: '',
        //       production: '',
        //       cZipCode: values?.zipCode || '',
        //       cCity: values?.city || '',
        //       capacity: '',
        //       date: '',
        //     },
        //   ]);
        // }
      }
    } catch (error) {
      console.log('Error in feteching data', error);
    }
    setLoading(false);
  };
  const handleDateChange = (date, index) => {
    let dateNew = moment(date[0]).toISOString();
    formik.setFieldValue(`connections[${index}].date`, dateNew);
  };
  return (
    <>
      <div className="user-details">
        <div className="header d-flex justify-content-between">
          <h3 className="ps-3 title">{t('Where does the power go')}</h3>
          <span
            role="button"
            className="btn-link font-bold"
            onClick={handleAddConnection}>
            <h6 className="mb-0">
              <img src={addIcon} height={20} className="mb-1 me-1" />
              {t('Add a connection')}
            </h6>
          </span>
        </div>
        {isOpen && (
          <AddConnectionPopup
            formik={formik}
            isOpen={isOpen}
            handleClose={handleClose}
            eanNumbers={eanNumbers}
            setEanNumbers={setEanNumbers}
          />
        )}
        <div className="details-container">
          {values.connections.length > 0 ? (
            values.connections.map((connection, index) => (
              <Card
                className={`company-details ${index != 0 ? 'mt-3' : ''}`}
                key={index}>
                <CardBody>
                  <CardTitle className="mb-4 d-flex justify-content-between">
                    <h4 className="font-bold">
                      {t('Connection')} {index + 1}
                    </h4>
                    <span
                      className="remove-connection"
                      role="button"
                      onClick={() => handleRemoveConnection(index)}>
                      <h6 className="mb-0 text-danger">
                        {' '}
                        <img src={binIcon} height={20} className="mb-1 me-1" />
                        {t('Remove')}
                      </h6>
                    </span>
                  </CardTitle>
                  <Row className="form-container">
                    <Col md={6}>
                      <FormGroup floating className="">
                        <Input
                          id={`connections.${index}.streetAddress`}
                          name={`connections.${index}.streetAddress`}
                          placeholder="Street Address"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.connections[index].streetAddress}
                          readOnly
                        />
                        <Label for={`connections.${index}.streetAddress`}>
                          {t('Street Address')}
                        </Label>
                        {errors.connections &&
                          errors.connections[index] &&
                          errors.connections[index].streetAddress && (
                            <div className="error-icon">
                              <img src={error} />
                            </div>
                          )}
                        <div className="text-danger error-msg">
                          {errors.connections &&
                          errors.connections[index] &&
                          errors.connections[index].streetAddress
                            ? errors.connections[index].streetAddress
                            : ''}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup floating className="mb-lg-4 mb-3">
                        <Input
                          id={`connections.${index}.EAN`}
                          name={`connections.${index}.EAN`}
                          placeholder="EAN"
                          type="text"
                          onChange={formik.handleChange}
                          value={values.connections[index].EAN}
                          onBlur={formik.handleBlur}
                          className={
                            errors.connections &&
                            errors.connections[index] &&
                            errors.connections[index].EAN &&
                            'has-error'
                          }
                          // readOnly
                        />
                        <Label for={`connections.${index}.EAN`}>EAN</Label>
                        {errors.connections &&
                          errors.connections[index] &&
                          errors.connections[index].EAN && (
                            <div className="error-icon">
                              <img src={error} />
                            </div>
                          )}
                        <div className="text-danger error-msg">
                          {errors.connections &&
                          errors.connections[index] &&
                          errors.connections[index].EAN
                            ? errors.connections[index].EAN
                            : ''}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col lg={3} sm={6}>
                      <FormGroup floating className="mb-lg-4 mb-3">
                        <Input
                          id={`connections.${index}.cHouseNumber`}
                          name={`connections.${index}.cHouseNumber`}
                          placeholder="Housenumber"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={values.connections[index].cHouseNumber}
                          readOnly={
                            values.connections[index].cHouseNumber
                              ? true
                              : false
                          }
                        />
                        <Label for={`connections.${index}.cHouseNumber`}>
                          {t('House No.')}
                        </Label>
                        {errors.connections &&
                          errors.connections[index] &&
                          errors.connections[index].cHouseNumber && (
                            <div className="error-icon">
                              <img src={error} />
                            </div>
                          )}
                        <div className="text-danger error-msg">
                          {errors.connections &&
                          errors.connections[index] &&
                          errors.connections[index].cHouseNumber
                            ? errors.connections[index].cHouseNumber
                            : ''}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col lg={3} sm={6}>
                      <FormGroup floating className="">
                        <Input
                          id={`connections.${index}.cSuffix`}
                          name={`connections.${index}.cSuffix`}
                          placeholder="Suffix"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={values.connections[index].cSuffix}
                        />
                        <Label for={`connections.${index}.cSuffix`}>
                          {t('Suffix')}
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col lg={3} sm={6}>
                      <FormGroup floating className="mb-lg-4 mb-3">
                        <Input
                          id={`connections.${index}.consumption`}
                          name={`connections.${index}.consumption`}
                          placeholder="Consumption"
                          type="text"
                          // onChange={formik.handleChange}
                          value={values.connections[index].consumption}
                          onBlur={formik.handleBlur}
                          // onKeyUp={(e)=>formatNumberWithCommas(e.target.value,`connections.${index}.consumption`)}
                          onChange={formik.handleChange}
                          // onChange={e => {
                          //   const inputValue = e.target.value;
                          //   // const formattedValue =
                          //   //   formatNumberWithCommas(inputValue);
                          //   formik.setFieldValue(
                          //     `connections.${index}.consumption`,
                          //     inputValue,
                          //   );
                          // }}
                          className={
                            errors.connections &&
                            errors.connections[index] &&
                            errors.connections[index].consumption &&
                            'has-error'
                          }
                        />
                        <Label for={`connections.${index}.consumption`}>
                          {t('Consumption')}(kWh)
                        </Label>
                        {errors.connections &&
                          errors.connections[index] &&
                          errors.connections[index].consumption && (
                            <div className="error-icon">
                              <img src={error} />
                            </div>
                          )}
                        <div className="text-danger error-msg">
                          {errors.connections &&
                          errors.connections[index] &&
                          errors.connections[index].consumption
                            ? errors.connections[index].consumption
                            : ''}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col lg={3} sm={6}>
                      <FormGroup floating className="mb-lg-4 mb-3">
                        <Input
                          id={`connections.${index}.production`}
                          name={`connections.${index}.production`}
                          placeholder="Production"
                          type="text"
                          className={
                            errors.connections &&
                            errors.connections[index] &&
                            errors.connections[index].production &&
                            'has-error'
                          }
                          onChange={formik.handleChange}
                          // onChange={e => {
                          //   const inputValue = e.target.value;
                          //   const formattedValue =
                          //     formatNumberWithCommas(inputValue);
                          //   formik.setFieldValue(
                          //     `connections.${index}.production`,
                          //     formattedValue,
                          //   );
                          // }}
                          value={values.connections[index].production}
                          onBlur={formik.handleBlur}
                        />
                        <Label for={`connections.${index}.production`}>
                          {t('Production')}(kWh)
                        </Label>
                        {errors.connections &&
                          errors.connections[index] &&
                          errors.connections[index].production && (
                            <div className="error-icon">
                              <img src={error} />
                            </div>
                          )}
                        <div className="text-danger error-msg">
                          {errors.connections &&
                          errors.connections[index] &&
                          errors.connections[index].production
                            ? errors.connections[index].production
                            : ''}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col lg={3} sm={6}>
                      <FormGroup floating className="">
                        <Input
                          id={`connections.${index}.cZipCode`}
                          name={`connections.${index}.cZipCode`}
                          placeholder="ZIP Code"
                          type="text"
                          className={
                            errors.connections &&
                            errors.connections[index] &&
                            errors.connections[index].cZipCode &&
                            'has-error'
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={values.connections[index].cZipCode}
                          readOnly
                        />
                        <Label for={`connections.${index}.zipCode`}>
                          {t('ZIP Code')}
                        </Label>
                        {errors.connections &&
                          errors.connections[index] &&
                          errors.connections[index].cZipCode && (
                            <div className="error-icon">
                              <img src={error} />
                            </div>
                          )}
                        <div className="text-danger error-msg">
                          {errors.connections &&
                          errors.connections[index] &&
                          errors.connections[index].cZipCode
                            ? errors.connections[index].cZipCode
                            : ''}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col lg={3} sm={6}>
                      <FormGroup floating className="">
                        <Input
                          id={`connections.${index}.cCity`}
                          name={`connections.${index}.cCity`}
                          placeholder="Town / City"
                          type="text"
                          className={
                            errors.connections &&
                            errors.connections[index] &&
                            errors.connections[index].cCity &&
                            'has-error'
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={values.connections[index].cCity}
                          readOnly={
                            values.connections[index].cCity ? true : false
                          }
                        />
                        <Label for={`connections.${index}.cCity`}>
                          {t('Town / City')}
                        </Label>
                        <div className="text-danger error-msg">
                          {errors.connections &&
                          errors.connections[index] &&
                          errors.connections[index].cCity
                            ? formik.errors.connections[index].cCity
                            : ''}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col lg={3} sm={6}>
                      <FormGroup floating className="">
                        <Input
                          id={`connections.${index}.capacity`}
                          name={`connections.${index}.capacity`}
                          placeholder="Capacity"
                          type="text"
                          onChange={formik.handleChange}
                          // onChange={e => {
                          //   const inputValue = e.target.value;
                          //   const formattedValue =
                          //     formatNumberWithCommas(inputValue);
                          //   formik.setFieldValue(
                          //     `connections.${index}.capacity`,
                          //     formattedValue,
                          //   );
                          // }}
                          value={values.connections[index].capacity}
                          onBlur={formik.handleBlur}
                          className={
                            errors.connections &&
                            errors.connections[index] &&
                            errors.connections[index].capacity &&
                            'has-error'
                          }
                        />
                        <Label for={`connections.${index}.capacity`}>
                          {t('Capacity')}
                        </Label>
                        {errors.connections &&
                          errors.connections[index] &&
                          errors.connections[index].capacity && (
                            <div className="error-icon">
                              <img src={error} />
                            </div>
                          )}
                        <div className="text-danger error-msg">
                          {errors.connections &&
                          errors.connections[index] &&
                          errors.connections[index].capacity
                            ? errors.connections[index].capacity
                            : ''}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col lg={3} sm={6} className="date-input">
                      <FormGroup floating>
                        <Flatpickr
                          name={`connections[${index}].date`}
                          value={values.connections[index].date}
                          onChange={date => handleDateChange(date, index)}
                          className="form-control position-relative"
                          placeholder="Start date"
                          options={{
                            dateFormat: 'd M Y',
                            position: 'auto',
                            minDate: SERVER_NAME == 'production' ?  new Date().fp_incr(14) : new Date().fp_incr(1),
                            maxDate: new Date().fp_incr(90),
                          }}
                          // onBlur={formik.handleBlur}
                        />
                        <div
                          id="calendar-icon"
                          className="custom-calendar-icon">
                          <img src={calendar} />
                        </div>
                        <Label for={`connections.${index}.date`}>
                          {t('Start Date')}
                        </Label>
                        <div className="text-danger error-msg">
                          {errors.connections &&
                          errors.connections[index] &&
                          errors.connections[index].date
                            ? errors.connections[index].date
                            : ''}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            ))
          ) : (
            <NoDataPage loading={isLoading}/>
          )}
        </div>
      </div>
    </>
  );
};

export default ConnectionDetails;
