import React, { useEffect, useState } from 'react';
import MessageIcon from '../../assests/images/homePageImages/Message.svg';
import VectorIcon from '../../assests/images/homePageImages/Vector.svg';
import AddressIcon from '../../assests/images/homePageImages/Address.svg';
import { api } from '../../utilities/api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap';
import { FaCaretRight } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { endpoints } from '../../utilities/Endpoints';

const ContactForm = () => {
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showRecaptcha, setShowRecaptcha] = useState(false);
  const [focused, setFocused] = useState(false);

  let regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  let regexPhone = /^\d{10}$/;

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Voornaam is verplicht'),
    lastName: Yup.string(),
    email: Yup.string()
      .matches(regexEmail, 'Ongeldig e-mailadres')
      .required('E-mail is vereist'),
    phone: Yup.string()
      .matches(regexPhone, 'Voer het juiste telefoon in')
      .required('Telefoon is vereist'),
    message: Yup.string()
      .required('Bericht is vereist')
      .min(5, 'Minimaal 5 tekens vereist')
      .max(200, 'Maximaal 200 tekens'),
  });
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      message: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      handleContactUs(values);
    },
  });
  const { values, errors, touched, setFieldTouched } = formik;

  useEffect(() => {
    if (window.location.hostname === 'powerhive.energy') {
      setShowRecaptcha(true);
    }
  }, []);

  const handleInputChange = (e, field) => {
    formik.setFieldValue(field, e.target.value);
    formik.setFieldTouched(field, true);
  };
  const handleFocus = () => {
    setFocused(true);
  };
  const handleInputBlur = e => {
    if (!values?.phone) {
      setFocused(false);
    }
    setFieldTouched('phone', true);
  };
  const handleContactUs = async values => {
    let country_code = '+31';

    setLoading(true);
    setDisabled(true);
    try {
      const modifiedValues = {
        ...values,
        phone: country_code.concat(values.phone),
      };
      const response = await api.post(endpoints.contactUs, modifiedValues);
      if (response.status == 200) {
        toast.success('Bericht verstuurd!');
        formik.handleReset();
      }
    } catch (error) {
      toast.error(error.statusText || 'Internal Server Error');
    }
    setDisabled(false);
    setLoading(false);
  };

  const checkAllFieldsFilled = () => {
    const noErrors = errors && errors.length > 0 ? false : true;
    if (
      values.firstName != '' &&
      values.email != '' &&
      values.phone != '' &&
      values.message != '' &&
      noErrors
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  useEffect(() => {
    checkAllFieldsFilled();
  }, [values, errors]);
  return (
    <div className="container contact-us">
      <div className="card-content">
        <div className="container contact-info">
          <h1>
            Wil je weten wat PowerHive voor jou kan betekenen? Neem gerust
            contact met ons op, we staan klaar om je te helpen. Hieronder vind
            je hoe je ons kunt bereiken.
          </h1>
          <div className="container">
            <div className="box social-box">
              <img className="social-icon" src={MessageIcon} alt="Message" />
            </div>
            <div className="content">
              <h2>Mail ons</h2>
              <a href="mailto:contact@powerhive.energy" target="_blank">
                contact@powerhive.energy
              </a>
            </div>
          </div>
          <div className="container">
            <div className="box social-box">
              <img className="social-icon" src={VectorIcon} alt="Phone" />
            </div>
            <div className="content">
              <h2>Bel ons</h2>
              <a href="tel:+31636174388" target="_blank">
                (085) 013 2430
              </a>
            </div>
          </div>
          <div className="container">
            <div className="box social-box">
              <img className="social-icon" src={AddressIcon} alt="Address" />
            </div>
            <div className="content">
              <h2>Ons adres</h2>
              <a
                href="https://maps.google.com/?q=Prins Bernardlaan 15 3441XG, Woerden"
                target="_blank"
                rel="noopener noreferrer">
                Prins Bernardlaan 15 <br />
                <span className="space"> </span>3441XG, Woerden
              </a>
            </div>
          </div>
        </div>
        <div className="container contact-form">
          <h3>Neem contact op</h3>
          <form
            onSubmit={formik.handleSubmit}
            name="contact-us-form"
            autoComplete="off">
            <Row>
              <Col md={6}>
                <div className="mb-lg-3 mb-2">
                  <div className="floating-label">
                    <input
                      type="text"
                      className="form-input"
                      id="firstName"
                      name="firstName"
                      placeholder=""
                      autoComplete="off"
                      value={values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <label htmlFor="firstName">Voornaam</label>
                  </div>
                  {errors.firstName && touched.firstName ? (
                    <div className="error-msg text-danger position-absolute">
                      {errors.firstName}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-lg-3 mb-2">
                  <div className="floating-label">
                    <input
                      type="text"
                      className="form-input"
                      id="lastName"
                      name="lastName"
                      placeholder=""
                      autoComplete="off"
                      value={values.lastName}
                      onChange={e => handleInputChange(e, 'lastName')}
                      onBlur={formik.handleBlur}
                    />
                    <label htmlFor="lastName">Achternaam</label>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-lg-3 mb-2">
                  <div className="floating-label">
                    <input
                      type="email"
                      className="form-input"
                      id="email"
                      name="email"
                      placeholder=""
                      autoComplete="off"
                      value={values.email}
                      onChange={e => handleInputChange(e, 'email')}
                      onBlur={formik.handleBlur}
                    />
                    <label htmlFor="email">E-mail</label>
                    {errors.email && touched.email ? (
                      <div className="error-msg text-danger position-absolute">
                        {errors.email}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </Col>
              <Col md={6} className="items-center my-lg-0 my-3 ">
                <div
                  className={`floating-input w-100 mb-0 ${focused || values.phone != '' ? 'focused' : ''}`}>
                  <InputGroup>
                    <InputGroupText>+31</InputGroupText>
                    <InputGroupText className="p-0 seperator"></InputGroupText>
                    <Input
                      id="phone"
                      name="phone"
                      type="text"
                      className="phone-input"
                      onChange={e => handleInputChange(e, 'phone')}
                      value={values.phone}
                      onFocus={handleFocus}
                      onBlur={handleInputBlur}
                    />
                    <Label for="phone" className="input-label">
                      Telefoon
                    </Label>
                  </InputGroup>
                  {errors.phone && touched.phone ? (
                    <div className="error-msg text-danger position-absolute">
                      {errors.phone}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-4">
                  <div className="floating-label">
                    <textarea
                      className="form-input"
                      id="messageInput"
                      name="message"
                      placeholder=""
                      autoComplete="off"
                      value={values.message}
                      onChange={e => handleInputChange(e, 'message')}
                      onBlur={formik.handleBlur}
                    />
                    <label htmlFor="message">Typ je bericht</label>
                    {errors.message && touched.message ? (
                      <div className="error-msg text-danger position-absolute">
                        {errors.message}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </Col>

              {/*secret key = 6LfgSekpAAAAAHAS3klz_g0EIqLhBGh1EK3Zzn7*/}
            </Row>
            <div
              className="g-recaptcha mb-2"
              data-sitekey="6Lf6G_UpAAAAAO-y-4OR0TLWjqHujT0yGToT9XCY"></div>
            <div className="contact-submit-button">
              <button id="submitButton" type="submit" disabled={disabled}>
                <span>{loading ? 'Please wait..' : 'Verstuur'}</span>
                {!loading && (
                  <FaCaretRight
                    size={16}
                    color={disabled ? '#757575' : '#212121'}
                  />
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
