import React, { createContext, useContext, useLayoutEffect, useState } from "react";


const TooltipContext = createContext();

//Function for all those little tooltip context consumers out there
export function useTooltipContext() {
  const tooltipContext = useContext(TooltipContext);
  return tooltipContext;
}

//Tooltip provider - place somewhere in your app with access to full window
//width. You can use the same thing for all of your tooltips, and the callback
//functions openTooltip and closeTooltip will modify it's state
export default function CustomTooltip({ children }) {
  const emptyTooltip = {
    open: false,
    content: null,
    style: null
  };
  const [tooltip, setTooltip] = useState(emptyTooltip);

  const openTooltip = ({ content, style }) => {
    setTooltip({
      open: true,
      content: content,
      style: style
    });
  };

  const closeTooltip = () => {
    setTooltip(emptyTooltip);
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Tooltip tooltip={tooltip} />
      <TooltipProvider openTooltip={openTooltip} closeTooltip={closeTooltip}>
        {children}
      </TooltipProvider>
    </div>
  );
}

const Tooltip = ({ tooltip }) => {
  const position = useMousePosition();
  //This part is essential to the tooltip functioning properyly, but
  //I haven't thought of a way to stop listening to useMousePosition
  //when the tooltip is closed
  const left = tooltip.open ? position.x : -9999;
  const top = tooltip.open ? position.y : -9999;

  return (
    <div
      style={{
        position: "fixed",
        left: left + 5,
        top: top + 5,
        zIndex: 9999,
        ...tooltip.style
      }}
    >
      {tooltip.content}
    </div>
  );
};

//Used in the CustomTooltip wrapper above
function TooltipProvider({ children, openTooltip, closeTooltip }) {
  const tooltipContext = React.useMemo(() => {
    return {
      openTooltip: openTooltip,
      closeTooltip: closeTooltip
    };
  }, [openTooltip, closeTooltip]);

  return (
    <TooltipContext.Provider value={tooltipContext}>
      {children}
    </TooltipContext.Provider>
  );
}
 function useMousePosition() {
  const [position, setPosition] = useState({
    x: 0,
    y: 0
  });
  useLayoutEffect(() => {
    function updatePosition(e) {
      setPosition({ x: e.clientX, y: e.clientY });
    }
    document.addEventListener("mousemove", updatePosition);
    return () => document.removeEventListener("mousemove", updatePosition);
  }, []);

  return position;
}
