import React from 'react';
import { Col, FormGroup, Input, Row, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const BankDetails = ({ data = '' }) => {
  const {t} = useTranslation()
  const { bankAccountName, ibanNumber } = data;
  return (
    <div>
      <div className="py-xl-5 tab-details">
        <Row className="form-container">
          <Col md={6} className="mb-3">
            <FormGroup floating className="">
              <Input
                id="accountName"
                name="accountName"
                placeholder="Name"
                type="text"
                readOnly
                disabled
                value={bankAccountName}
              />
              <Label for="accountName">{t('Name')}</Label>
            </FormGroup>
          </Col>
          <Col md={6} className="mb-3">
            <FormGroup floating className="">
              <Input
                id="IBAN"
                name="IBAN"
                placeholder="IBAN"
                type="text"
                value={ibanNumber}
                readOnly
                disabled
              />
              <Label for="IBAN">IBAN</Label>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BankDetails;
