import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, Tooltip } from 'reactstrap';
import closeIcon from '../../assests/images/icons/close.svg';
import { MdAdd } from 'react-icons/md';
import { HiMinusSm } from 'react-icons/hi';
import { formatNumber } from '../../utilities/function';
import logo from '../../assests/images/AppLogo/logo_Icon.png';
import { PiCopySimpleFill } from 'react-icons/pi';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { paymentAPI, processAPI } from '../../utilities/api';
import { IS_FEATURED } from '../../config/constants';
import { endpoints } from '../../utilities/Endpoints';
import {
  getBankDetails,
  getKYCStatus,
} from '../../pages/UserDashboard/function';
import Loader from '../common/Loader';

const PaymentPopup = ({ isOpen = {}, handleClose = {}, handleSave = {} }) => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState(0);
  const [avgCost, setAvgCost] = useState(1);
  const [isDisabled, setDisabled] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [disableReduced, setDisableReduce] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [estRecord, setEstRecord] = useState(0);
  const [bankDetails, setBankDetails] = useState({
    username: '',
    iban: null,
  });
  const [initialDeposit, setInitialDeposit] = useState(0);
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const companyId = sessionStorage.getItem('companyId');
  const accessToken = sessionStorage.getItem('accessToken');

  const handleCopy = value => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        setCopied(true);
        const intervalId = setTimeout(() => {
          setCopied(false);
        }, 3000);
        return () => clearTimeout(intervalId);
      })
      .catch(() => {
        toast.error('Error copying code.Please try again later.');
      });
  };
  const getAverageCost = async () => {
    setLoading(true);
    try {
      const response = await processAPI.get(
        `${endpoints.getAverageCostAPI}/${companyId}`,
        {
          headers: {
            accessToken: accessToken,
          },
        },
      );
      let avgCostPerDay = response?.data;
      setAvgCost(avgCostPerDay);
      let spendableAmount = sessionStorage.getItem('spendableAmount');
      setAmount(0);
      let estDays =
        avgCostPerDay != 0 ? Math.round(spendableAmount / avgCostPerDay) : 0;
      setEstRecord(estDays || 0);
    } catch (error) {
      console.log('Error getting average cost', error);
    }
    setLoading(false);
  };
  const getUserBankDetails = async () => {
    setLoading(true);
    const userId = sessionStorage.getItem('userId');
    const bankDetailsResponse = await getBankDetails(userId);
    if (bankDetailsResponse) {
      setBankDetails({
        username: bankDetailsResponse?.bankAccountName,
        iban: bankDetailsResponse?.ibanNumber,
      });
      setLoading(false);
    }
  };
  const handleWithdraw = async () => {
    if (IS_FEATURED == 'true') {
      setLoading(true);
      try {
        let payload = {
          quantity: {
            assetCode: 'EUR_MPV',
            value: 1,
          },
          bankAccountNumber: bankDetails?.iban,
        };
        if (bankDetails?.iban) {
          const withdrawalRes = await paymentAPI.post(
            `${endpoints.submitWithdrawalRequestAPI}`,
            payload,
            {
              headers: {
                accessToken: accessToken,
                companyId: companyId,
              },
            },
          );
          toast.success(withdrawalRes?.data);
          handleClose();
        }
      } catch (error) {
        console.log('Error in withdrawal', error);
        toast.error(t('Something went wrong. Please try again later'));
      }
      setLoading(false);
    } else {
      handleClose();
    }
  };
  const handleAmount = (type, amount) => {
    setAmount(Math.round(amount));
    if (IS_FEATURED == 'true') {
      let spendableAmount = sessionStorage.getItem('spendableAmount');

      if (type === 'decrement') {
        let estDays = Math.round((spendableAmount - amount) / avgCost);
        setEstRecord(Math.abs(estDays || 0));
      }
      if (type == 'increment') {
        let estDays = Math.round((spendableAmount - amount) / avgCost);
        setEstRecord(estDays || 0);
      }
    }
  };

  useEffect(() => {
    if (IS_FEATURED == 'true') {
      if (amount == 0) {
        setDisableReduce(true);
        setDisableSubmit(true);
      } else {
        if(estRecord <= 5){
          setDisabled(true)
        }
        else{
          setDisabled(false)
          setDisableReduce(false);
          setDisableSubmit(false);
        }
      }
    }
  }, [amount, estRecord]);
  useEffect(() => {
    if (IS_FEATURED == 'true') {
      getAverageCost();
      getUserBankDetails();
    }
  }, []);
  return (
    <Modal isOpen={isOpen} centered backdrop className="mw-350">
      <ModalBody className="p-lg-4 p-3">
        <span role="button" className="close-button">
          <img src={closeIcon} height={25} onClick={handleClose} />
        </span>
        <div className="d-flex align-items-end justify-content-start">
          <h5 className={`mb-0 font-bold`}>{t('Withdraw funds')}</h5>
        </div>

        <p className=" font-regular">
          {t('Select amount to withdraw from your wallet')}
        </p>
        <div className="amount-counter cstm-button">
          <button
            className="btn-counter"
            disabled={disableReduced}
            onClick={() => handleAmount('decrement', amount - 5000)}>
            <HiMinusSm
              size={25}
              color={`${disableReduced ? '#CECECE' : '#FF9900'} `}
            />
          </button>
          {isDisabled && (
            <Tooltip
              isOpen={tooltipOpen}
              target="btn-add"
              className=""
              toggle={toggle}>
              {t('You have insufficient balance in your wallet')}
            </Tooltip>
          )}
          <h3 className="font-bold mb-0">
            €{formatNumber(Math.round(amount))}
          </h3>
          <button
            className="btn-counter"
            onClick={() => handleAmount('increment', amount + 5000)}
            disabled={isDisabled}
            id="btn-add">
            <MdAdd size={25} color={`${isDisabled ? '#CECECE' : '#FF9900'} `} />{' '}
          </button>
        </div>
       {IS_FEATURED == 'true' && <div className="items-center justify-content-center font-regular py-2">
          <img src={logo} height={22} className="mb-1" />{' '}
          <p className="mb-0">
            Estimated <span className="btn-link px-1">{estRecord}</span>days
            remaining after withdrawal
          </p>
        </div>}
        <div className="payment-details d-grid cstm-button mt-4">
          {IS_FEATURED == 'false' ? (
            <p className="font-regular fs-16 mb-0">
              Om geld op te nemen kun je contact opnemen met onze klantenservice
            </p>
          ) : loading ? (
            ''
          ) : (
            <div>
              <div>
                <p className="text-gray mb-0">{t('Name')}:</p>
                <p className="text-black">{bankDetails?.username}</p>
              </div>
              <div>
                <p className="text-gray mb-0">IBAN:</p>
                <p className="text-black mb-0">{bankDetails?.iban}</p>
              </div>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-center mt-4">
          <Button
            className="btn-submit px-4"
            type="submit"
            onClick={handleWithdraw}
            disabled={
              IS_FEATURED == 'false'
                ? amount < 0
                  ? true
                  : false
                : disableSubmit
            }>
            {t('OK')}
          </Button>
        </div>
        {loading && <Loader isOpen={loading} />}
      </ModalBody>
    </Modal>
  );
};

export default PaymentPopup;
