import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap';
import error from '../../assests/images/icons/Error.png';
import { useTranslation } from 'react-i18next';

const UserDetails = ({ formik = {}, setDisabled = {} }) => {
  const { t } = useTranslation();
  const { errors, touched, values, setFieldTouched } = formik;
  const [readOnlyFields, setReadOnlyFields] = useState({});
  const [focused, setFocused] = useState(false);

  const checkAllFieldsFilled = () => {
    const fieldNames = [
      'companyName',
      'streetAddress',
      'KvkNumber',
      'houseNumber',
      'city',
      'zipCode',
      'firstName',
      'email',
      'phone',
    ];
    const { values, errors } = formik;
    const isAllFilled = fieldNames.every(fieldName => {
      const value = values[fieldName];
      if (Array.isArray(value)) {
        return value.every(innerValue => innerValue && innerValue !== '');
      }
      return value && value !== '';
    });
    const errorsForFields = Object.keys(errors)
      .filter(key => fieldNames.includes(key))
      .reduce((obj, key) => {
        obj[key] = errors[key];
        return obj;
      }, {});

    // const errors = formik.errors;
    const noError =
      Object.keys(errorsForFields).length === 0 &&
      errorsForFields.constructor === Object;
    if (isAllFilled && noError) {
      if (
        formik.values?.lastName &&
        formik.errors?.lastName != '' &&
        formik.errors?.lastName != undefined
      ) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    } else {
      setDisabled(true);
    }
  };
  useEffect(() => {
    let companyDetails = localStorage.getItem('company-details');
    if (companyDetails) {
      const fields = [
        'companyName',
        'streetAddress',
        'KvkNumber',
        'houseNumber',
        'city',
        'zipCode',
      ];
      const readOnlyFieldsObj = {};
      fields.forEach(field => {
        if (values[field]) {
          readOnlyFieldsObj[field] = true;
        }
      });
      setReadOnlyFields(readOnlyFieldsObj);
    }
  }, []);

  useEffect(() => {
    checkAllFieldsFilled();
  }, [formik.values, formik.errors]);

  const handleFocus = () => {
    setFocused(true);
  };
  const handleInputBlur = e => {
    if (!values?.phone) {
      setFocused(false);
    }
    setFieldTouched('phone', true);
  };
  return (
    <>
      <div className="user-details">
        <div className="header">
          <h3 className="ps-3 title">{t('Nice to meet you')}</h3>
        </div>
        <div className="details-container">
          <Card className="company-details">
            <CardBody>
              <CardTitle className="mb-4">
                <h4 className="font-bold">{t('Company Details')}</h4>
              </CardTitle>

              <Row className="form-container">
                <Col md={6}>
                  <FormGroup floating className="">
                    <Input
                      id="companyName"
                      name="companyName"
                      placeholder="Company Name"
                      type="text"
                      onChange={e => {
                        let str = e.target.value;
                        if (str.charAt(0) == ' ') {
                          return;
                        }
                        formik.setFieldValue('companyName', e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      value={values.companyName}
                      className={
                        errors.companyName && touched.companyName && 'has-error'
                      }
                      readOnly={readOnlyFields['companyName']}
                    />
                    <Label for="companyName">{t('Company Name')}</Label>
                    {errors.companyName && touched.companyName && (
                      <div className="error-icon">
                        <img src={error} />
                      </div>
                    )}
                    <div className="text-danger error-msg">
                      {errors.companyName && touched.companyName
                        ? errors.companyName
                        : ''}
                    </div>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup floating className="">
                    <Input
                      id="streetAddress"
                      name="streetAddress"
                      placeholder="Street Address"
                      type="text"
                      onChange={e => {
                        let str = e.target.value;
                        if (str.charAt(0) == ' ') {
                          return;
                        }
                        formik.setFieldValue('streetAddress', e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      value={values.streetAddress}
                      readOnly={readOnlyFields['streetAddress']}
                      className={
                        errors.streetAddress &&
                        touched.streetAddress &&
                        'has-error'
                      }
                    />
                    <Label for="streetAddress">{t('Street Address')}</Label>
                    {errors.streetAddress && touched.streetAddress && (
                      <div className="error-icon">
                        <img src={error} />
                      </div>
                    )}
                    <div className="text-danger error-msg">
                      {errors.streetAddress && touched.streetAddress
                        ? errors.streetAddress
                        : ''}
                    </div>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup floating className="">
                    <Input
                      id="KvkNumber"
                      name="KvkNumber"
                      placeholder="KvK Number"
                      type="text"
                      onChange={e => {
                        let str = e.target.value;
                        if (str.charAt(0) == ' ') {
                          return;
                        }
                        formik.setFieldValue('KvkNumber', e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      value={values.KvkNumber}
                      readOnly={readOnlyFields['KvkNumber']}
                      className={
                        errors.KvkNumber && touched.KvkNumber && 'has-error'
                      }
                    />
                    <Label for="KvkNumber ">{t('KvK Number')}</Label>
                    {formik.errors.KvkNumber && formik.touched.KvkNumber && (
                      <div className="error-icon">
                        <img src={error} />
                      </div>
                    )}
                    <div className="text-danger error-msg">
                      {errors.KvkNumber && touched.KvkNumber
                        ? errors.KvkNumber
                        : ''}
                    </div>
                  </FormGroup>
                </Col>
                <Col lg={3} sm={6} className="pe-lg-0">
                  <FormGroup floating className="">
                    <Input
                      id="houseNumber"
                      name="houseNumber"
                      placeholder="Housenumber"
                      type="text"
                      onChange={e => {
                        let str = e.target.value;
                        if (str.charAt(0) == ' ') {
                          return;
                        }
                        formik.setFieldValue('houseNumber', e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      value={values.houseNumber}
                      readOnly={readOnlyFields['houseNumber']}
                      className={
                        errors.houseNumber && touched.houseNumber && 'has-error'
                      }
                    />
                    <Label for="houseNumber">{t('House No.')}</Label>
                    {errors.houseNumber && touched.houseNumber && (
                      <div className="error-icon">
                        <img src={error} />
                      </div>
                    )}
                    <div className="text-danger error-msg">
                      {errors.houseNumber && touched.houseNumber
                        ? errors.houseNumber
                        : ''}
                    </div>
                  </FormGroup>
                </Col>
                <Col lg={3} sm={6}>
                  <FormGroup floating className="">
                    <Input
                      id="suffix"
                      name="suffix"
                      placeholder="suffix"
                      type="text"
                      onChange={e => {
                        let str = e.target.value;
                        if (str.charAt(0) == ' ') {
                          return;
                        }
                        formik.setFieldValue('suffix', e.target.value);
                      }}
                      value={values.suffix}
                      onBlur={formik.handleBlur}
                      readOnly={readOnlyFields['suffix']}
                      className={errors.suffix && touched.suffix && 'has-error'}
                    />
                    <Label for="suffix">{t('Suffix')}</Label>
                    {errors.suffix && touched.suffix && (
                      <div className="error-icon">
                        <img src={error} />
                      </div>
                    )}
                    <div className="text-danger error-msg">
                      {errors.suffix && touched.suffix ? errors.suffix : ''}
                    </div>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup floating className="">
                    <Input
                      id="city"
                      name="city"
                      placeholder="Town / City"
                      type="text"
                      onChange={e => {
                        let str = e.target.value;
                        if (str.charAt(0) == ' ') {
                          return;
                        }
                        formik.setFieldValue('city', e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      value={values.city}
                      readOnly={readOnlyFields['city']}
                      className={errors.city && touched.city && 'has-error'}
                    />
                    <Label for="city ">{t('Town / City')}</Label>
                    {errors.city && touched.city && (
                      <div className="error-icon">
                        <img src={error} />
                      </div>
                    )}
                    <div className="text-danger error-msg">
                      {errors.city && touched.city ? errors.city : ''}
                    </div>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup floating className="">
                    <Input
                      id="zipCode"
                      name="zipCode"
                      placeholder="ZIP Code"
                      type="text"
                      onChange={e => {
                        let str = e.target.value;
                        if (str.charAt(0) == ' ') {
                          return;
                        }
                        formik.setFieldValue('zipCode', e.target.value);
                      }}
                      className={
                        errors.zipCode && touched.zipCode && 'has-error'
                      }
                      onBlur={formik.handleBlur}
                      value={values.zipCode}
                      readOnly={readOnlyFields['zipCode']}
                    />
                    <Label for="zipCode ">{t('ZIP Code')}</Label>
                    {errors.zipCode && touched.zipCode && (
                      <div className="error-icon">
                        <img src={error} />
                      </div>
                    )}
                    <div className="text-danger error-msg">
                      {errors.zipCode && touched.zipCode ? errors.zipCode : ''}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className="personal-details mt-md-3">
            <CardBody>
              <CardTitle className="mb-4">
                <h4 className="font-bold">{t('Personal Details')}</h4>
              </CardTitle>

              <Row className="form-container">
                <Col md={6} lg={3}>
                  <FormGroup floating className="">
                    <Input
                      id="firstName"
                      name="firstName"
                      placeholder="First Name"
                      type="text"
                      onChange={e => {
                        let str = e.target.value;
                        if (str.charAt(0) == ' ') {
                          return;
                        }
                        formik.setFieldValue('firstName', e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      value={values.firstName}
                      className={
                        errors.firstName && touched.firstName && 'has-error'
                      }
                    />
                    <Label for="firstName">{t('First Name')}</Label>
                    {errors.firstName && touched.firstName && (
                      <div className="error-icon">
                        <img src={error} />
                      </div>
                    )}
                    <div className="text-danger error-msg">
                      {errors.firstName && touched.firstName
                        ? errors.firstName
                        : ''}
                    </div>
                  </FormGroup>
                </Col>
                <Col md={6} lg={3}>
                  <FormGroup floating className="">
                    <Input
                      id="lastName"
                      name="lastName"
                      placeholder="Last Name"
                      type="text"
                      className={
                        errors.lastName && touched.lastName && 'has-error'
                      }
                      onChange={e => {
                        let str = e.target.value;
                        if (str.charAt(0) == ' ') {
                          return;
                        }
                        formik.setFieldValue('lastName', e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      value={values.lastName}
                    />
                    <Label for="lastName">{t('Last Name')}</Label>
                    {errors.lastName && touched.lastName && (
                      <div className="error-icon">
                        <img src={error} />
                      </div>
                    )}
                    <div className="text-danger error-msg">
                      {errors.lastName && touched.lastName
                        ? errors.lastName
                        : ''}
                    </div>
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup floating className="">
                    <Input
                      id="email"
                      name="email"
                      placeholder="Email Address"
                      type="text"
                      onChange={e => {
                        let str = e.target.value;
                        if (str.charAt(0) == ' ') {
                          return;
                        }
                        formik.setFieldValue('email', e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      value={values.email}
                      className={errors.email && touched.email && 'has-error'}
                    />
                    <Label for="email">{t('Email Address')}</Label>
                    {errors.email && touched.email && (
                      <div className="error-icon">
                        <img src={error} />
                      </div>
                    )}
                    <div className="text-danger error-msg">
                      {errors.email && touched.email ? errors.email : ''}
                    </div>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <div
                    className={`floating-input mb-0 ${focused || values.phone != '' ? 'focused' : ''}`}>
                    <InputGroup
                      className={`${errors.phone && touched.phone ? 'has-error' : ''}`}>
                      <InputGroupText>+31</InputGroupText>
                      <InputGroupText className="p-0 seperator"></InputGroupText>
                      <Input
                        id="phone"
                        name="phone"
                        // placeholder="Phone Number"
                        type="text"
                        onChange={e => {
                          let str = e.target.value;
                          if (str.charAt(0) == ' ') {
                            return;
                          }
                          formik.setFieldValue('phone', e.target.value);
                        }}
                        onBlur={e => handleInputBlur(e)}
                        value={values.phone}
                        onFocus={handleFocus}
                      />
                      <Label for="phone" className="input-label">
                        {t('Phone Number')}
                      </Label>
                    </InputGroup>
                    {errors.phone && touched.phone && (
                      <div className="error-icon">
                        <img src={error} />
                      </div>
                    )}
                    <div className="text-danger error-msg">
                      {errors.phone && touched.phone ? errors.phone : ''}
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
      {/* {isPreview && (
        <Preview
          isOpen={isPreview}
          handleClose={handleClose}
          file={values.passport}
        />
      )} */}
    </>
  );
};

export default UserDetails;
