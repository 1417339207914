import React, { useEffect, useState } from 'react';
import {Outlet } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import UserSidebar from '../../components/common/UserSidebar';

const UserDashboard = () => {


  return (
    <Row className="p-0 m-0">
      <Col md={3} lg={2} >
        <UserSidebar />
      </Col>
      <Col md={9} lg={10}  className='outlet-dashboard'>
        <Outlet />
      </Col>
    </Row>
  );
};

export default UserDashboard;
