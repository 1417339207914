import { useEffect, useState } from 'react';
import NoData from '../../assests/images/miscellaneous/transaction.svg';
import EmptyIcon from '../../assests/images/miscellaneous/search.svg';
import { Card, CardBody, Spinner, Table } from 'reactstrap';
import moment from 'moment/moment';
import { formatNumber } from '../../utilities/function';
import { api } from '../../utilities/api';
import { FaAngleRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { endpoints } from '../../utilities/Endpoints';

const HistoryTransaction = () => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasData,setHasData] = useState(true)
  const getTransactionHistory = async type => {
    if (type != 'showMore') {
      setLoading(true);
    }
    try {
      const idToken =
        sessionStorage.getItem('idToken') || localStorage.getItem('idToken');
      const companyId =
        sessionStorage.getItem('companyId') ||
        localStorage.getItem('companyId');
      const response = await api.get(`${endpoints.historyWallet}?offset=${offset}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          companyId:companyId
        },
      });
      if (response?.status == 200) {
        if (type != 'showMore') {
          setData(response?.data);
        } else {
          setData([...data, ...response?.data]);
          if(response?.data.length <= 0){
            setHasData(false)
          }
        }
      }
    } catch (error) {
      console.log('Error getting transaction history', error);
      setData([]);
    }
    setLoading(false);
    setShowMore(false);
  };
  const handleShowMore = () => {
    setShowMore(true);
    setOffset(offset + 10);
  };
  useEffect(() => {
    if (offset && showMore) {
      getTransactionHistory('showMore');
    } else {
      setOffset(0);
      getTransactionHistory();
    }
  }, [offset]);
  return (
    <Card className={`${data.length <= 0 ? '' : 'mh-120'} cstm-card mb-2`}>
      <CardBody>
      {loading || !data || data.length <= 0 ? (
        <div className="no-data-view fs-16">
          <img src={!loading ? NoData : EmptyIcon} alt="No Data" height={100} />
          {loading ? (
            <span className="font-regular text-center">
              {t('Fetching Transactions')}
            </span>
          ) : (
            <span className="font-regular text-center">
              {t('No transactions yet')}
            </span>
          )}
        </div>
      ) : (
        <div className="history-table">
          <Table className="summary-box mb-0" borderless>
            <thead>
              <tr>
                <td
                  className="table-header text-center"
                  valign="top"
                  width="10%"></td>
                <td
                  className="table-header text-start"
                  valign="top"
                  width="25%">
                  {t('TYPE')}
                </td>
                <td
                  className="table-header text-start"
                  valign="top"
                  width="25%">
                  {t('DATE')}
                </td>
                {/* <td
                  className="table-header text-start"
                  valign="top"
                  width="20%">
                  {t('TIME')}
                </td> */}
                <td
                  className="table-header text-start"
                  valign="top"
                  width="25%">
                  {t('ORDER ID')}
                </td>
                <td
                  className="table-header text-start"
                  valign="top"
                  width="25%"
                  style={{ minWidth: '100px' }}>
                  {t('AMOUNT')}
                </td>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item, index) => (
                  <tr
                    key={index}
                    className={`${!item?.type && 'd-none'} table-details`}>
                    <td className="pb-0">
                      <div
                        className={`bg-rounded bg-rounded-${
                          item?.type.toLowerCase() == 'deposit'
                            ? 'deposit'
                            : item?.type.toLowerCase() == 'withdrawal'
                              ? 'withdrawal'
                              : [
                                    'energy trade',
                                    'subscription',
                                    'reconciliation',
                                  ].includes(item?.type.toLowerCase())
                                ? item?.amount > 0
                                  ? 'transaction-positive'
                                  : 'transaction-negative'
                                : ''
                        } col-2`}></div>
                    </td>
                    <td className="font-bold pb-0" valign="middle" width="20%">
                      <div className=" text-capitalize">
                        {t(`${(item?.type).toLowerCase()}`) || '-'}
                      </div>
                    </td>
                    <td className="text-start pb-0" valign="middle" width="10%">
                      {moment(new Date(item?.transactionDate)).format(
                        'DD MMM YYYY',
                      ) || '-'}
                    </td>
                    {/* <td valign="middle" className={` text-end- pb-0`}>
                      {moment(new Date(item?.transactionDate)).format(
                        'HH:mm',
                      ) || '-'}
                    </td> */}
                    <td valign="middle" className={`pb-0`}>
                      {item?.walletId || '-'}
                    </td>
                    <td
                      valign="middle"
                      className={` ${item?.amount > 0 ? 'text-deposit' : 'text-danger'} font-bold pb-0`}>
                      {formatNumber(Math.abs(item?.amount), 'currency') || '-'}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {((!loading && data.length > 9)&& !showMore && hasData) && (
            <div className="d-flex justify-content-end align-items-center cstm-button show-more">
              <button
                className={` fs-14 text-blue text-end items-center`}
                role="button"
                onClick={handleShowMore}>
                <span>Show more</span>
                <FaAngleRight color="#718ebf" size={14} />
              </button>
            </div>
          )}
        </div>
      )}
      {showMore && !loading && (
        <div className="w-100 text-center font-regular text-gray mt-2">
          Loading
          <Spinner color="secondary" size="sm" type="grow" className="ms-2">
            Loading...
          </Spinner>{' '}
          <Spinner color="secondary" size="sm" type="grow">
            Loading...
          </Spinner>
        </div>
      )}
    </CardBody>
    </Card>
  );
};
export default HistoryTransaction;
