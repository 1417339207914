import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BsCircleFill, BsSquareFill } from 'react-icons/bs';
import { Card, CardBody, CardTitle } from 'reactstrap';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import EmptyIcon from '../../assests/images/miscellaneous/search.svg';
import NoData from '../../assests/images/miscellaneous/file.svg';
import { api } from '../../utilities/api';
import { formatNumber } from '../../utilities/function';
import Loader from '../common/Loader';
import { useTranslation } from 'react-i18next';
import { endpoints } from '../../utilities/Endpoints';
import { toast } from 'react-toastify';

const HourlyForecast = ({
  forecastDate = '',
  currentDayData = '',
  setForecastData = {},
  loading = false,
  setLoading = {},
  isUpdated = false,
  setIsUpdated = {},
  setCurrentData = {},
  forecastData = '',
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [unit, setUnit] = useState('kWh');
  let firstDay = sessionStorage.getItem('currentDay');
  firstDay = moment(new Date(firstDay)).format('YYYY-MM-DD');
  const getHourlyForecast = async () => {
    setLoading(true);
    try {
      const idToken =
        sessionStorage.getItem('idToken') || localStorage.getItem('idToken');
      const companyId =
        sessionStorage.getItem('companyId') ||
        localStorage.getItem('companyId');
      if (forecastDate != '') {
        const response = await api.get(
          `${endpoints.orders}?requested_date=${forecastDate}`,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
              companyId: companyId,
            },
          },
        );

        if (response.status == 200) {
          setCurrentData(response?.data?.currentOrders);
          setData(response?.data?.hourlyForecasts);
          setForecastData(response?.data?.forecastCorrections);
          setUnit(response?.data?.hourlyForecasts[0]?.volumeUnit);
        }
      }
    } catch (error) {
      console.log('Error while getting forecast', error);
      toast.error(t('Error getting data'));
      setCurrentData([]);
      setData([]);
      setForecastData([]);
    }
    setIsUpdated(false);
    setLoading(false);
  };
  useEffect(() => {
    if (forecastDate == firstDay) {
      setData(currentDayData?.hourlyForecasts);
      setForecastData(currentDayData?.forecastCorrections);
      setUnit(currentDayData?.forecastCorrections[0]?.volumeUnit);
    } else {
      getHourlyForecast();
    }
  }, [forecastDate, currentDayData, isUpdated]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{moment.parseZone(label).format('HH:mm')}</p>
          <p>
            <BsSquareFill size={20} color={`#FBBB36`} className="me-2" />
            {formatNumber(payload[0]?.payload?.currentVolume)}&nbsp;
            {unit}
          </p>
          <p>
            <BsSquareFill size={20} color={`#001427`} className="me-2" />
            {formatNumber(payload[1]?.payload?.previousVolume)}&nbsp;
            {unit}
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <Card className="cstm-card mt-3">
        <CardBody className="pt-md-4 pt-3">
          {!data || data.length <= 0 || loading || !forecastDate ? (
            <div className="no-data-view">
              <img
                src={loading ? EmptyIcon : NoData}
                className=""
                height={100}
              />
              {!loading ? (
                <span className="font-regular text-center">
                  {t('No data yet')}
                </span>
              ) : (
                <span className="font-regular text-center">
                  {t('Forecast is being generated.')} <br />
                  {t('This usually appears the next day')}
                </span>
              )}
            </div>
          ) : (
            <>
              <CardTitle className="items-center justify-content-end mx-md-4 ">
                {forecastDate && (
                  <>
                    <BsCircleFill
                      color="#001427"
                      size={20}
                      className="me-md-2 me-1"
                    />
                    <span>
                      {moment(new Date(forecastDate))
                        .subtract('1', 'day')
                        .format('DD MMM')}
                      &nbsp;({unit})
                    </span>
                  </>
                )}
                {forecastDate && (
                  <>
                    <BsCircleFill
                      color="#FBBB36"
                      size={20}
                      className="mx-md-2 mx-1"
                    />
                    <span>
                      {moment(new Date(forecastDate)).format('DD MMM')}&nbsp;(
                      {unit})
                    </span>
                  </>
                )}
              </CardTitle>
              <div style={{ height: '400px' }}>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 10,
                      bottom: 30,
                    }}>
                    <CartesianGrid strokeDasharray="5 3" vertical={false} />
                    <XAxis
                      dataKey="fromHour"
                      tickFormatter={tick => moment.parseZone(tick).format('HH:mm')}
                      tickCount={24}
                      angle={-90}
                      dy={20}
                      axisLine={false}
                      tickLine={false}
                      padding={{ left: 10, right: 10 }}
                    />
                    <YAxis
                      tickCount={6}
                      axisLine={false}
                      tickLine={false}
                      tickSize={15}
                      tickFormatter={tick =>
                        tick != 0 ? formatNumber(tick) : '0'
                      }
                    />
                    <Tooltip content={<CustomTooltip />} cursor={false} />
                    <Line
                      type="monotone"
                      dataKey="previousVolume"
                      stroke="#001427"
                      activeDot={{ r: 8 }}
                      strokeWidth={2}
                      unit={unit}
                    />

                    <Line
                      type="monotone"
                      dataKey="currentVolume"
                      stroke="#FBBB36"
                      activeDot={{ r: 8 }}
                      strokeWidth={2}
                      unit={unit}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default HourlyForecast;
