import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import EmptyIcon from '../../assests/images/miscellaneous/search.svg';
import NoData from '../../assests/images/miscellaneous/wallet.svg';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { BsCircle, BsCircleFill } from 'react-icons/bs';
import { api } from '../../utilities/api';
import { formatNumber } from '../../utilities/function';
import { useTranslation } from 'react-i18next';
import { endpoints } from '../../utilities/Endpoints';

const Expenses = ({ month = '', year = '' }) => {
  const { t } = useTranslation();
  const [expensesData, setExpensesData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const getAllExpensesData = async () => {
    setLoading(true);
    try {
      const idToken =
        sessionStorage.getItem('idToken') || localStorage.getItem('idToken');
        const companyId =
        sessionStorage.getItem('companyId') || localStorage.getItem('companyId');
      const response = await api.get(
        `${endpoints.expensesByMonth}?month=${month}&year=${year}`,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
            companyId:companyId
          },
        },
      );
      if (response?.status == 200) {
        let resData = response?.data;
        if (
          resData?.consumptionAmount == 0 &&
          resData?.productionAmount == 0 &&
          resData?.imbalanceAmount == 0 &&
          resData?.energyTax == 0 &&
          resData?.platformFees == 0 &&
          resData?.vat == 0
        ) {
          setExpensesData([]);
          setTotal(0);
        } else {
          let data = [
            {
              name: `${t('Consumption')}`,
              value: Math.abs(Number(resData?.consumptionAmount).toFixed(2)),
            },
            {
              name: `${t('Production')}`,
              value: Math.abs(Number(resData?.productionAmount).toFixed(2)),
            },
            {
              name: `${t('imbalance-cost')}`,
              value: Math.abs(Number(resData?.imbalanceAmount).toFixed(2)),
            },
            {
              name: `${t('Energy taxes')}`,
              value: Math.abs(Number(resData?.energyTax).toFixed(2)),
            },
            {
              name: `${t('Transaction fees')}`,
              value: Math.abs(Number(resData?.platformFees).toFixed(2)),
            },
            {
              name: `${t('VAT')}`,
              value: Math.abs(Number(resData?.vat).toFixed(2)),
            },
          ];
          setExpensesData(data);
          setTotal(response?.data?.totalCost);
        }
      } else {
        setExpensesData([]);
      }
    } catch (error) {
      console.log('Error getting expenses data', error);
      setExpensesData([]);
      setTotal(0);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (month != '' && year != '') {
      getAllExpensesData();
    }
  }, [month, year]);

  const COLORS = [
    '#343C6A',
    '#FC7900',
    '#1814F3',
    '#FBBB36',
    '#D0004B',
    '#16DBCC',
  ];
  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    // Calculate the center position of the label
    const centerRadius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const centerX = cx + centerRadius * Math.cos(-midAngle * RADIAN);
    const centerY = cy + centerRadius * Math.sin(-midAngle * RADIAN);

    // Calculate the end position of the label
    const endRadius = outerRadius - 20; // Slightly outside the outer radius
    const endX = cx + endRadius * Math.cos(-midAngle * RADIAN);
    const endY = cy + endRadius * Math.sin(-midAngle * RADIAN);

    // Choose the position based on the percentage value
    const isSmallPercentage = percent * 100 <= 10;
    const x = isSmallPercentage ? endX :(percent*100 <= 10 && percent*100 >= 5)? endX-8: centerX;
    const y = isSmallPercentage ? endY :(percent*100 <= 10 && percent*100 >= 5)? endY+8: centerY;
    const rotate = isSmallPercentage || (percent*100 <= 10 && percent*100 >= 5)? `rotate(${midAngle}, ${x}, ${y})` : '';
    return (
      <text
        x={x}
        y={y}
        fill="white"
        display={isSmallPercentage ? 'none' : ''}
        textAnchor={x < cx ? 'middle' : percent * 100 <= 10 ? 'start' : 'middle'}
        dominantBaseline="central"
        transform={rotate}
        className="font-regular fs-12">
        {`${(percent * 100).toFixed(2)}%`}
      </text>
    );
    // };
  };
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label text-capitalize mb-1 fs-14">{payload[0]?.name}</p>
          <p className="mb-0 fs-14">
            <BsCircleFill
              size={20}
              color={`${payload[0]?.payload?.fill}`}
              className="me-2"
            />
            {formatNumber(payload[0]?.payload?.value, 'currency')}
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <Card className="cstm-card">
      <CardBody>
        {!expensesData || expensesData.length <= 0 || loading ? (
          <div className="no-data-view">
            <img
              src={loading ? EmptyIcon : NoData}
              className=""
              height={loading ? 80 : 50}
            />
            {loading ? (
              <span className="font-regular text-center">
                {t('Forecast is being generated.')}
                <br /> {t('This usually appears the next day')}
              </span>
            ) : (
              <span className="font-regular text-center">
                {t('No expenses yet')}
              </span>
            )}
          </div>
        ) : (
          <div className="position-relative">
            <div className="position-absolute legend-container">
              {expensesData &&
                expensesData.length > 0 &&
                expensesData.map((item, index) => (
                  <span
                    className=" text-black d-block text-capitalize fs-11"
                    key={index}>
                    <BsCircleFill
                      color={`${COLORS[index]}`}
                      className="me-1"
                      size={8}
                    />
                    {t(`${item?.name}`)}
                  </span>
                ))}
            </div>
            {total ? (
              <div className="position-absolute total-container">
                <div>Totaal</div>
                <p className="mb-0">
                  {total ? formatNumber(Math.abs(total), 'currency') : '0'}
                </p>
              </div>
            ) : (
              ''
            )}
            <div
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'flex-start',
              }}>
              <ResponsiveContainer
                width="80%"
                height="100%"
                minHeight="220px"
                className="pie-chart">
                <PieChart
                  height={'100%'}
                  width={'100%'}
                  margin={{ top: 0, right: 5, bottom: -15, left: -25 }}>
                  <Tooltip content={<CustomTooltip />} cursor={false} />
                  <Pie
                    className="pie-chart"
                    dataKey="value"
                    isAnimationActive
                    data={expensesData}
                    cx="50%"
                    cy="50%"
                    minAngle={15}
                    strokeWidth={3}
                    label={renderCustomizedLabel}
                    labelLine={false}
                    // paddingAngle={5}
                    outerRadius={100}>
                    {expensesData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default Expenses;
