import HeaderWebsite from '../components/HeaderWebsite';
import FooterWebsite from '../components/FooterWebsite';
import comingSoonImage from '../assests/images/servicesPageImages/coming.svg';
import { useTranslation } from 'react-i18next';

const ComingSoonPages = () => {
    const {t} = useTranslation();
    return (
        <div className='coming-soon-page'>
            <HeaderWebsite />
            <div className='container hero-section'>
                <img src={comingSoonImage} alt='coming-soon-img'/>
                <div className='container text-center'>
                    <h1>{t('Coming Soon')}</h1>
                    <p>{t('Get ready for innovation at your fingertips! Our cutting-edge web app is launching soon—stay tuned!')}</p>
                </div>
            </div>
            <FooterWebsite />
        </div>

    );
}

export default ComingSoonPages;