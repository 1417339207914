import { endpoints } from '../../utilities/Endpoints';
import { api } from '../../utilities/api';
export const getCompanyData = async value => {
  try {
    const response = await api.get(`${endpoints.companyDetails}?id=${value}`);
    return response;
  } catch (error) {
    console.log('Error in getting data', error);
  }
};
export const getConnectionDetails = async (postalCode, houseNumber, suffix) => {
  try {
    // let postal = postalCode ? postalCode : null;
    let houseNumberExtn = suffix ? suffix : null;
    // let houseNum = houseNumber ? houseNumber : null;
    let query = `${endpoints.fetchEan}?postalCode=${postalCode}&houseNumber=${houseNumber}`;
    if (houseNumberExtn != null) {
      query += `&houseNumberExtn=${houseNumberExtn}`;
    }
    const response = await api.get(query);
    return response;
  } catch (error) {
    console.log('Error in getting data', error);
    return error?.response;
  }
};
