// import translations from '../'
export const formatNumber = (number, type) => {
   let value;
   value = Number(number).toFixed(2);
   if (type == 'currency') {
     return new Intl.NumberFormat('nl-NL', {
       style: 'currency',
       currency: 'EUR',
     }).format(value);
   }
   else{
     return new Intl.NumberFormat('nl-NL').format(value);
   }
 };
 export const logout=() =>{
  sessionStorage.clear();
  window.location.href = '/auth/login';
}