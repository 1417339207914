import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col,
  FormGroup,
  Input,
  Row,
  Label,
} from 'reactstrap';

const CompanyDetails = ({data=''}) => {
const {t}=useTranslation()
  const {companyName,streetAddress,kvkNumber,houseNo,suffix,townCity,postalCode}=data
  return (
    <div className="py-xl-5 tab-details">
      <Row className="form-container">
        <Col md={6} className='mb-3'>
          <FormGroup floating >
            <Input
              id="companyName"
              name="companyName"
              placeholder="Company Name"
              type="text"
              readOnly
              value={companyName}
            />
            <Label for="companyName">{t('Company Name')}</Label>
          </FormGroup>
        </Col>
        <Col md={6} className='mb-3'>
          <FormGroup floating >
            <Input
              id="streetAddress"
              name="streetAddress"
              placeholder="Street Address"
              type="text"
              value={streetAddress}
              readOnly
            />
            <Label for="streetAddress">{t('Street Address')}</Label>
          </FormGroup>
        </Col>
        <Col md={6} className='mb-3' >
          <FormGroup floating >
            <Input
              id="KvkNumber"
              name="KvkNumber"
              placeholder="KvK Number"
              type="text"
              value={kvkNumber}
              readOnly
            />
            <Label for="KvkNumber ">{t('KvK Number')}</Label>
          </FormGroup>
        </Col>
        <Col lg={3} sm={6} className="mb-3" >
          <FormGroup floating >
            <Input
              id="houseNumber"
              name="houseNumber"
              placeholder="Housenumber"
              type="text"
              value={houseNo}
              readOnly
             
            />
            <Label for="houseNumber">{t('House No.')}</Label>
          </FormGroup>
        </Col>
        <Col lg={3} sm={6} className='mb-3'>
          <FormGroup floating >
            <Input
              id="suffix"
              name="suffix"
              placeholder="suffix"
              type="text"
              value={suffix}
              readOnly
            />
            <Label for="suffix">{t('Suffix')}</Label>
          </FormGroup>
        </Col>
        <Col md={6} className='mb-3'>
          <FormGroup floating >
            <Input
              id="city"
              name="city"
              placeholder="Town / City"
              type="text"
              value={townCity}
              readOnly
            />
            <Label for="city">{t('Town / City')}</Label>
          </FormGroup>
        </Col>
        <Col md={6} >
          <FormGroup floating className='mb-3'>
            <Input
              id="postalCode"
              name="postalCode"
              placeholder="ZIP Code"
              type="text"
              value={postalCode}
              readOnly
            />
            <Label for="postalCode ">{t('ZIP Code')}</Label>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

export default CompanyDetails;
