import {
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
} from 'reactstrap';
import logo from '../assests/images/AppLogo/logo-dark.svg';
import {
  IoMdArrowDropdown,
  IoMdArrowDropup,
  IoMdHelpCircle,
} from 'react-icons/io';
import './styles.scss';
import { useEffect, useState } from 'react';
import { IoMdNotifications } from 'react-icons/io';
import { IoLogOut } from 'react-icons/io5';
import { FaUser } from 'react-icons/fa';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoggedIn, setUserDetails } from '../redux/AuthReducer';
import ConfirmationPopUp from './common/ConfirmationPopup';
import { useTranslation } from 'react-i18next';
const Header = () => {
  const {t} = useTranslation()
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [logout, setLogout] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const [activeTab, setActiveTab] = useState('dashboard');
  const dispatch = useDispatch();
  const { isLoggedIn, userName } = useSelector(store => store?.userInfo);
  const name =
    sessionStorage.getItem('username') || localStorage.getItem('username');
  const navigate = useNavigate();
  const handleUserlogout = () => {
    setLogout(true);
  };
  const handleClose = () => {
    setLogout(false);
  };
  const handleSubmit = () => {
    sessionStorage.clear();
    localStorage.clear();
    dispatch(setIsLoggedIn(false));
    dispatch(setUserDetails({}));
    setLogout(false);
    navigate('/auth/login');
  };
  const active = sessionStorage.getItem('active-tab');
  useEffect(() => {
    setActiveTab(active);
  }, [active]);
  const handleActiveTab = tab => {
    sessionStorage.setItem('active-tab', tab);
    sessionStorage.removeItem('next-tab');
    setActiveTab(tab);
  };
  return (
    <>
      <Navbar className={`bg-header`}>
        {!isLoggedIn ? (
          <Container>
            <NavbarBrand href={'/'}>
              <img src={logo} width={200} />
            </NavbarBrand>
          </Container>
        ) : (
          <Nav className="user-nav">
            <NavbarBrand href="/user/app/dashboard" className="col-lg-2">
              <img src={logo} width={200} />
            </NavbarBrand>
            <NavItem className="items-center flex-grow-1 mx-md-2- px-2 ">
              <h5 className="mb-0 text-capitalize title pt-1">{t(`${activeTab}`)}</h5>
            </NavItem>
            <NavItem className="items-center px-3">
              {/* <div className="avatar mx-3">
                <IoMdNotifications color="#001427" size={20} />
              </div> */}
              {/* <div className="avatar mx-3"> */}
              <div
                className={`${window.location.pathname == '/user/app/account' ? 'user-active' : ''} avatar items-center justify-content-center`}>
                <p className="text-uppercase">
                  {userName?.charAt(0) || name?.charAt(0)}
                </p>
              </div>
              <Dropdown
                inNavbar
                isOpen={dropdownOpen}
                toggle={toggle}
                direction="start"
                className="mx-1 user-dropdown">
                {' '}
                <DropdownToggle tag="span" className="cursor-pointer">
                  {dropdownOpen ? (
                    <IoMdArrowDropup color="#fff" size={25} />
                  ) : (
                    <IoMdArrowDropdown color="#fff" size={25} />
                  )}
                </DropdownToggle>
                <DropdownMenu>
                  {/* <Link to="/user/app/account" className="items-center text-decoration-none"> */}
                  <DropdownItem
                    className="user-items items-center"
                    onClick={() => {
                      navigate('/user/app/account');
                      handleActiveTab('my account');
                    }}>
                    <FaUser color="#757575" size={20} className="me-1" />
                    <span className="mt-1">{t('My Account')}</span>
                  </DropdownItem>
                  {/* </Link> */}
                  {/* <Link to="/user/app/help" className="items-center text-decoration-none" > */}
                  <DropdownItem
                    className="user-items items-center"
                    onClick={() => {
                      navigate('/user/app/help');
                      handleActiveTab('help');
                    }}>
                    <IoMdHelpCircle
                      color="#757575"
                      size={20}
                      className="me-1"
                    />
                    <span className="mt-1">{t('Help')}</span>
                  </DropdownItem>
                  {/* </Link> */}
                  <DropdownItem
                    className="user-items items-center"
                    role="button"
                    onClick={() => handleUserlogout()}>
                    <IoLogOut color="#757575" size={20} className="me-1" />
                    <span className="mt-1">{t('Logout')}</span>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </NavItem>
          </Nav>
        )}
      </Navbar>
      {logout && (
        <ConfirmationPopUp
          isOpen={logout}
          icon={<IoLogOut color="#212121" size={25} className="me-1" />}
          content={t('You are attempting to logout of PowerHive, Are you sure want to logout?')}
          title="Logout"
          confirmationText1="Cancel"
          confirmationText2="Logout"
          handleSave={handleSubmit}
          handleClose={handleClose}
        />
      )}
    </>
  );
};
export default Header;
