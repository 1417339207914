import React, { useState } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from 'reactstrap';
import closeIcon from '../../assests/images/icons/close.svg';
import { getConnectionDetails } from '../../pages/OnBoarding/function';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const AddConnectionPopup = ({
  formik = '',
  isOpen = {},
  handleClose = {},
  eanNumbers = '',
  setEanNumbers = {},
}) => {
  const { t } = useTranslation();
  const [connectioninfo, setConnectionInfo] = useState({
    zipCode: '',
    houseNumber: '',
    suffix: '',
  });
  const [isLoading, setLoading] = useState(false);

  const handleInputChange = (name, value) => {
    setConnectionInfo(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
  const formatNumberWithCommas = number => {
    let value;
    if (typeof number != 'number') {
      value = number.replace(/[^\d.]/g, '');
    } else {
      value = number;
    }
    return Number(value).toLocaleString('nl-NL');
  };
  const getConnections = async () => {
    setLoading(true);
    try {
      let { zipCode, houseNumber, suffix } = connectioninfo;
      let response = await getConnectionDetails(zipCode, houseNumber, suffix);
      if (response?.status === 200) {
        const fetchedConnections = response.data || [];
        const updatedConnections = fetchedConnections
          .filter(connection => !eanNumbers.includes(connection.EAN))
          .map(connection => ({
            streetAddress: connection?.streetAddress || '',
            EAN: connection?.EAN || '', // Set EAN from fetched data
            cHouseNumber: connectioninfo?.houseNumber || '',
            cSuffix: connectioninfo?.suffix || '',
            code:connection?.code,
            consumption:
              connection.consumptionVolume != 0 &&
              connection?.consumptionVolume != undefined
                ? connection.consumptionVolume
                : connection.consumptionVolume == 0
                  ? 0
                  : '',
            production:
              connection.productionVolume != 0 &&
              connection?.productionVolume != undefined
                ? connection.productionVolume
                : connection.productionVolume == 0
                  ? 0
                  : '',
            cZipCode: connectioninfo?.zipCode || '',
            cCity: connection?.city || '',
            capacity:
              connection.capacity != 0 && connection?.capacity != undefined
                ? connection.capacity
                : connection.capacity == 0
                  ? 0
                  : '',
            date: '',
          }));
          console.log('fetchedConnections',updatedConnections)

        // Update eanNumbers only with the EAN values of new connections
        const newEANs = updatedConnections.map(connection => connection.EAN);
        setEanNumbers([...eanNumbers, ...newEANs]);
        formik.setFieldValue('connections', [
          ...formik.values.connections,
          ...updatedConnections,
        ]);
        handleClose();
        if (fetchedConnections.length == 0) {
          toast.info(`${t('No records found')}`);
        }
      } else {
        toast.error(t(`${response?.data?.message}`) ||'Something went wrong! Please check your details again.');
      }
    } catch (error) {
      console.log('Error fetching connections', error);
      toast.error(error?.response?.data?.message||'Something went wrong');
    }
    setLoading(false);
  };

  return (
    <>
      <Modal isOpen={isOpen} centered backdrop size="sm">
        <ModalBody>
          <span role="button" className="close-button">
            <img src={closeIcon} height={25} onClick={handleClose} />
          </span>
          <h5 className="mb-4">{t('Add Connection')}</h5>
          <Row className="m-0 p-0">
            <Col md={6} className="px-md-0">
              <FormGroup floating>
                <Input
                  id="zipCode"
                  name="zipCode"
                  placeholder="Zip Code"
                  type="text"
                  onChange={e => handleInputChange('zipCode', e.target.value)}
                />
                <Label for="zipCode">{t('ZIP Code')}</Label>
              </FormGroup>
            </Col>
            <Col md={6} className="pe-md-0">
              <FormGroup floating className="">
                <Input
                  id="houseNumber"
                  name="houseNumber"
                  placeholder="Housenumber"
                  type="text"
                  onChange={e =>
                    handleInputChange('houseNumber', e.target.value)
                  }
                />
                <Label for="houseNumber">{t('Housenumber')}</Label>
              </FormGroup>
            </Col>
            <Col md={6} className="px-md-0">
              <FormGroup floating className="">
                <Input
                  id="suffix"
                  name="suffix"
                  placeholder="Suffix"
                  type="text"
                  onChange={e => handleInputChange('suffix', e.target.value)}
                />
                <Label for="suffix">{t('Suffix')}</Label>
              </FormGroup>
            </Col>
            {isLoading && (
              <Col xs={12}>
                <p className="font-bold text-center">
                  {t('Fetching details')}...
                </p>
              </Col>
            )}
            <Col xs={12} className="p-md-0 text-end">
              <Button
                className="btn-submit w-50"
                type="button"
                disabled={isLoading}
                onClick={getConnections}>
                {t('Next')}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AddConnectionPopup;
