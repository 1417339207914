/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './app.scss';
import { Provider } from 'react-redux';
import store from './store';
import i18n from './services/i18n';
import { I18nextProvider } from 'react-i18next';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </Provider>
  </React.StrictMode>,
);
