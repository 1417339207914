import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import { formatNumber } from '../../utilities/function';
import NoData from '../../assests/images/miscellaneous/file.svg';
import SearchIcon from '../../assests/images/miscellaneous/search.svg';
import { useTranslation } from 'react-i18next';

const Summary = ({ data = '', loading = '' }) => {
  const { t } = useTranslation();
  let type = [
    'Consumption',
    'Production',
    'Imbalance-summary',
    'Energy taxes',
    'Transaction fees',
    'VAT',
  ];
  return (
    <div>
      <Card className="cstm-card mt-3">
        {loading || !data || data.length < 0 ? (
          <CardBody>
            <div className="no-data-view fs-16">
              <img
                src={!loading ? NoData : SearchIcon}
                alt="No Data"
                height={100}
              />
              {loading ? (
                <span className="font-regular text-center">
                  {t('Forecast is being generated.')}
                  <br /> {t('This usually appears the next day')}
                </span>
              ) : (
                <span className="font-regular text-center">
                  {t('No data yet')}
                </span>
              )}
            </div>
          </CardBody>
        ) : (
          <CardBody className="p-0">
            <div className="mx-3 mt-3">
              <Table className="summary-box mb-0">
                <thead>
                  <tr>
                    <td className="table-header ">{t('TYPE')}</td>
                    <td className="table-header text-end text-transform-none">
                      {t('VOLUME')} (kWh)
                    </td>
                    <td className="table-header text-end">
                      {t('AMOUNT')} (€ )
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td width="33%">{t(`${type[0]}`)}</td>
                    <td width="33%" className={`text-end`}>
                      {formatNumber(data?.consumptionInKwh || 0)}
                    </td>
                    <td width="33%" className={`text-end `}>
                      {formatNumber(data?.consumptionAmount || 0)}
                    </td>
                  </tr>
                  <tr>
                    <td>{t(`${type[1]}`)}</td>
                    <td width="33%" className={`text-end `}>
                      {formatNumber(data?.productionInKwh || 0)}
                    </td>
                    <td width="33%" className={`text-end `}>
                      {formatNumber(data?.productionAmount || 0)}
                    </td>
                  </tr>
                  <tr>
                    <td width="33%">{t(`${type[2]}`)}</td>
                    <td width="33%" className={`text-end`}>
                      {formatNumber(data?.imbalanceInKwh || 0)}
                    </td>
                    <td width="33%" className={`text-end `}>
                      {formatNumber(data?.imbalanceAmount || 0)}
                    </td>
                  </tr>
                  <tr>
                    <td width="33%">{t(`${type[3]}`)}</td>
                    <td width="33%" className={`text-end `}>
                      -
                    </td>
                    <td width="33%" className={`text-end `}>
                      {formatNumber(data?.energyTaxAmount || 0)}
                    </td>
                  </tr>
                  <tr>
                    <td width="33%">{t(`${type[4]}`)}</td>
                    <td width="33%" className={`text-end`}>
                      -
                    </td>
                    <td width="33%" className={`text-end `}>
                      {formatNumber(data?.platformFees || 0)}
                    </td>
                  </tr>
                  <tr>
                    <td width="33%">{t(`${type[5]}`)}</td>
                    <td width="33%" className={`text-end`}>
                      -
                    </td>
                    <td width="33%" className={`text-end`}>
                      {formatNumber(data?.vatAmount || 0)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <Table bordered className="total mb-0 ">
              <thead className="px-0">
                <tr>
                  <td width="33%">{t('Total')}</td>
                  <td width="33%" className={`text-end `}>
                    {formatNumber(data?.totalVolume || 0)}
                  </td>
                  <td width="33%" className={`text-end `}>
                    {formatNumber(data?.totalCost || 0)}
                  </td>
                </tr>
              </thead>
            </Table>
          </CardBody>
        )}
      </Card>
    </div>
  );
};

export default Summary;
