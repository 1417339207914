import React from 'react';
import { Card, CardBody } from 'reactstrap';
import EmptyIcon from '../../assests/images/miscellaneous/search.svg';

import { useSelector } from 'react-redux';
import { IoMdArrowRoundBack } from 'react-icons/io';
import './styles.scss';

const NotFound = () => {
  const { isLoggedIn } = useSelector(store => store?.userInfo);
  return (
    <div className="items-center justify-content-center not-found-page">
      <div className="no-data-view">
        <img src={EmptyIcon} />
        <h1 className="font-regular text-center">404! Page not found</h1>
          <a
            href={`${!isLoggedIn ? '/auth/login' : '/user/app/dashboard'}`}
            className="btn-link py-3">
            <IoMdArrowRoundBack color="#212121" size={20} className="me-2 mb-1" />
            <h5 className='d-inline mb-0'>{!isLoggedIn ? 'Go to Login' : 'Go to dashboard'}</h5>
          </a>
      </div>
    </div>
  );
};

export default NotFound;
