import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import closeIcon from '../../assests/images/icons/close.svg';
import { MdAdd } from 'react-icons/md';
import { HiMinusSm } from 'react-icons/hi';
import { formatNumber } from '../../utilities/function';
import logo from '../../assests/images/AppLogo/logo_Icon.png';
import { PiCopySimpleFill } from 'react-icons/pi';
import { useTranslation } from 'react-i18next';

const PaymentPopup = ({ isOpen = {}, handleClose = {}, handleSave = {} }) => {
  const {t} = useTranslation()
  const [amount, setAmount] = useState(1000);
  const [copied, setCopied] = useState(false);
  const [isDisabled,setDisabled] = useState(false)

  const handleCopy = value => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        setCopied(true);
        const intervalId = setTimeout(() => {
          setCopied(false);
        }, 3000);
        return () => clearTimeout(intervalId);
      })
      .catch(() => {
        toast.error('Error copying code.Please try again later.');
      });
  };
  useEffect(() => {
    if (amount == 1000) {
      setDisabled(true);
    }
    else{
      setDisabled(false)
    }
  }, [amount]);
  return (
    <Modal isOpen={isOpen} centered backdrop className="mw-350">
      <ModalBody className="p-lg-4 p-3">
        <span role="button" className="close-button">
          <img src={closeIcon} height={25} onClick={handleClose} />
        </span>
        <div className="d-flex align-items-end justify-content-start">
          <h5 className={`mb-0 font-bold`}>{t('Deposit Funds')}</h5>
        </div>

        <p className=" font-regular">
          {t('Select amount to transfer into your wallet')}
        </p>
        <div className="amount-counter cstm-button">
          <button className="btn-counter"  onClick={() => setAmount(amount - 5000)} disabled={isDisabled}>
            <HiMinusSm size={25} color={`${isDisabled ? '#CECECE' : '#FF9900'} `} />
          </button>
          <h3 className="font-bold mb-0">{formatNumber(amount, 'currency')}</h3>
          <button className="btn" onClick={() => setAmount(amount + 5000)}>
            <MdAdd size={25} color="#FF9900" />{' '}
          </button>
        </div>
        {/* <div className="items-center justify-content-center font-regular py-2">
          <img src={logo} height={22} className="mb-1" />{' '}
          <p className="mb-0">
            Standard estimated <span className="btn-link px-1">10</span> days
          </p>
        </div> */}
        <div className="payment-details d-grid mt-4">
          {/* <div>
            <p className="text-gray mb-0">{('Unique Code')}:</p>
            <p className="text-black cstm-button">
              829292911
              <button onClick={() => handleCopy('829292911')}>
                <PiCopySimpleFill size={16} color="#FBBB36" className="mb-1" />
              </button>
              <span className="text-success fs-12 font-regular mb-1">
                {copied ? 'Copied!' : ''}
              </span>
            </p>
          </div> */}
          <div>
            <p className="text-gray mb-0">{t('Name')}:</p>
            <p className="text-black">PowerHive B.V.</p>
          </div>
          <div>
            <p className="text-gray mb-0">IBAN:</p>
            <p className="text-black mb-0">NL82 ABNA 0892 6395 47</p>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <Button
            className="btn-submit px-4"
            type="submit"
            onClick={handleClose}
            disabled={amount == 0 ? true:false}>
            {t('OK')}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PaymentPopup;
