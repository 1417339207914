import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../assests/scss/header.scss';
import logo from '../assests/images/homePageImages/logo.svg';
import { useLocation } from 'react-router-dom';
import { WEB_APP_LOGIN_URL, WEB_APP_SIGNUP_URL} from '../config/constants';

const HeaderWebsite = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const location = useLocation();

    const toggleMenu = () => {
        setMenuOpen(prevMenuOpen => {
            const newMenuOpen = !prevMenuOpen;
            document.body.style.overflowY = newMenuOpen ? 'hidden' : 'auto';
            return newMenuOpen;
        });
    };

    const redirectLogo = () => {
        document.body.style.overflowY = 'auto';
    };

    const handleMenuClose = () => {
        setMenuOpen(false);
        document.body.style.overflowY = 'auto';
    };
    const getActiveClass = (path) => {
        return location.pathname === path ? 'active' : '';
    };
    return (
        <header>
            <div className="container">
                <Link to="/" onClick={redirectLogo}><img src={logo} className="logo" alt="logo img" /></Link>
                <div className="toggle-button" onClick={toggleMenu}>
                    {menuOpen ? (
                        <>
                            <div className="hamburger-line close"></div>
                            <div className="hamburger-line close"></div>
                        </>
                    ) : (
                        <>
                            <div className="hamburger-line"></div>
                            <div className="hamburger-line"></div>
                            <div className="hamburger-line"></div>
                        </>
                    )}
                </div>

                <nav className={`navigation ${menuOpen ? 'open' : 'close'}`} id="navigation">
                    <ul>
                        <li className="nav-item" id='link1'><Link to="/services" className={getActiveClass('/services')} onClick={handleMenuClose}>Services</Link></li>
                        <li className="nav-item" id='link2'><Link to="/about-us" className={getActiveClass('/about-us')} onClick={handleMenuClose}>Over ons</Link></li>
                        <li className="nav-item" id='link3'><Link to="/contact-us" className={getActiveClass('/contact-us')} onClick={handleMenuClose}>Contact</Link></li>
                    </ul>
                </nav>
                <nav className="navigation" id="redirection">
                    <ul>
                        <li className="nav-item"><Link to={`${WEB_APP_LOGIN_URL}`} className="login">Inloggen</Link></li>
                        <li className="nav-item"><Link to={`${WEB_APP_SIGNUP_URL}`} style={{ color: 'black' }} className="signup-button">Registreer nu</Link></li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default HeaderWebsite;
