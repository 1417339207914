import React from 'react';
import { Button, Modal, ModalBody, Spinner } from 'reactstrap';
import closeIcon from '../../assests/images/icons/close.svg';
import { useTranslation } from 'react-i18next';

const ConfirmationPopup = ({
  type = '',
  downloading = '',
  icon = '',
  isOpen = {},
}) => {
  const {t} = useTranslation()
  return (
    <Modal
      isOpen={isOpen}
      centered
      backdrop
      size="sm"
      className={`${type == 'fetch' ? 'load-data-modal':''}`}>
      <ModalBody className={`${type == 'fetch' ? '':'loader-modal'}`}>
        {type == 'fetch' ? (
          <div className="file-download-status d-flex justify-content-center align-items-center">
            <span className="fs-14 text-dark font-bold me-2">{('Fetching data')}...</span>
            <Spinner color="warning" />
          </div>
        ) : (
          <div className="file-download-status d-flex justify-content-between align-items-center">
            <span className="fs-14 text-white font-bold">
              <img src={icon} alt="file" className="me-2" />
              {downloading ? `${t('Downloading')}...` : `${t('Downloaded')}`}
            </span>
            {downloading && <Spinner color="warning" />}
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ConfirmationPopup;
