import React, { useEffect, useState } from 'react';
import './style.scss';

import Fade1 from '../../assests/images/homePageImages/fade-1.svg';
import Fade2 from '../../assests/images/homePageImages/fade-2.svg';
import Fade3 from '../../assests/images/homePageImages/fade-3.svg';
import Fade4 from '../../assests/images/homePageImages/fade-4.svg';
import Fade1Mobile from '../../assests/images/homePageImages/fade-1-mobile.svg';
import Fade2Mobile from '../../assests/images/homePageImages/fade-2-mobile.svg';
import Fade3Mobile from '../../assests/images/homePageImages/fade-3-mobile.svg';
import Fade4Mobile from '../../assests/images/homePageImages/fade-4-mobile.svg';
import { max } from 'moment/moment';

const FoldThree = () => {
  const [animationTriggered, setAnimationTriggered] = useState(false);
  // useEffect(()=>{
  //     setAnimationTriggered(true)
  // },[])

  useEffect(() => {
    const handleScroll = () => {
      if (animationTriggered) {
        return;
      }
      const fold3 = document.getElementById('fold3');
      if (fold3) {
        const fold3Rect = fold3.getBoundingClientRect();
        const fold3Height = fold3.clientHeight;
        const fold3Top = fold3Rect.top - 200;
        const windowHeight = window.innerHeight;
        const maxScroll = windowHeight + fold3Top - 200;
        const checkAnimationScroll = maxScroll <= windowHeight;

        if (checkAnimationScroll) {
          const isMobile = window.innerWidth <= 1024;
          if (isMobile) {
            animateElementsMobile();
          } else {
            animateElementsDesktop();
          }
          setAnimationTriggered(true);
          if (fold3Top) {
            const isMobile = window.innerWidth <= 1024;
            if (isMobile) {
              animateElementsMobile();
            } else {
              animateElementsDesktop();
            }
            setAnimationTriggered(true);
          }
        }
      }
    };

    const animateImages = () => {
      const images = document.querySelectorAll('#imgfold img');
      let timeouts = [];

      // Clear existing timeouts if any
      timeouts.forEach(timeout => clearTimeout(timeout));
      timeouts = [];

      images.forEach((img, index) => {
        const timeout = setTimeout(() => {
          if (img) {
            img.classList.add('fade-in');
            if (index > 0) {
              images[index].style.display = 'block';
              images[index - 1].style.display = 'none';
            } else {
              images[index].style.display = 'block';
            }
          }
        }, index * 1500);

        timeouts.push(timeout);
      });
    };

    const animateMobileImages = () => {
      const images = document.querySelectorAll('#imgfold-mobile img');
      let timeouts = [];

      // Clear existing timeouts if any
      timeouts.forEach(timeout => clearTimeout(timeout));
      timeouts = [];

      images.forEach((img, index) => {
        const timeout = setTimeout(() => {
          if (img) {
            img.classList.add('fade-in');
            if (index > 0) {
              images[index].style.display = 'block';
              images[index - 1].style.display = 'none';
            } else {
              images[index].style.display = 'block';
            }
          }
        }, index * 1500);

        timeouts.push(timeout);
      });
    };

    const animateSteps = () => {
      setTimeout(() => {
        const line2 = document.getElementById('line2');
        const step2circle = document.getElementById('step2circle');
        const step3circle = document.getElementById('step3circle');
        const step3content = document.getElementById('step3content');
        if (line2) line2.style.opacity = '1';
        if (line2) line2.style.borderColor = 'rgba(251, 187, 54, 1)';
        if (step2circle)
          step2circle.style.backgroundColor = 'rgba(251, 187, 54, 1)';
        if (step3content) step3content.style.opacity = '1';
        if (step3circle)
          step3circle.style.backgroundColor = 'rgba(0, 20, 39, 1)';
      }, 1500);
      setTimeout(() => {
        const line3 = document.getElementById('line3');
        const step3circle = document.getElementById('step3circle');
        const step4circle = document.getElementById('step4circle');
        const step4content = document.getElementById('step4content');
        if (line3) line3.style.opacity = '1';
        if (line3) line3.style.borderColor = 'rgba(251, 187, 54, 1)';
        if (step3circle)
          step3circle.style.backgroundColor = 'rgba(251, 187, 54, 1)';
        if (step4content) step4content.style.opacity = '1';
        if (step4circle)
          step4circle.style.backgroundColor = 'rgba(0, 20, 39, 1)';
      }, 3000);
      setTimeout(() => {
        const step4circle = document.getElementById('step4circle');
        if (step4circle)
          step4circle.style.backgroundColor = 'rgba(251, 187, 54, 1)';
      }, 4500);
    };

    const animateElementsDesktop = () => {
      animateImages();
      animateSteps();
    };

    const animateElementsMobile = () => {
      animateMobileImages();
      animateSteps();
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [animationTriggered]);

  return (
    <div className="container" id="fold3">
      <div className="heading-container">
        <h1 id="header-3">Hoe het werkt</h1>
      </div>
      <div className="container" id="subfold3">
        <div className="container" id="imgfold">
          <img src={Fade1} alt="fade1" id="fade-1" />
          <img src={Fade2} alt="fade2" id="fade-2" />
          <img src={Fade3} alt="fade3" id="fade-3" />
          <img src={Fade4} alt="fade4" id="fade-4" />
        </div>
        <div className="container" id="imgfold-mobile">
          <img src={Fade1Mobile} alt="fade1" id="fade-1" />
          <img src={Fade2Mobile} alt="fade2" id="fade-2" />
          <img src={Fade3Mobile} alt="fade3" id="fade-3" />
          <img src={Fade4Mobile} alt="fade4" id="fade-4" />
        </div>
        <div className="container" id="stepfold">
          <div className="container step" id="step1">
            <div className="dashed-line" id="line1"></div>
            <div className="parallax-circle" id="step1circle">
              <h4>01</h4>
            </div>
            <div className="step-content" id="step1content">
              <h1>Maak een account aan</h1>
              <p>
                Stap over naar PowerHive en meld je aansluitingen zelf aan. Wij
                verzorgen de rest.
              </p>
            </div>
          </div>
          <div className="container step" id="step2">
            <div className="dashed-line" id="line2"></div>
            <div className="parallax-circle" id="step2circle">
              <h4>02</h4>
            </div>
            <div className="step-content" id="step2content">
              <h1>Wallet aanmaken</h1>
              <p>
                Elke dag koop of verkoop je energie op de markt hiervoor word je
                elke dag afgerekend op je Wallet.
              </p>
            </div>
          </div>
          <div className="container step" id="step3">
            <div className="dashed-line" id="line3"></div>
            <div className="parallax-circle" id="step3circle">
              <h4>03</h4>
            </div>
            <div className="step-content" id="step3content">
              <h1>Voorspellen en inkopen</h1>
              <p>
                Automatisch zal PowerHive voor alle aansluitingen je verbruik
                voorspellen en inkopen op de markt.
              </p>
            </div>
          </div>
          <div className="container step" id="step4">
            <div className="parallax-circle" id="step4circle">
              <h4>04</h4>
            </div>
            <div className="step-content" id="step4content">
              <h1>Inzicht in je verbruik</h1>
              <p>
                Elke dag inzicht in wat er gisteren is gebeurd. PowerHive biedt
                een duidelijk overzicht van je verbruik en de kosten en/of
                opbrengsten.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FoldThree;
