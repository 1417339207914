import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { api, paymentAPI } from '../../utilities/api';
import { formatNumber } from '../../utilities/function';
import EmptyIcon from '../../assests/images/miscellaneous/search.svg';
import NoData from '../../assests/images/miscellaneous/transaction.svg';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { endpoints } from '../../utilities/Endpoints';

const HistoryTransaction = () => {
  const { t } = useTranslation();
  const [transactionData, setTransactionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const getTransactionHistory = async () => {
    setLoading(true);
    try {
      const idToken =
        sessionStorage.getItem('idToken') || localStorage.getItem('idToken');
      const companyId =
        sessionStorage.getItem('companyId') ||
        localStorage.getItem('companyId');
      const accessToken =
        sessionStorage.getItem('accessToken') ||
        localStorage.getItem('accessToken');
      const response = await paymentAPI.get(
        `${endpoints.historyWalletPaymentAPI}`,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
            accessToken: accessToken,
            CompanyId: companyId,
            pageNumber: 1,
            pageSize: 3,
          },
        },
      );
      if (response?.status == 200) {
        if (response?.data == null) {
          setTransactionData([]);
        } else {
          setTransactionData(response?.data?.items);
        }
      }
    } catch (error) {
      console.log('Error fetching transaction history', error);
      setTransactionData([]);
    }
    setLoading(false);
  };
  useEffect(() => {
    getTransactionHistory();
  }, []);
  return (
    <Card className="cstm-card">
      <CardBody
        className={`${transactionData?.length < 3 ? 'justify-content-start p-3' : 'justify-content-evenly p-2'} `}>
        {loading || transactionData.length <= 0 ? (
          <div className="no-data-view">
            <img src={loading ? EmptyIcon : NoData} className="" height={80} />
            {loading ? (
              <span className="font-regular text-center">
                {t('Forecast is being generated.')}
                <br /> {t('This usually appears the next day')}
              </span>
            ) : (
              <span className="font-regular text-center">
                {t('No transactions yet')}
              </span>
            )}
          </div>
        ) : (
          transactionData.map((item, index) => (
            <div
              className="row align-items-center m-0 p-0 flex-wrap history-tab"
              key={index}>
              <div
                className={`bg-rounded bg-rounded-${
                  item?.transactionType
                    ? item?.transactionType.toLowerCase() == 'deposit'
                      ? 'deposit'
                      : item?.transactionType.toLowerCase() == 'withdrawal'
                        ? 'withdrawal'
                        : [
                              'energytrade',
                              'subscription',
                              'reconciliation',
                              'deal',
                              'transactioncosts',
                              'imbalance',
                              'banktransfercost',
                              'certificates',
                              'portfolioprofit',
                            ].includes(item?.transactionType.toLowerCase())
                          ? item?.amountInclVAT >= 0
                            ? 'transaction-positive'
                            : 'transaction-negative'
                          : ''
                    : ''
                } col-2`}></div>
              <div className="text-start col-6">
                <p className="text-dark mb-0 text-capitalize">
                  {t(`${(item?.transactionType).toLowerCase()}`)}
                </p>
                <span className="text-gray fs-14">
                  {moment(new Date(item?.transactionDate)).format(
                    'DD MMM YYYY',
                  )}
                </span>
              </div>
              <div
                className={`${item?.transactionType.toLowerCase() == 'withdrawal' ? 'text-danger' : item?.amountInclVAT >= 0 ? 'text-deposit' : 'text-danger'} font-bold  col-md-4  px-0 text-end transaction-type`}>
                <p className="text-wrap mb-0 fs-16">
                  {formatNumber(Math.abs(item?.amountInclVAT), 'currency')}
                </p>
              </div>
            </div>
          ))
        )}
      </CardBody>
    </Card>
  );
};

export default HistoryTransaction;
