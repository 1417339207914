import React, { useEffect, useState, useMemo } from 'react';
import { Card, CardBody, Col, Nav, NavItem, Row } from 'reactstrap';
import PersonalDetails from './PersonalDetails';
import CompanyDetails from './CompanyDetails';
import MyPlans from './MyPlans';
import BankDetails from './BankDetails';
import MyConnections from './MyConnections';
import SetPassword from './SetPassword';
import { api } from '../../utilities/api';
import Loader from '../../components/common/Loader';
import './styles.scss';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { endpoints } from '../../utilities/Endpoints';

const UserAccount = () => {
  const {t} = useTranslation();
  const [active, setActive] = useState('personal-details');
  const [activeStep, setActiveStep] = useState(1);
  const [changePassword, setChangePassword] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [navItem, setNavItems] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const idToken =
        sessionStorage.getItem('idToken') || localStorage.getItem('idToken');
        const companyId =
        sessionStorage.getItem('companyId') || localStorage.getItem('companyId');
        const userId =
        sessionStorage.getItem('userId') || localStorage.getItem('userId');
        let payload= {companyId,userId}
      const response = await api.post(endpoints.accountDetails,payload, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      if (response.status === 200 && response.data) {
        setData(response.data);
      }
    } catch (error) {
      console.error('Error getting data', error, error.status);
      toast.error(t(error?.response?.data?.message) || 'Something went wrong');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);
  const navItems = [
    {
      step: 1,
      id: 'personal-details',
      text: 'Personal Details',
      component: (
        <PersonalDetails
          data={data?.personalDetails}
          fetchData={fetchData}
          setChangePassword={setChangePassword}
        />
      ),
    },
    {
      step: 2,
      id: 'company-details',
      text: 'Company Details',
      component: <CompanyDetails data={data?.companyDetails} />,
    },
    {
      step: 3,
      id: 'user-plan',
      text: 'My Plan',
      component: <MyPlans data={data?.planDetails} />,
    },
    {
      step: 4,
      id: 'bank-details',
      text: 'Bank Details',
      component: <BankDetails data={data?.bankDetails} />,
    },
    {
      step: 5,
      id: 'connections',
      text: 'My Connections',
      component: <MyConnections data={data?.connectionDetails} fetchData={fetchData}/>,
    },
  ];

  const passwordItems = [
    {
      step: 6,
      id: 'change-password',
      text: 'Set New Password',
      component: <SetPassword setChangePassword={setChangePassword} />,
    },
  ];

  useEffect(() => {
    if (changePassword) {
      // setNavItems(passwordItems)
      setActive('change-password');
      setActiveStep(6);
    } else {
      // setNavItems(navItems)
      setActive('personal-details');
      setActiveStep(1);
    }
  }, [changePassword]);

  const handleNavItem = (tab, step) => {
    sessionStorage.setItem('active-tab', 'My Account');
    setActive(tab);
    setActiveStep(step);
  };

  return (
    <div className="user-account">
      <Card className="cstm-card my-3 user-account-container mx-3">
        <CardBody className="p-md-4  p-3">
          {!loading ? (
            <Row className="m-0 p-0">
              <Nav >
                {(changePassword ? passwordItems : navItems).map(item => (
                  <NavItem
                    key={item?.id}
                    className={`${active === item.id ? 'active-item-css' : ''} account-nav-item position-relative`}
                    onClick={() => handleNavItem(item.id, item?.step)}
                    role="button">
                    <div className="items-center justify-content-center">
                      <h6
                        className={`${active === item.id ? 'text-theme' : 'text-gray'} mb-1`}>
                        {t(`${item.text}`)}
                      </h6>
                    </div>
                  </NavItem>
                ))}
              </Nav>
              {changePassword ? (
                <Col md={12}><SetPassword setChangePassword={setChangePassword} /></Col>
              ) : (
                <Col md={12} className='account-children'>{navItems[activeStep - 1]?.component}</Col>
              )}
            </Row>
          ) : (
            <Loader isOpen={loading} />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default UserAccount;
