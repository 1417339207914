import './styles.scss';
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import { toast } from 'react-toastify';
import Search from '../../assests/images/icons/Search.png';
import CompanyList from '../../components/OnBoarding/CompanyList';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../../utilities/api';
import { useTranslation } from 'react-i18next';
import { endpoints } from '../../utilities/Endpoints';

const CompanySearch = ({ formik = {}, setActiveStep = {} }) => {
  const listRef = useRef();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [showResult, setShowResult] = useState(false);
  const [query, setQuery] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [companyList, setCompanyList] = useState({});
  const [companyData, setCompanyData] = useState({});
  const [isDisabled, setDisabled] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  const handleShowResults = async value => {
    if (value.length >= 3 && value != '') {
      const encodedValue = encodeURIComponent(value);
      try {
        setLoading(true);
        const response = await api.get(
          `${endpoints.companyDetails}?name=${encodedValue}`,
        );
        const results = [];
        if (response) {
          for (const element of response?.data) {
            if (element && element?.names.length > 0 && element?.id != null) {
              results.push(element);
            }
          }
          localStorage.setItem('company-details', true);
          setCompanyList(results);
          const timeoutId = setTimeout(() => {
            setLoading(false);
            setShowResult(true);
          }, 2000);
          return () => clearTimeout(timeoutId);
        }
        for (const element of response?.data) {
          if (element && element?.names.length > 0 && element?.id != null) {
            results.push(element);
          }
        }
        setCompanyList(results);
        const timeoutId = setTimeout(() => {
          setLoading(false);
          setShowResult(true);
        }, 3000);
        return () => clearTimeout(timeoutId);
      } catch (error) {
        console.log('Error fetching data', error);
        toast.error('Something went wrong');
        setLoading(false);
      }
    }
  };

  const handleChange = e => {
    setCompanyList({});
    if (e.target.value != '') {
      const sanitizedValue = e.target.value.replace(/\s+/g, ' ');
      setQuery(sanitizedValue);
    } else {
      setQuery('');
      setDisabled(true);
    }
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (query.length >= 3 && isEditing) {
        handleShowResults(query);
      }
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [query]);
  useEffect(() => {
    if (companyData !== undefined && companyData !== null) {
      formik.setFieldValue('companyName', companyData?.naam || '');
      formik.setFieldValue('activityDescription',companyData?.activiteiten ? companyData?.activiteiten[0]?.omschrijving:'')
      formik.setFieldValue('KvkNumber', companyData?.kvknummer || '');
      formik.setFieldValue(
        'streetAddress',
        `${companyData?.bezoeklocatie?.straat || ''}`,
      );
      formik.setFieldValue(
        'houseNumber',
        Number(companyData?.bezoeklocatie?.huisnummer) || '',
      );
      formik.setFieldValue(
        'zipCode',
        companyData?.bezoeklocatie?.postcode || '',
      );
      formik.setFieldValue('city', companyData?.bezoeklocatie?.plaats || '');
    }
  }, [companyData]);
  useEffect(() => {
    const handleClickOutside = event => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setShowResult(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className="auth-layout">
      <Row className="w-100 m-md-4 m-2">
        <Col lg={7} xl={6} xs={12}>
          <Card className="bg-auth">
            <div className="bg-auth-footer">
              {t('Make energy work for you')}
            </div>
          </Card>
        </Col>
        <Col lg={5} xl={6} xs={12} className="auth-container p-md-4">
          <Card className="auth-card ">
            <CardBody className="py-lg-4 py-3">
              <h2 className="text-center font-bold mb-3">
                {t('Let’s Get Started')}
              </h2>
              <p className="text-center font-bold mx-3 text-black">
                {t(
                  'Take matters into your own hands and start your registration by entering your company name below',
                )}
              </p>
              <div className="mx-md-4 mx-1 h-75">
                <div className="margin-register">
                  <FormGroup className="position-relative " floating>
                    <Input
                      id="search"
                      type="text"
                      name="search"
                      className={` search-input`}
                      placeholder="Enter your company name"
                      value={query || ''}
                      onChange={e => handleChange(e)}
                      onFocus={() => setIsEditing(true)}
                      // onKeyUp={e=>handleShowOptions(e)}
                    />
                    <Label for="search">{t('Company Name')}</Label>
                    <span className="btn-search pe-1">
                      <img src={Search} />
                    </span>
                    {isLoading && (
                      <Spinner color="warning" className="spinner-input">
                        Loading
                      </Spinner>
                    )}
                  </FormGroup>
                  {showResult && !isLoading && (
                    <div className="company-list-container" ref={listRef}>
                      <CompanyList
                        formik={formik}
                        companyList={companyList}
                        setShowResult={setShowResult}
                        setQuery={setQuery}
                        setCompanyData={setCompanyData}
                        setDisabled={setDisabled}
                        setIsEditing={setIsEditing}
                      />
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-center start-container">
                  <Button
                    type="button"
                    disabled={isDisabled}
                    className={`${isDisabled ? 'btn-disabled' : 'btn-submit'} w-50`}
                    onClick={() => setActiveStep(1)}>
                    {t('Start')}
                  </Button>
                </div>
              </div>
              <h6 className="text-center font-regular text-gray fw-500">
                {t('Already have an account?')}
                <span
                  role="button"
                  onClick={() => navigate('/auth/login')}
                  className="btn-link font-bold">
                  {' '}
                  {t('Login Now')}
                </span>
              </h6>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default CompanySearch;
