import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import DepositFund from './DepositFund';
import WithdrawalFund from './WithdrawFund';
import alert from '../../assests/images/icons/Alert.png';
import { formatNumber } from '../../utilities/function';
import { useTranslation } from 'react-i18next';

const MyBalance = ({ type = 'wallet', balance = 0 ,estDays=0}) => {
  const { t } = useTranslation();
  const [isDeposit, setDeposit] = useState(false);
  const [isWithdrawal, setWithdrawal] = useState(false);

  const handleClose = () => {
    if (isDeposit) {
      setDeposit(false);
    }
    if (isWithdrawal) {
      setWithdrawal(false);
    }
  };
  return (
    <Card className="cstm-card balance-card ">
      <CardBody className="d-grid p-lg-4 p-3">
        <div className="mt-3">
          <span className="font-light fs-14">
            {type == 'wallet' ? `${t('Available')}` : `${t('Balance')}`}
          </span>
          <h4 className="font-bold">{formatNumber(balance, 'currency')}</h4>
        </div>
        {estDays != 0 && (
          <div>
            <span className="font-bold fs-14">
              <img
                src={alert}
                alt="alert"
                className="alert-icon mb-1"
                height={14}
              />{' '}
              Est.{' '}
              <span className="text-dark text-decoration-underline">
                {estDays} days
              </span>{' '}
              remaining!
            </span>
          </div>
        )}
        <div className="items-center justify-content-between">
          <Button
            className="w-50 me-3 bg-dark  font-bold"
            onClick={() => setDeposit(true)}>
            {t('Deposit')}
          </Button>
          <Button
            className="w-50 bg-white text-dark  font-bold"
            onClick={() => setWithdrawal(true)}>
            {t('Withdrawal')}
          </Button>
        </div>
        {isDeposit && (
          <DepositFund isOpen={isDeposit} handleClose={handleClose} />
        )}
        {isWithdrawal && (
          <WithdrawalFund isOpen={isWithdrawal} handleClose={handleClose} />
        )}
      </CardBody>
    </Card>
  );
};

export default MyBalance;
