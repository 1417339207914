export const endpoints = {
  // Node APIs
  login: '/login',
  contactUs: '/contact-us',
  dashboardWallet: '/dashboard/wallet',
  historyWallet: '/wallet-data',
  balance: '/balance',
  fetchEan: '/fetch-ean',
  companyDetails: '/company',
  sendActivationMail:'/send-activation-mail',
  accountDetails: '/account-details',
  productDetails: '/products',
  orders: '/orders',
  historicalDataByDay: 'historical-by-day',
  analytics: '/analytics',
  epexSpot: '/epex-prices',
  expensesByMonth: '/expenses',
  summary: '/summary',
  historyDownload: '/history_download',
  getBankDetails:'/getBankDetails',
  // historyDataByDate: '/historical-data',
  getMonthlyRecords:'/getMonthlyRecords',

  // ASP.NET APIs
  forecastCorrection: '/forecast',
  register: '/Registration',
  updateUser: '/Registration/UpdateUser',
  updateConnectionName:'/connection/UpdateConnection',
  historyWalletPaymentAPI: '/transaction/GetTransactionsForDateByCompany',
  balancePaymentAPI:'/Wallets/GetWalletDetails',
  getKYCStatus:'/organization/GetKycStatusAndInitialDeposit',
  getAverageCostAPI:'/wallet/GetAverageCostPerDay',
  submitWithdrawalRequestAPI:'/transaction/SubmitWithdrawalRequest'

};
