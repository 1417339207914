import React, { useState, useEffect } from 'react';
import { Col, FormGroup, Input, Button, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import visibleIcon from '../../assests/images/icons/View.png';
import invisibleIcon from '../../assests/images/icons/invisible.png';
import PasswordStrengthIndicator from '../../components/common/PasswordStrengthIndicator';
import SuccessModal from '../../components/common/SuccessModal';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setIsLoggedIn, setUserDetails } from '../../redux/AuthReducer';

const SetPassword = ({
  setChangePassword = {},

}) => {
  const [passwordVisibility, setPasswordVisibility] = useState({
    newPassword: 'password',
    confirmPassword: 'password',
  });
  const [isConfirm, setConfirm] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const togglePasswordVisibility = type => {
    setPasswordVisibility(prevState => ({
      ...prevState,
      [type]: prevState[type] === 'password' ? 'text' : 'password',
    }));
  };

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string().required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], "Passwords don't match")
      .required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      handleSubmit(values);
    },
  });

  const { values, errors, touched } = formik;
  const { newPassword, confirmPassword } = values;
  const [isDisabled, setDisabled] = useState(true);

  useEffect(() => {
    const noErrors = errors && errors.length > 0 ? false : true;
    if (
      noErrors &&
      values?.newPassword !== '' &&
      values?.confirmPassword !== ''
    ) {
      if (values?.newPassword == values?.confirmPassword) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      setDisabled(true);
    }
  }, [values, errors]);
  const handleSubmit = () => {
    setConfirm(true);
    const timeout = setTimeout(() => {
      setConfirm(false);
      handleLogout();
    }, 3000);
    return () => clearTimeout(timeout);
  };
  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    dispatch(setIsLoggedIn(false));
    dispatch(setUserDetails({}));
    navigate('/auth/login');
  };
  return (
    <div className="py-md-5 py-3 user-password">
      <form onSubmit={formik.handleSubmit}>
        <Row className="m-0 p-0">
          <Col md={4} className="px-md-0">
            <FormGroup
              className="position-relative mb-md-4 password-input"
              floating>
              <Input
                id="newPassword"
                type={passwordVisibility.newPassword}
                name="newPassword"
                placeholder="New Password"
                value={newPassword}
                onChange={formik.handleChange}
              />
              <Label for="newPassword">New Password</Label>
              <div className="toggle-password position-absolute">
                <img
                  src={
                    passwordVisibility.newPassword === 'password'
                      ? invisibleIcon
                      : visibleIcon
                  }
                  className=""
                  onClick={() => togglePasswordVisibility('newPassword')}
                  alt="Toggle Password Visibility"
                />
              </div>
              {touched.newPassword && errors.newPassword && (
                <div className="text-danger fs-14">{errors.newPassword}</div>
              )}
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup className="position-relative password-input" floating>
              <Input
                id="confirmPassword"
                type={passwordVisibility.confirmPassword}
                name="confirmPassword"
                placeholder="Re-Enter New Password"
                value={confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Label for="confirmPassword">Re-Enter New Password</Label>
              <div className="toggle-password position-absolute">
                <img
                  src={
                    passwordVisibility.confirmPassword === 'password'
                      ? invisibleIcon
                      : visibleIcon
                  }
                  className=""
                  onClick={() => togglePasswordVisibility('confirmPassword')}
                  alt="Toggle Password Visibility"
                />
              </div>
              {touched.confirmPassword && errors.confirmPassword && (
                <div className="text-danger fs-14">
                  {errors.confirmPassword}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <div className="w-100 d-flex justify-content-end edit-container">
          <Button
            className="btn-previous px-3 mx-3"
            onClick={() => setChangePassword(false)}>
            Cancel Changes
          </Button>
          <Button
            className="btn-submit"
            type="submit"
            disabled={isDisabled}
          >
            Save Changes
          </Button>
        </div>
        <PasswordStrengthIndicator password={newPassword} />
        {isConfirm && (
          <SuccessModal
            isOpen={isConfirm}
            message="Your password has been successfully updated. Please use the new password for your next login."
          />
        )}
      </form>
    </div>
  );
};

export default SetPassword;
