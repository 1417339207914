import React, { useEffect, useState } from 'react';
import './style.scss';
import image1 from '../../assests/images/aboutUsPageImages/aboutimg-1.png';
import image2 from '../../assests/images/aboutUsPageImages/aboutimg-2.png';

const FoldTwo = () => {
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='container' id='fold12'>
            <div className='container' id='vision'>
                <div className='content'>
                    <h2 className="content-heading">Ons doel</h2>
                    <h2 className="content-sub-heading">Een groene energiemarktplaats voor een duurzamere toekomst</h2>
                    <p className="content-paragraph">Onze visie bij PowerHive is om een wereld te creëren waarin 100% van onze energie afkomstig is van hernieuwbare bronnen. We geloven dat dit haalbaar is door innovatie, technologie en samenwerking. Door onze klanten te voorzien van de tools en middelen die ze nodig hebben om zelf hun energiebehoeften te beheren, streven we ernaar om een duurzame toekomst voor iedereen mogelijk te maken.</p>
                </div>
                <img src={image1} alt="Example" className="example-image" />
            </div>
            <div className='container' id='mission'>
                {isMobile ? (
                    <>
                        <div className='content'>
                            <h2 className="content-heading">Onze aanpak</h2>
                            <h2 className="content-sub-heading">Hét alles-in-één Energieplatform</h2>
                            <p className="content-paragraph">Onze unieke oplossingen stellen bedrijven in staat om direct toegang te krijgen tot energiemarkten, transparantie te bevorderen en kosten te verlagen. Door te focussen op gebruiksgemak, betrouwbaarheid en duurzaamheid, streven we ernaar om de overgang naar een groenere toekomst te versnellen en een positieve impact te hebben op het milieu en de samenleving.</p>
                        </div>
                        <img src={image2} alt="Example" className="example-image" />
                    </>
                ) : (
                    <>
                        <img src={image2} alt="Example" className="example-image" />
                        <div className='content'>
                            <h2 className="content-heading">Onze aanpak</h2>
                            <h2 className="content-sub-heading">Hét alles-in-één Energieplatform</h2>
                            <p className="content-paragraph">Onze unieke oplossingen stellen bedrijven in staat om direct toegang te krijgen tot energiemarkten, transparantie te bevorderen en kosten te verlagen. Door te focussen op gebruiksgemak, betrouwbaarheid en duurzaamheid, streven we ernaar om de overgang naar een groenere toekomst te versnellen en een positieve impact te hebben op het milieu en de samenleving.</p>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default FoldTwo;