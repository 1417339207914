import { Modal, ModalBody } from 'reactstrap';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Input, Row, Label, Button } from 'reactstrap';
import closeIcon from '../../assests/images/icons/close.svg';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { processAPI } from '../../utilities/api';
import { endpoints } from '../../utilities/Endpoints';
import Loader from '../../components/common/Loader';
import moment from 'moment';

const EditConnectionModal = ({
  isOpen = '',
  data = '',
  handleClose = {},
  fetchData = {},
}) => {
  const { t } = useTranslation();
  const {
    connectionId,
    eanNumber,
    name,
    connectionStatus,
    houseNo,
    streetAddress,
    suffix,
    postalCode,
    townCity,
    startDate,
    capacity,
    productionVol,
    consumptionVol,
  } = data;
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(5, t('Min. 5 characters required'))
      .required(t('Connection Name is required')),
  });

  const formik = useFormik({
    initialValues: {
      name: name ? name : streetAddress + ' ' + houseNo + ' ' + (suffix? suffix:''),
    },
    validationSchema: validationSchema,
    onSubmit: values => handleSubmit(values),
  });
  const { values, errors, touched } = formik;
  const connName = name ? name.substring(0) : name;
  const [isDisabled, setIsDisabled] = useState(true);
  const [id, setId] = useState('');
  const companyId = sessionStorage.getItem('companyId');
  const accessToken = sessionStorage.getItem('accessToken');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async values => {
    setLoading(true);
    let payload = {
      connectionId: id || '',
      connectionName: values?.name || '',
    };
    try {
      const response = await processAPI.post(
        endpoints.updateConnectionName,
        payload,
        {
          headers: {
            companyId: companyId,
            accessToken: accessToken,
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      if (response?.status == 200) {
        toast.success(
          response?.data?.value?.message ||
            t('Connection name updated successfully'),
        );
        handleClose();
        fetchData();
      }
    } catch (error) {
      console.log('Error updating record', error);
      toast.error('Error updating connection Name');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (values?.name == connName) {
      setIsDisabled(true);
    } else if (
      values.name.length <= 0 &&
      errors.name != '' &&
      values?.name != connName
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [values, errors]);
  return (
    <Modal
      isOpen={isOpen}
      centered
      backdrop
      size="lg"
      className="edit-connection">
      <ModalBody>
        <span role="button" className="close-button">
          <img src={closeIcon} height={25} onClick={handleClose} />
        </span>
        <div className="d-flex align-items-end justify-content-start">
          <h5 className={`mb-0 font-bold`}>{t('Set Connection Name')}</h5>
          <div className="fs-16">
            <span
              className={`${connectionStatus == 'Active' ? 'text-success' : connectionStatus == 'Inactive'?'text-danger':'text-theme'} font-bold ms-3`}>
              {data.connectionStatus != null ? t(`${data?.connectionStatus}`):'N/A'}
            </span>
          </div>
        </div>
        <div className="pt-4 tab-details">
          <form onSubmit={formik.handleSubmit}>
            <Row className="form-container">
              <Col md={6} className="py-2">
                <FormGroup floating className="position-relative">
                  <Input
                    id="name"
                    name="name"
                    placeholder="Connection Name"
                    type="text"
                    onChange={e => {
                      let str = e.target.value;
                      if (str.charAt(0) == ' ') {
                        return;
                      }
                      formik.setFieldValue('name', e.target.value);
                      setIsDisabled(false);
                    }}
                    onBlur={formik.handleBlur}
                    value={values.name}
                    className={`${errors.name && touched.name ? 'has-error' : ''}`}
                  />
                  <Label for="name">{t('Connection Name')}</Label>
                  {errors.name && touched.name ? (
                    <div className="error-msg text-danger">{errors.name}</div>
                  ) : (
                    ''
                  )}
                </FormGroup>
              </Col>{' '}
              <Col md={3} className="py-2">
                <FormGroup floating>
                  <Input
                    id="city"
                    name="city"
                    placeholder="Town / City "
                    type="text"
                    className="text-capitalize"
                    value={townCity}
                    readOnly
                    disabled
                  />
                  <Label for="city">{t('Town / City')}</Label>
                </FormGroup>
              </Col>
              <Col md={3} className="py-2">
                <FormGroup floating>
                  <Input
                    id="EAN"
                    name="EAN"
                    placeholder="EAN"
                    type="text"
                    value={eanNumber}
                    readOnly
                    disabled
                  />
                  <Label for="EAN">EAN</Label>
                </FormGroup>
              </Col>
              <Col md={3} className="py-2">
                <FormGroup floating className="">
                  <Input
                    id="streetAddress"
                    name="streetAddress"
                    placeholder="Street Address"
                    type="streetAddress"
                    readOnly
                    value={streetAddress || ''}
                    disabled
                  />
                  <Label for="streetAddress">{t('Street Address')}</Label>
                </FormGroup>
              </Col>
              <Col md={3} className="py-2">
                <FormGroup floating className="">
                  <Input
                    id="houseNo"
                    name="houseNo"
                    placeholder="HouseNo."
                    type="text"
                    readOnly
                    value={houseNo}
                    disabled
                  />
                  <Label for="houseNo">{t('HouseNo.')}</Label>
                </FormGroup>
              </Col>
              <Col md={3} className="py-2">
                <FormGroup floating className="">
                  <Input
                    id="consumption"
                    name="consumption"
                    placeholder="Consumption Vol."
                    type="consumption"
                    readOnly
                    value={consumptionVol}
                    disabled
                  />
                  <Label for="consumption">{t('Consumption')}(kWh)</Label>
                </FormGroup>
              </Col>
              <Col md={3} className="py-2">
                <FormGroup floating className="">
                  <Input
                    id="production"
                    name="production"
                    placeholder="Production Vol."
                    type="production"
                    readOnly
                    value={productionVol}
                    disabled
                  />
                  <Label for="production">{t('Production')}(kWh)</Label>
                </FormGroup>
              </Col>
              <Col md={3} className="py-2">
                <FormGroup floating className="">
                  <Input
                    id="suffix"
                    name="suffix"
                    placeholder="Suffix"
                    type="suffix"
                    readOnly
                    disabled
                    value={suffix || ''}
                  />
                  <Label for="suffix">{t('Suffix')}</Label>
                </FormGroup>
              </Col>
              <Col md={3} className="py-2">
                <FormGroup floating className="">
                  <Input
                    id="zipCode"
                    name="zipCode"
                    placeholder="ZIP Code"
                    type="zipCode"
                    readOnly
                    disabled
                    value={postalCode}
                  />
                  <Label for="zipCode">{t('ZIP Code')}</Label>
                </FormGroup>
              </Col>
              <Col md={3} className="py-2">
                <FormGroup floating className="">
                  <Input
                    id="capacity"
                    name="capacity"
                    placeholder="Capacity"
                    type="capacity"
                    readOnly
                    disabled
                    value={capacity}
                  />
                  <Label for="capacity">{t('Capacity')}</Label>
                </FormGroup>
              </Col>
              <Col md={3} className="py-2">
                <FormGroup floating className="">
                  <Input
                    id="date"
                    name="date"
                    placeholder="Start Date"
                    type="string"
                    readOnly
                    disabled
                    value={startDate != null ? moment(new Date(startDate)).format('DD MMM YYYY'):''}
                  />
                  <Label for="date">{t('Start Date')}</Label>
                </FormGroup>
              </Col>
              <Col
                md={12}
                className="w-100 d-flex justify-content-end edit-container my-2">
                <Button
                  className="btn-previous px-3 mx-3"
                  onClick={handleClose}>
                  {t('Cancel')}
                </Button>
                <Button
                  className="btn-submit"
                  type="submit"
                  onClick={() => setId(connectionId)}
                  disabled={isDisabled}>
                  {t('Save Changes Edit')}
                </Button>
              </Col>
            </Row>
          </form>
        </div>
        {loading && <Loader isOpen={loading} />}
      </ModalBody>
    </Modal>
  );
};

export default EditConnectionModal;
