import React, { useEffect, useMemo, useState } from 'react';
import { BsCircleFill, BsSquareFill } from 'react-icons/bs';
import { Card, CardBody, CardTitle } from 'reactstrap';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import EmptyIcon from '../../assests/images/miscellaneous/search.svg';
import NoData from '../../assests/images/miscellaneous/file.svg';
import { api } from '../../utilities/api';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useTooltipContext } from '../common/CustomeTooltip';
import { formatNumber } from '../../utilities/function';
import Loader from '../common/Loader';
import { useTranslation } from 'react-i18next';
import { endpoints } from '../../utilities/Endpoints';

const CurrentOrders = ({
  currentData='',
  setCurrentData={},
  setForecastDate = {},
  setCurrentDayData = {},
  setForecastData = {},
  isUpdated = false,
  forecastLoading=false,
  setForecastLoading={}
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { openTooltip, closeTooltip } = useTooltipContext();

  const getCurrentOrdersData = async () => {
    setLoading(true);
    try {
      const idToken =
        sessionStorage.getItem('idToken') || localStorage.getItem('idToken');
      const companyId =
        sessionStorage.getItem('companyId') ||
        localStorage.getItem('companyId');
      const response = await api.get(endpoints.orders, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          companyId: companyId,
        },
      });
      if (response.status === 200 && response?.data) {
        setCurrentData(response?.data?.currentOrders);
        setForecastDate(response?.data?.currentOrders[0]?.date);
        sessionStorage.setItem(
          'currentDay',
          response?.data?.currentOrders[0]?.date,
        );
        setCurrentDayData(response?.data);
      }
    } catch (error) {
      console.error('Error getting current orders', error);
      toast.error(
        t(error?.response?.data?.message) ||
          `${t('Something went wrong. Please re-login.')}`,
      );
      setCurrentDayData([]);
    }
    setLoading(false);
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      getCurrentOrdersData();
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);
 
  const handleClick = selectedDate => {
    setForecastDate(selectedDate);
  };
  // const tooltipContent = e => {
  //   let { payload, date } = e;
  //   return (
  //     <div className="custom-tooltip">
  //       <p className="label">{moment(new Date(date)).format('DD MMM')}</p>
  //       <div className="items-center my-1">
  //         <BsSquareFill size={15} color={`#FBBB36`} className="me-2" />
  //         {formatNumber(payload?.orderVolume)}&nbsp;
  //         {currentData[0]?.volumeUnit}
  //       </div>
  //     </div>
  //   );
  // };
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{moment(new Date(payload[0]?.payload?.date)).format('DD MMM')}</p>
          <p>
            <BsSquareFill
              size={20}
              color={`${payload[0]?.color}`}
              className="me-2"
            />
            {formatNumber(payload[0]?.payload?.orderVolume)}&nbsp;
            {payload[0]?.payload?.volumeUnit}
          </p>
        </div>
      );
    }
    return null;
  };
  return (
    <div>
      <Card className="cstm-card mt-3">
        <CardBody className="pt-md-4 pt-3">
          {loading && <Loader isOpen={loading} />}
          {loading || currentData.length <= 0  || (forecastLoading && isUpdated) ? (
            <div className="no-data-view">
              <img
                src={currentData.length <= 0 && !loading ? NoData : EmptyIcon}
                alt="No Data"
                height={100}
              />
              {currentData.length <= 0 && !loading ? (
                <span className="font-regular text-center">
                  {t('No data yet')}
                </span>
              ) : (
                <span className="font-regular text-center">
                  {t('Forecast is being generated.')} <br />
                  {t('This usually appears the next day')}
                </span>
              )}
            </div>
          ) : (
            <>
              <CardTitle className="items-center justify-content-end mx-md-4 ">
                <BsCircleFill
                  color="#FBBB36"
                  size={20}
                  className="me-md-2 me-1"
                />

                <span>
                  {t('Forecast')}&nbsp;({currentData[0]?.volumeUnit})
                </span>
              </CardTitle>
              <div className="pt-2" style={{ height: '350px' }}>
                <ResponsiveContainer width="100%" height="90%">
                  <BarChart
                    width="100%"
                    height={300}
                    data={currentData}
                    barSize={20}
                    margin={{ top: 5, right: 30, left: 10, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="5 3" vertical={false} />
                    <XAxis
                      dataKey="date"
                      axisLine={false}
                      tickLine={false}
                      tickSize={15}
                      className='cursor-pointer'
                      onClick={(e) => handleClick(e?.value)}
                      tickFormatter={tick =>
                        moment(new Date(tick)).format('DD/MM')
                      }
                    />
                    <YAxis
                      axisLine={false}
                      tickLine={false}
                      tickCount={6}
                      tickSize={20}
                      type="number" 
                      domain={['auto', 'auto']} 

                      tickFormatter={tick =>
                        tick != 0 ? formatNumber(tick) : '0'
                      }
                    />
                    <Tooltip content={CustomTooltip} cursor={false} />
                    <Bar
                      dataKey="orderVolume"
                      fill="#FBBB36"
                      className="cursor-pointer position-relative"
                      onClick={e => handleClick(e?.date)}
                      radius={20}
                      minPointSize={2}
                      unit={currentData[0]?.volumeUnit}
                      // onMouseEnter={e =>
                      //   openTooltip({
                      //     content: tooltipContent(
                      //       e,
                      //       currentData[0]?.volumeUnit,
                      //     ),
                      //   })
                      // }
                      // onMouseLeave={() => closeTooltip()}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default CurrentOrders;
