import React, { useRef } from 'react';
import './style.scss';
// import chartImage from '../../assests/images/homePageImages/chart-image.svg';
import chartImage from '../../assests/images/homePageImages/HomeHeroFold.svg';
import { Link } from 'react-router-dom';

const HeroSection = ({ foldTwoRef }) => {

    const handleScroll = () => {
        foldTwoRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="container-wrapper">
            <div className="container" id="container-1">
                <div className="heading-container">
                    <h1 id="header-1">De Toekomst Van Energie</h1>
                </div>
                <div className="subheading-container">
                    <h2 id="sub-header-1">
                        Welkom bij PowerHive, waar wij jou de controle over jouw energie geven. Ons platform biedt directe toegang tot de energiemarkt, waardoor je eenvoudig energie kunt verhandelen tegen de laagste kosten. Optimaliseer je energiegebruik, bespaar kosten en draag bij aan een duurzamere toekomst.  Ontdek hoe PowerHive voor jouw organisatie energiebeheer simpel en betaalbaar maakt.
                    </h2>
                </div>
                <div className="button-container" id="fold-1-button">
                    <Link id="join" onClick={handleScroll}>Ontdek Meer</Link>
                </div>
            </div>

            <div className="container" id="chart-container">
                <div className="image">
                    <img src={chartImage} alt="Image 1" />
                    <div className="button-container" id="fold-1-sub-button">
                        <Link id="join" onClick={handleScroll}>Ontdek Meer</Link>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default HeroSection;
