import React from 'react';
import { Col, FormGroup, Input, Row, Label } from 'reactstrap';
import { formatNumber } from '../../utilities/function';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

const MyPlans = ({ data = '' }) => {
  const { t } = useTranslation();
  const {
    subscriptionPlan,
    cancellationTerm,
    planCosts,
    planEndDate,
    planStartDate,
    transactionFees,
    pricingPeriod,
  } = data;
  let startDate = moment(new Date(planStartDate)).format('DD/MM/YYYY');
  let endDate = moment(new Date(planEndDate)).format('DD/MM/YYYY');
  let planCost =
    planCosts != null || planCosts != 0
      ? pricingPeriod == 'Monthly'
        ? formatNumber(planCosts)
        : pricingPeriod == 'Yearly'
          ? formatNumber(planCosts * 12)
          : formatNumber(planCosts)
      : 0;

  return (
    <div>
      <div className="py-xl-5 tab-details">
        <Row className="form-container">
          <Col md={6} className="mb-3">
            <FormGroup floating className="">
              <Input
                id="planType"
                name="planType"
                placeholder="Subscription Plan "
                type="text"
                readOnly
                value={t(`${subscriptionPlan}`)}
              />
              <Label for="planType">{t('Subscription Plan')}</Label>
            </FormGroup>
          </Col>
          <Col md={6} className="mb-3">
            <FormGroup floating className="">
              <Input
                id="cancellation"
                name="cancellation"
                placeholder="Cancellation Term "
                type="text"
                value={t(`${cancellationTerm}`)}
                readOnly
              />
              <Label for="cancellation">{t('Cancellation Term')}</Label>
            </FormGroup>
          </Col>
          <Col md={6} className="mb-3">
            <FormGroup floating className="">
              <Input
                id="cost"
                name="cost"
                placeholder="Costs "
                type="text"
                value={
                  '€ ' +
                  formatNumber(planCost) +
                  ' ' +
                  (pricingPeriod == 'Monthly'
                    ? '/' + ' ' + t('Month')
                    : pricingPeriod == 'Yearly'
                      ? '/' + ' ' + t('Yearly')
                      : '/' + ' ' + t('Month'))
                }
                readOnly
              />
              <Label for="costs">{t('costs-account')}</Label>
            </FormGroup>
          </Col>
          {/* <Col sm={6} className="mb-3">
            <FormGroup floating className="">
              <Input
                id="deposit"
                name="deposit"
                placeholder="Deposit"
                type="text"
                value={'€ '+formatNumber(deposit)}
                readOnly
              />
              <Label for="deposit">Deposit</Label>
            </FormGroup>
          </Col> */}
          <Col sm={6} className="mb-3">
            <FormGroup floating className="">
              <Input
                id="fee"
                name="fee"
                placeholder="Transaction fees"
                type="text"
                value={transactionFees}
                // value={'€ ' + formatNumber(transactionFees) + ' ' + '/ MWh'}
                readOnly
              />
              <Label for="fee">{t('Transaction fees')}</Label>
            </FormGroup>
          </Col>
          <Col sm={6} className="mb-3">
            <FormGroup floating className="">
              <Input
                id="startDate"
                name="startDate"
                placeholder="Plan Start Date"
                type="text"
                value={startDate}
                readOnly
              />
              <Label for="startDate">{t('Plan Start Date')}</Label>
            </FormGroup>
          </Col>
          {/* {endDate && (
            <Col sm={6} className="mb-3">
              <FormGroup floating className="">
                <Input
                  id="endDate"
                  name="endDate"
                  placeholder="Plan End Date"
                  type="text"
                  // value={endDate}
                  // value={'€ ' + formatNumber(transactionFees) + ' ' + '/ MWh'}
                  // readOnly
                />
                <Label for="endDate">{t('Plan End Date')}</Label>
              </FormGroup>
            </Col>
          )} */}
        </Row>
      </div>
    </div>
  );
};

export default MyPlans;
