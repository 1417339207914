import React, { useEffect, useState } from 'react';
import './style.scss';
import rect from '../../assests/images/homePageImages/rect.png';
import pwh from '../../assests/images/homePageImages/pwh2.png';
import LeftArrow from '../../assests/images/homePageImages/left-arrow.svg';
import RightArrow from '../../assests/images/homePageImages/right-arrow.svg';
import hexbg1 from '../../assests/images/homePageImages/hexbg1.png';
import hexbg2 from '../../assests/images/homePageImages/hexbg2.png';
import hexbg3 from '../../assests/images/homePageImages/hexbg3.png';
import hexbg4 from '../../assests/images/homePageImages/hexbg4.png';

const hexData = [
    { bg: hexbg1, text: "Efficiënt" },
    { bg: hexbg2, text: "Transparant" },
    { bg: hexbg3, text: "Eerlijk" },
    { bg: hexbg4, text: "Alles-in-1" }
];

const contentData = [
    { topic: "Inkoop zoals een energieleverancier met direct markttoegang tegen een vaste lage prijs", text: "Geniet van groothandel tarieven zonder gedoe. PowerHive stelt je in staat om energie direct van de groothandelsmarkten te kopen, net als de professionals. Geen verborgen kosten, alleen duidelijke besparingen." },
    { topic: "Transparante prijsstructuur zonder verborgen kosten", text: "Ervaar helderheid in prijzen. PowerHive garandeert transparantie met een eenvoudige maandelijkse toegangsvergoeding voor het platform, en een vaste lage transactievergoeding per kilowattuur. Wat je ziet is wat je krijgt, voor gemoedsrust." },
    { topic: "Optimalisatie van flexibele assets", text: "PowerHive stemt de belangen op elkaar af. Door je flexibele assets zoals zonne- en windenergie of batterijen te optimaliseren, delen we een klein deel van de winst. Het is een win-win situatie – als jij profiteert, doen wij dat ook." },
    { topic: "Alles-in-één oplossing zonder gedoe", text: "Vereenvoudig je energiebeheer. Met PowerHive heb je al je energiezaken op één plek, wat je strategie eenvoudiger maakt zonder onnodige complicaties. Efficiëntie wordt zo eenvoudig." },
]

const OurFeatures = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [isLeftDisabled,setLeftDisabled]= useState(true)
    const [isRightDisabled,setRightDisabled]=useState(false)

    const handleNext = () => {
        if (!isRightDisabled) {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % hexData.length);
        }
    };

    const handlePrevious = () => {
        if (!isLeftDisabled) {
            setCurrentIndex((prevIndex) => (prevIndex - 1 + hexData.length) % hexData.length);
        }
    };
    useEffect(()=>{
        if(currentIndex == 0 ){
            setLeftDisabled(true)
            setRightDisabled(false)
        }
        else if(currentIndex == hexData.length -1){
            setRightDisabled(true)
            setLeftDisabled(false)
        }
        else{
            setLeftDisabled(false)
            setRightDisabled(false)
        }

    },[currentIndex])


    const handleActiveStep = (index) =>{
        if(index < hexData.length){
            setCurrentIndex(index)
        }
    }
    return (
        <div className='container' id='fold-our-feature'>
            <div className='container our-feature'>
                {isMobile && (
                    <h2>Onze Oplossing</h2>
                )}
                <div className="hexagon-container">
                    <div className='hex'>
                        {hexData.map((hex, index) => (
                            <div key={index} role='button' className={`hexagon-wrapper  cursor-pointer hex-${index + 1} ${currentIndex === index ? 'current' : ''}`} onClick={()=>handleActiveStep(index)}>
                                <img src={rect} className="hexagon" />
                                <div className="hex-bg" style={{ backgroundImage: `url(${hex.bg})` }}></div>
                                <p className="hex-text">{hex.text}</p>
                            </div>
                        ))}
                        <div className="powerhive">
                            <img src={pwh} />
                        </div>
                    </div>
                </div>
                <div className='container feature-content'>
                    {!isMobile && (
                        <h2>Onze Oplossing</h2>
                    )}
                    <div className='fading-content'>
                        {contentData.map((hex, index) => (
                            <div key={index} className={`content content-${index + 1} ${currentIndex === index ? 'show' : 'hide'}`}>
                                <h3>{hex.topic}</h3>
                                <p>{hex.text}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="arrow-container-feature">
                <div className={`parallax-circle ${isLeftDisabled ? 'disabled' : ''}`} id="left-arrow" onClick={handlePrevious}>
                    <img className={`left-arrow-img ${isLeftDisabled ? 'disabled' : ''}`} src={LeftArrow} alt="left" />
                </div>
                <div className={`parallax-circle ${isRightDisabled ? 'disabled' : ''}`} id="right-arrow" onClick={handleNext}>
                    <img className={`right-arrow-img ${isRightDisabled ? 'disabled' : ''}`} src={RightArrow} alt="right" />
                </div>
            </div>

        </div>
    );
}

export default OurFeatures;
