import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { BsCircleFill, BsSquareFill } from 'react-icons/bs';
import { Card, CardBody } from 'reactstrap';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import NoData from '../../assests/images/miscellaneous/file.svg';
import EmptyIcon from '../../assests/images/miscellaneous/search.svg';
import { useTooltipContext } from '../common/CustomeTooltip';
import { formatNumber } from '../../utilities/function';
import { api } from '../../utilities/api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { endpoints } from '../../utilities/Endpoints';

const Analytics = () => {
  const { t } = useTranslation();
  const { openTooltip, closeTooltip } = useTooltipContext();
  const [analyticsData, setAnalyticsData] = useState([]);
  const [loading, setLoading] = useState([]);

  const tickFormat = value => {
    let date = new Date(value);
    let day = moment(date).format('DD/MM');
    return day;
  };

  const tooltipContent = (e, unit) => {
    let { payload, transactionDate } = e;
    return (
      <div className="custom-tooltip">
        <p className="label mb-0 fs-14">
          {moment(new Date(transactionDate)).format('DD MMM')}
        </p>
        <div className="items-center fs-14">
          <BsSquareFill size={15} color={`#212121`} className="me-2" />
          {formatNumber(payload?.actualVolume)}&nbsp;{unit || 'kWh'}
        </div>
        <div className="items-center my-1 fs-14">
          <BsSquareFill size={15} color={`#FBBB36`} className="me-2" />
          {formatNumber(payload?.forecastVolume)}&nbsp;{unit || 'kWh'}
        </div>
      </div>
    );
  };
  const getAnalyticsData = async () => {
    setLoading(true);
    try {
      const idToken =
        sessionStorage.getItem('idToken') || localStorage.getItem('idToken');
        const companyId =
        sessionStorage.getItem('companyId') || localStorage.getItem('companyId');
      const response = await api.get(endpoints.analytics, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          companyId:companyId
        },
      });
      if (response.status == 200) {
        setAnalyticsData(response?.data?.historical_records);
      }
    } catch (error) {
      console.log('Error getting analytics data', error);
      setAnalyticsData([]);
      toast.error(t(error?.response?.data?.message));
    }
    setLoading(false);
  };
  useEffect(() => {
    getAnalyticsData();
  }, []);
  return (
    <Card className="cstm-card">
      <CardBody>
        {!analyticsData || analyticsData.length <= 0 || loading ? (
          <div className="no-data-view">
            <img src={loading ? EmptyIcon : NoData} className="" height={100} />
            {loading ? (
              <span className="font-regular text-center">
                {t('Forecast is being generated.')}
                <br /> {t('This usually appears the next day')}
              </span>
            ) : (
              <span className="font-regular text-center">{t('No data yet')}</span>
            )}
          </div>
        ) : (
          <>
            <div className="items-center justify-content-end mx-md-4 ">
              <>
                <BsCircleFill
                  color="#FBBB36"
                  size={14}
                  className="me-md-2 me-1"
                />
                <span className="fs-12 text-blue">
                  {t('Forecast')}&nbsp;({analyticsData[0]?.volumeUnit})
                </span>
              </>
              <>
                <BsCircleFill
                  color="#212121"
                  size={14}
                  className="mx-md-2 mx-1"
                />
                <span className="fs-12 text-blue">
                  {t('Imbalance')}&nbsp;({analyticsData[0]?.volumeUnit})
                </span>
              </>
            </div>
            <div className="" style={{ height: 'inherit' }}>
              <ResponsiveContainer
                width="100%"
                height="100%"
                minWidth="250px"
                minHeight="200px">
                <BarChart
                  width={'90%'}
                  height={'90%'}
                  data={analyticsData}
                  barSize={7}
                  margin={{ top: 10, right: 0, left: 0, bottom: -10 }}>
                  <CartesianGrid strokeDasharray="5 3" vertical={false} />
                  <XAxis
                    dataKey="transactionDate"
                    axisLine={false}
                    tickLine={false}
                    tickFormatter={tick => tickFormat(tick)}
                    tickCount={7}
                  />
                  <YAxis
                    axisLine={false}
                    tickLine={false}
                    // tickCount={6}
                    tickSize={3}
                    tickFormatter={tick =>
                      tick != 0 ? formatNumber(tick) : '0'
                    }
                  />
                  <Bar
                    dataKey="forecastVolume"
                    fill="#FBBB36"
                    className="cursor-pointer position-relative"
                    radius={20}
                    onMouseEnter={e =>
                      openTooltip({
                        content: tooltipContent(
                          e,
                          analyticsData[0]?.volumeUnit || 'kWh',
                        ),
                      })
                    }
                    onMouseLeave={() => closeTooltip()}
                  />
                  <Bar
                    dataKey="actualVolume"
                    fill="#212121"
                    className="cursor-pointer position-relative"
                    radius={20}
                    onMouseEnter={e =>
                      openTooltip({
                        content: tooltipContent(
                          e,
                          analyticsData[0]?.volumeUnit || 'kWh',
                        ),
                      })
                    }
                    onMouseLeave={() => closeTooltip()}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default Analytics;
