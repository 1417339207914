import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Spinner } from 'reactstrap';
import TransactionsSummary from '../../components/Transactions/TransactionsSummary';
import VolumeOverview from '../../components/Transactions/VolumeOverview';
import CostOverview from '../../components/Transactions/CostOverview';
import History from '../../components/Transactions/History';
import CustomMonthYearSelector from '../../components/common/CustomMonthSelector';
import CustomTooltip from '../../components/common/CustomeTooltip';
import { MdOutlineFileDownload } from 'react-icons/md';
import { api } from '../../utilities/api';
import CommonModal from '../../components/common/CommonModal';
import FileIcon from '../../assests/images/icons/FileIcon.png';
import Loader from '../../components/common/Loader';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { endpoints } from '../../utilities/Endpoints';

const Transactions = () => {
  const { t } = useTranslation();
  const [summaryData, setSummaryData] = useState([]);
  const [currentMonthIndex, setCurrentMonthIndex] = useState(
    new Date().getMonth(),
  );
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [transactionOverview, setTransactionOverview] = useState([]);
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [download, setDownload] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const presentYear = new Date().getFullYear();
  const presentMonth = new Date().getMonth();
  let previousDay = moment(new Date()).subtract(1, 'day').format('YYYY-MM-DD');
  const [currentDate, setCurrentDate] = useState(previousDay);
  const [searchType, setSearchType] = useState('');
  const [historyLoading, setHistoryLoading] = useState(false);
  const [lastTransactionDate, setLastTransactionDate] = useState('');
  const [fetchNextRecord, setFetchNextRecord] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const idToken =
    sessionStorage.getItem('idToken') || localStorage.getItem('idToken');
  const companyId =
    sessionStorage.getItem('companyId') || localStorage.getItem('companyId');

  const handleTransactionData = async () => {
    setLoading(true);
    try {
      const companyId = sessionStorage.getItem('companyId');
      const response = await api.get(
        `${endpoints.summary}?month=${currentMonthIndex + 1}&year=${currentYear}`,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
            companyId: companyId,
          },
        },
      );
      if (response.status == 200) {
        setSummaryData(response?.data?.transactionSummary);
        setTransactionOverview(response?.data?.transactionVolumes);
      }
    } catch (err) {
      console.log('Error fetching data', err);
      setSummaryData([]);
      setTransactionOverview([]);
    }
    setLoading(false);
  };
  const handleDownloadHistory = async () => {
    setDownload(true);
    setDownloading(true);
    try {
      const response = await api.get(
        `${endpoints.historyDownload}?month=${currentMonthIndex + 1}&year=${currentYear}`,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
            companyId: companyId,
          },
          responseType: 'blob',
        },
      );
      if (response.status == 200 && response.data) {
        var blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        let selectedMonth =
          currentMonthIndex < 9
            ? '0' + (currentMonthIndex + 1)
            : currentMonthIndex + 1;
        let companyName = sessionStorage.getItem('companyName');
        let filename = `Verbruiksrapport_${companyName}_${selectedMonth}_${currentYear}.xlsx`;
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        delayDebounce();
      } else {
        toast.error('Error downloading file, Please try again later.');
      }
    } catch (err) {
      console.log('Error fetching data', err);
      toast.error('Error downloading file, Please try again later.');
    }
    const timeout = setTimeout(() => {
      setDownload(false);
    }, 3000);
    return () => clearTimeout(timeout);
  };

  const getHistoryByMonth = async (type, counts) => {
    try {
      const companyId = sessionStorage.getItem('companyId');
      if (type == 'update') {
        const response = await api.post(
          `${endpoints.getMonthlyRecords}`,
          { month: currentMonthIndex + 1, year: currentYear, offset: counts },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
              companyId: companyId,
            },
          },
        );
        if (response?.status == 200) {
          let data = response?.data?.data;
          if (data.length == 0) {
            setHasMore(false);
          }
          setHistory(prevHistory => [...prevHistory, ...data]);
          setLastTransactionDate(data[data.length - 1]?.transactionDate);
          setFetchNextRecord(false);
        }
      } else {
        const response = await api.post(
          `${endpoints.getMonthlyRecords}`,
          { month: currentMonthIndex + 1, year: currentYear, offset: 0 },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
              companyId: companyId,
            },
          },
        );
        if (response?.status == 200) {
          let data = response?.data?.data;
          setHistory(data);
          setLastTransactionDate(data[data.length - 1]?.transactionDate);
        } else {
          setHistory([]);
          setLastTransactionDate('');
        }
      }
      setHistoryLoading(false);
    } catch (error) {
      console.log('Error fetching data', error);
      toast.error(t(error?.response?.data?.message))
      setHistory([]);
      setLastTransactionDate('');
    }
  };

  const delayDebounce = () => {
    const timeout = setTimeout(() => {
      setDownloading(false);
    }, 2000);
    return () => clearTimeout(timeout);
  };
  const handleShowMore = () => {
    setHistoryLoading(true);
    setSearchType('fetch');
    let nextTransactionDate = moment(lastTransactionDate)
      .subtract(1, 'day')
      .format('YYYY-MM-DD');
    setCurrentDate(nextTransactionDate);
    setOffset(offset + 10);
    getHistoryByMonth('update', offset + 10);
  };
  function getLastDateOfMonth(year, month) {
    if (month - 1 != presentMonth) {
      const date = moment(`${year}-${month}`, 'YYYY-MM');
      const lastDate = date.endOf('month').format('YYYY-MM-DD');
      setCurrentDate(lastDate);
    }
  }

  useEffect(() => {
    getLastDateOfMonth(currentYear, currentMonthIndex + 1);
    handleTransactionData();
    setLastTransactionDate('');
    setSummaryData([]);
    setTransactionOverview([]);
    setLoading(true);
    setSearchType('');
    setHasMore(true);
    setOffset(0);
    getHistoryByMonth('loadData', 0);

    if (currentMonthIndex == presentMonth) {
      setCurrentDate(previousDay);
    }
  }, [currentMonthIndex, currentYear]);

  return (
    <div className="p-3 transactions-section">
      <div className="month-selector">
        <CustomMonthYearSelector
          loading={loading}
          currentMonthIndex={currentMonthIndex}
          currentYear={currentYear}
          setCurrentYear={setCurrentYear}
          setCurrentMonthIndex={setCurrentMonthIndex}
        />
      </div>
      <Row className="m-0 px-1">
        <Col md={12} className="py-md-3 px-0 transactions-summary">
          <h5 className="title" style={{ userSelect: 'none' }}>
            {t('Summary')}
          </h5>
          <TransactionsSummary data={summaryData} loading={loading} />
        </Col>
        <Col md={12} className="py-md-3 px-0 volume-overview">
          <h5 className="title">{t('Volume Overview')}</h5>
          <VolumeOverview loading={loading} data={transactionOverview} />
        </Col>
        <Col md={12} className="py-md-3 px-0 cost-overview">
          <h5 className="title">{t('Cost Overview')}</h5>
          <CostOverview loading={loading} data={transactionOverview} />
        </Col>
        <Col md={12} className="py-md-3 px-0 transaction-history">
          <div className="items-center justify-content-between">
            <h5 className="title mb-0">{t('History')}</h5>
            <button
              type="button"
              disabled={
                loading ||
                history.length <= 0 ||
                presentMonth == currentMonthIndex
                  ? true
                  : false
              }
              className={`btn-download`}
              onClick={handleDownloadHistory}>
              <MdOutlineFileDownload
                size={20}
                color={
                  loading ||
                  history.length <= 0 ||
                  presentMonth == currentMonthIndex
                    ? '#757575'
                    : '#fff'
                }
                className="mb-1"
              />
              <span>{t('Download')}</span>
            </button>
          </div>
          <History
            data={history}
            month={currentMonthIndex + 1}
            year={currentYear}
            loading={loading}
            hasMore={hasMore}
            setData={setHistory}
            handleShowMore={handleShowMore}
            historyLoading={historyLoading}
            fetchNextRecord={fetchNextRecord}
            lastTransactionDate={lastTransactionDate}
          />
        </Col>
        {download && !loading && (
          <CommonModal
            icon={FileIcon}
            isOpen={download}
            downloading={downloading}
          />
        )}
        {loading && !download && <Loader isOpen={loading} />}
        {fetchNextRecord && <Loader isOpen={fetchNextRecord} />}
      </Row>
    </div>
  );
};

export default Transactions;
