import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { lazy, useEffect, useState } from 'react';
import NotFound from '../components/common/NotFound';
import Dashboard from '../pages/UserDashboard/Dashboard';
import Wallet from '../pages/UserDashboard/Wallet';
import Orders from '../pages/UserDashboard/Orders';
import Transactions from '../pages/UserDashboard/Transactions';
import UserDashboard from '../pages/UserDashboard';
import UserAccount from '../pages/Account';
import Help from '../pages/UserHelp';
import { toast } from 'react-toastify';
import KYCPopup from '../components/common/KYCPopup';
import { paymentAPI } from '../utilities/api';
import { getKYCStatus } from '../pages/UserDashboard/function';
import { IS_FEATURED } from '../config/constants';
import MessagePopup from '../components/common/MessagePopup';
import WalletPopup from '../components/common/WalletPopup';
import Loader from '../components/common/Loader';

const AppRoutes = () => {
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [kycDetails, setKycDetails] = useState({
    initialDeposit: 0,
    isWalletActive: true,
    kycStatus: 'KYC Completed',
    securityDeposit: 0,
  });
  const [loading, setLoading] = useState(false);
  const companyId = sessionStorage.getItem('companyId');
  const routes = [
    {
      path: '/app/dashboard',
      element: <Dashboard />,
    },
    {
      path: '/app/wallet',
      element: <Wallet />,
    },
    {
      path: '/app/orders',
      element: <Orders />,
    },
    {
      path: '/app/transactions',
      element: <Transactions />,
    },
    {
      path: '/app/account',
      element: <UserAccount />,
    },
    {
      path: '/app/help',
      element: <Help type="user" />,
    },
  ];
  const handleClose = () => {
    setKycDetails({
      ...kycDetails,
      isWalletActive: true,
      kycStatus: 'KYC Completed',
    });
  };
  const handleCloseMessageModal = () => {
    setShowMessageModal(false);
  };
  const getStatus = async () => {
    setLoading(true);
    const kycDetailsResponse = await getKYCStatus(companyId);
    if (kycDetailsResponse?.status == 500) {
      setShowMessageModal(true);
      setLoading(false);
      return;
    }
    setKycDetails({
      isWalletActive: kycDetailsResponse?.isWalletActive,
      initialDeposit: kycDetailsResponse?.initialDeposit,
      kycStatus: kycDetailsResponse?.kycStatus,
      securityDeposit: kycDetailsResponse?.securityDeposit,
    });
    setLoading(false);
  };

  useEffect(() => {
    if (IS_FEATURED == 'true') {
      getStatus();
    }
  }, []);
  return (
    <>
      <Routes>
        <Route path="/user/app" element={<UserDashboard />} />
        <Route
          path="/auth/login"
          element={<Navigate to={'/user/app/dashboard'} replace={true} />}
        />
        <Route path="/app" element={<UserDashboard />}>
          {routes.map((route, index) => {
            return (
              <Route key={index} path={route.path} element={route.element} />
            );
          })}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      {kycDetails?.kycStatus != 'KYC Completed' && IS_FEATURED == 'true' && (
        <KYCPopup
          isOpen={kycDetails?.kycStatus != 'KYC Completed' ? true : false}
          handleClose={handleClose}
          amount={kycDetails?.initialDeposit}
        />
      )}
   
      {kycDetails?.kycStatus == 'KYC Completed' &&
        IS_FEATURED == 'true' &&
        !kycDetails?.isWalletActive && (
          <WalletPopup
            isOpen={!kycDetails?.isWalletActive}
            handleClose={handleClose}
            initialDeposit={kycDetails?.initialDeposit}
            securityDeposit={kycDetails?.securityDeposit}
          />
        )}
      {showMessageModal && IS_FEATURED == 'true' && (
        <MessagePopup
          type="app"
          isOpen={showMessageModal}
          handleClose={handleCloseMessageModal}
        />
      )}
      {loading && <Loader isOpen={loading} />}
    </>
  );
};
export default AppRoutes;
