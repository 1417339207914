import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import CurrentOrders from '../../components/Orders/CurrentOrders';
import HourlyForecast from '../../components/Orders/HourlyForecast';
import ForecastCorrection from '../../components/Orders/Forecast';
import './styles.scss';
import moment from 'moment';
import CustomTooltip from '../../components/common/CustomeTooltip';
import Loader from '../../components/common/Loader';
import { useTranslation } from 'react-i18next';

const Orders = () => {
  const { t } = useTranslation();
  const [forecastDate, setForecastDate] = useState('');
  const [currentData, setCurrentData] = useState([]);
  const [currentDayData, setCurrentDayData] = useState([]);
  const [forecastData, setForecastData] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <div className="p-3 orders-section">
      <Row className="m-0  px-1">
        <Col md={12} className="py-md-3 px-0">
          <h5 className="title">{t('Current Orders')}</h5>
          <CustomTooltip>
            <CurrentOrders
              currentData={currentData}
              setCurrentData={setCurrentData}
              forecastDate={forecastDate}
              setForecastDate={setForecastDate}
              setCurrentDayData={setCurrentDayData}
              setForecastData={setForecastData}
              forecastLoading={loading}
              setForecastLoading={setLoading}
              isUpdated={isUpdated}
            />
          </CustomTooltip>
        </Col>
        <Col md={12} className="py-md-3 px-0">
          <div className="items-center justify-content-between">
            <h5 className="title">{t('Hourly Forecast')}</h5>
            {forecastDate && (
              <div className="forecast-date">
                {moment(new Date(forecastDate)).format('DD MMM YYYY')}
              </div>
            )}
          </div>

          <HourlyForecast
            setCurrentData={setCurrentData}
            forecastDate={forecastDate}
            forecastData={forecastData}
            currentDayData={currentDayData}
            setForecastData={setForecastData}
            loading={loading}
            setLoading={setLoading}
            isUpdated={isUpdated}
            setIsUpdated={setIsUpdated}
          />
        </Col>
        <Col md={12} className="py-md-3 px-0">
          <h5 className="title">{t('Forecast Correction')}</h5>
          <ForecastCorrection
            forecastDate={forecastDate}
            forecastData={forecastData}
            isUpdated={isUpdated}
            setIsUpdated={setIsUpdated}
            loading={loading}
            setLoading={setLoading}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Orders;
