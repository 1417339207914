import React from 'react';
import { FcCheckmark } from 'react-icons/fc';
import { FaXmark } from "react-icons/fa6";

const PasswordStrengthIndicator = ({ password }) => {
  let usermail = 'vertika199@gmail.com';
  let username = 'vertika';
  const usernameRegex = new RegExp(username, 'i');
  const emailRegex = new RegExp(
    usermail.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'),
    'i',
  );
  const requirements = [
    {
      label: 'At least 6 characters',
      condition: password.length >= 6,
    },
    {
      label: 'Should include atleast 1 uppercase',
      condition: /[A-Z]/.test(password),
    },
    {
      label: 'Cannot contain your name or email address',
      condition: !usernameRegex.test(password) && !emailRegex.test(password),
    },
    {
      label: 'Contains a numbers or symbol',
      condition: /[0-9@$!%*?&]/.test(password),
    },
  ];

  return (
    <div className='mb-2'>
      {password!=''&&requirements.map((req, index) => (
        <div key={index} style={{ color: req.condition ? 'green' : 'red' }} className='password-validation'>
       {!req?.condition? <FaXmark size={15} className='mb-1'/>:<FcCheckmark size={15} className='mb-1' />} {req.label}
        </div>
      ))}
    </div>
  );
};

export default PasswordStrengthIndicator;
