import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import MyBalance from '../../components/UserDashboard/MyBalance';
import AmountCard from '../../components/common/AmountCard';
import { IoIosLock, IoMdAdd } from 'react-icons/io';
import HistoryTransactionTable from '../../components/Wallet/HistoryTransactionTable';
import Loader from '../../components/common/Loader';
import { api, paymentAPI } from '../../utilities/api';
import { useTranslation } from 'react-i18next';
import { endpoints } from '../../utilities/Endpoints';
import { IS_FEATURED } from '../../config/constants';
import HistoryTransaction from '../../components/Wallet/HistoryTransaction';
import { toast } from 'react-toastify';
const Wallet = () => {
  const { t } = useTranslation();
  const [walletDetails,setWalletDetails] = useState({
    balance: 0,
    depositAmount:0,
    totalAmount:0,
    daysRemaining:0
  })
  const [loading, setLoading] = useState(false);
  const idToken =
    sessionStorage.getItem('idToken') || localStorage.getItem('idToken');
    const companyId =
    sessionStorage.getItem('companyId') || localStorage.getItem('companyId');
    const accessToken =
    sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken');

    const getBalanceAmount = async () => {
      setLoading(true);
      try {
        if (IS_FEATURED == 'true') {
          const response = await paymentAPI.get(
            `${endpoints.balancePaymentAPI}?companyId=${companyId}`,
            {
              headers: {
                Authorization: `Bearer ${idToken}`,
                accessToken:accessToken
              },
            },
          );
          if (response?.status == 200) {
            let resData = response?.data;
            setWalletDetails({
              balance: resData?.spendableAmount,
              depositAmount:resData?.depositAmount,
              totalAmount:resData?.powerhiveAmount,
              daysRemaining: resData?.daysRemaining,
            });
          }
        } else {
          const companyId =
            sessionStorage.getItem('companyId') ||
            localStorage.getItem('comapnyId');
          const response = await api.get(`${endpoints.balance}`, {
            headers: {
              Authorization: `Bearer ${idToken}`,
              companyId: companyId,
            },
          });
          if (response?.status == 200) {
            let resData = response?.data;
            setWalletDetails({
              balance: resData?.balance,
              depositAmount:resData?.deposit,
              totalAmount:resData?.amount,
              daysRemaining: resData?.estimatedNumDays,
            });
          }
        }
      } catch (error) {
        console.log('Error getting balance amount', error);
        toast.error(t(error?.response?.data?.message||error?.response?.statusText))
        setWalletDetails({
          balance: 0,
          daysRemaining: 0,
        });
      }
      setLoading(false);
    };
  useEffect(() => {
    getBalanceAmount();
  }, []);

  return (
    <div className="px-lg-3 pt-3 user-dashboard wallet-section">
      <Row className="m-0  px-1">
        <Col md={12} className="pt-md-3 px-0">
          <h5 className="title pb-1">{t('Available Funds')}</h5>
        </Col>
        <Col md={4} className="my-balance ps-0 py-2 position-relative">
          <MyBalance type="wallet" balance={walletDetails.balance} estDays={walletDetails?.daysRemaining}/>
          <div className="position-absolute bg-icon event-icon">
            <span className="items-center justify-content-center h-100">
              <IoMdAdd size={25} color="#000" />
            </span>
          </div>
        </Col>
        <Col md={4} className="amount-card deposit-card py-2 position-relative">
          <AmountCard
            title="Deposit-wallet"
            text="Deposit is determined on a daily basis"
            amount={walletDetails?.depositAmount}
            icon={<IoIosLock color="#001427" size={24} className="mb-2" />}
          />
          <div className="position-absolute bg-icon event-icon equal-icon"></div>
        </Col>
        <Col md={4} className="amount-card total-card pe-0 py-2">
          <AmountCard
            totalAmount={walletDetails?.totalAmount}
            title="Totaal"
            text="This is total amount you have deposited"
            amount="€ 208,755"
          />
        </Col>
      </Row>
      <div className="pt-md-3">
        <h5 className="title pb-2">{t('History Transaction')}</h5>
      </div>
      {IS_FEATURED == 'true' ?  <HistoryTransactionTable />: <HistoryTransaction />}
      {loading && <Loader isOpen={loading} />}
    </div>
  );
};

export default Wallet;
