import React, { useState, useEffect } from 'react';
import '../assests/scss/footer.scss';
import logo from '../assests/images/homePageImages/logo.svg';
import LinkedInlogo from '../assests/images/homePageImages/LinkedIn.svg';
import MessageIcon from '../assests/images/homePageImages/Message.svg';
import VectorIcon from '../assests/images/homePageImages/Vector.svg';
import AddressIcon from '../assests/images/homePageImages/Address.svg';
import { Link, useLocation } from 'react-router-dom';

const FooterWebsite = () => {
    const location = useLocation();

    const redirectionLoad = (e) => {
        e.preventDefault();
        localStorage.setItem('scrollToTop', 'true');
        document.location.href = e.target.href;
    };

    useEffect(() => {
        if (localStorage.getItem('scrollToTop') === 'true') {
            window.scrollTo(0, 0);
            localStorage.removeItem('scrollToTop');
        }
    }, []);

    const scrollTop = (e) => {
        e.preventDefault();
        localStorage.setItem('scrollToTop', 'true');
        if (location.pathname === '/') {
            window.location.reload();
        } else {
            document.location.href = '/';
        }
    };

    return (
        <footer>
            <div className="container" id="foot-0">
                <div className="container" id="foot-1">
                    <Link to="/" onClick={scrollTop}><img src={logo} className="logo" alt="logo img" /></Link>
                    <div className="content">
                        <h3>Laat energie voor je werken</h3>
                        <a href="https://www.linkedin.com/company/powerhive-nl" target="_blank" rel="noopener noreferrer">
                            <img className="social-icon" src={LinkedInlogo} alt="LinkedIn" />
                        </a>
                    </div>
                </div>

                <div className="container" id="foot-2">
                    <div className="content">
                        <h3>Navigeer</h3>
                        <Link to="/services" onClick={redirectionLoad}>Services</Link>
                        <Link to="/about-us" onClick={redirectionLoad}>Over ons</Link>
                        <Link to="/contact-us" onClick={redirectionLoad}>Contact</Link>
                        <Link to="/terms-and-conditions" onClick={redirectionLoad}>Algemene voorwaarden</Link>
                        <Link to="/privacy-statement" onClick={redirectionLoad}>Privacyverklaring</Link>
                    </div>
                </div>

                <div className="container" id="foot-3">
                    <div className="content">
                        <h3>Neem contact op</h3>
                        <div className="container">
                            <img className="social-icon" src={MessageIcon} alt="Message" />
                            <Link to="mailto:contact@powerhive.energy" target="_blank">contact@powerhive.energy</Link>
                        </div>
                        <div className="container">
                            <img className="social-icon" src={VectorIcon} alt="Phone" />
                            <Link to="tel:+31636174388" target="_blank">(085) 013 2430</Link>
                        </div>
                        <div className="container">
                            <img className="social-icon" src={AddressIcon} alt="Address" />
                            <Link to="https://maps.google.com/?q=Prins%20Bernardlaan%2015 3441XG,%20Woerden" target="_blank" rel="noopener noreferrer">Prins Bernardlaan 15 <br /><span className="space"> </span>3441XG, Woerden</Link>
                        </div>
                    </div>
                </div>

                <div className="container" id="foot-1-mobile">
                    <div className='container'>
                        <Link to="/" onClick={scrollTop}><img src={logo} className="logo" alt="logo img" /></Link>
                        <h3>Laat energie voor je werken</h3></div>

                    <div className="content">
                        <Link to="https://www.linkedin.com/company/powerhive-nl" target="_blank" rel="noopener noreferrer">
                            <img className="social-icon" src={LinkedInlogo} alt="LinkedIn" />
                        </Link>
                    </div>
                </div>
                <div className='container' id='foot-mobile'>
                    <div className="container" id="foot-22">
                        <div className="content">
                            <h3>Navigeer</h3>
                            <Link to="/services" onClick={redirectionLoad}>Services</Link>
                            <Link to="/about-us" onClick={redirectionLoad}>Over ons</Link>
                            <Link to="/contact-us" onClick={redirectionLoad}>Contact</Link>
                            <Link to="/terms-and-conditions" onClick={redirectionLoad}>Algemene voorwaarden</Link>
                            <Link to="/privacy-statement" onClick={redirectionLoad}>Privacyverklaring</Link>
                        </div>
                    </div>

                    <div className="container" id="foot-33">
                        <div className="content">
                            <h3>Neem contact op</h3>
                            <div className="container">
                                <img className="social-icon" src={MessageIcon} alt="Message" />
                                <Link to="mailto:contact@powerhive.energy" target="_blank">contact@powerhive.energy</Link>
                            </div>
                            <div className="container">
                                <img className="social-icon" src={VectorIcon} alt="Phone" />
                                <Link to="tel:+31636174388" target="_blank">+31 (0)6 36 17 43 88</Link>
                            </div>
                            <div className="container">
                                <img className="social-icon" src={AddressIcon} alt="Address" />
                                <Link to="https://maps.google.com/?q=Prins%20Bernardlaan%2015 3441XG,%20Woerden" target="_blank" rel="noopener noreferrer">Prins Bernardlaan 15 <br /><span className="space"> </span>3441XG, Woerden</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container" id="foot-4">
                <hr />
                <p>Copyright &copy; 2024 PowerHive</p>
            </div>
        </footer>
    );
};

export default FooterWebsite;
