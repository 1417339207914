import React from 'react';
import appLogo from '../../assests/images/AppLogo/logo_Icon.png';
import { useTranslation } from 'react-i18next';
const ThankYouPage = () => {
  const {t} = useTranslation()
  return (
    <div className="thankyou-page">
      <div className="header">
        <h3 className="title text-center">{t('Thank you for signing up')}</h3>
        <div className="text-center">
          <img src={appLogo} className="img-fluid" />
          <p className='mt-lg-3 mt-2 font-light'>
           {t('You have completed the registration with PowerHive. We’ll get working on switching your connections.')}{' '}
          </p>
          <p className='mt-lg-3 mt-2 font-light'>
            {t('Please check your inbox for our')} <strong>{t('activitation mail')}</strong>. {t('Use the link in the mail to set up your account and access the platform.')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
