import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from 'reactstrap';
import error from '../assests/images/icons/Error.png';
import visible from '../assests/images/icons/View.png';
import invisible from '../assests/images/icons/invisible.png';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { api, apiV2 } from '../utilities/api';
import * as Yup from 'yup';
import Loader from '../components/common/Loader';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setIsLoggedIn, setUserDetails } from '../redux/AuthReducer';
import { RESET_PASS_URL } from '../config/constants';
import { useTranslation } from 'react-i18next';
import { endpoints } from '../utilities/Endpoints';
import MessagePopup from '../components/common/MessagePopup';

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState('password');
  const [rememberMe, setRememberMe] = useState(false);
  const [showMessageModal,setShowMessageModal] = useState(false)
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .email(`${t('Invalid email address')}`)
      .required(`${t('Email Address is required')}`),
    password: Yup.string().required(`${t('Password is required')}`),
  });
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      handleSubmit(values);
    },
  });
  const handleSubmit = async values => {
    setLoading(true);
    try {
      if (values) {
        const response = await api.post(endpoints.login, values);
        // console.log('response', response);
        if (response.status == 200) {
          if (response?.data?.result?.accessToken) {
            const { username, name, userId } = response?.data?.result?.account;
            const { accessToken, idToken, companyId, companyName } =
              response?.data?.result;
            sessionStorage.setItem('accessToken', accessToken);
            sessionStorage.setItem('idToken', idToken);
            sessionStorage.setItem('username', name);
            sessionStorage.setItem('userId', userId);
            sessionStorage.setItem('companyId', companyId);
            sessionStorage.setItem('companyName', companyName);
            sessionStorage.setItem('active-tab', 'dashboard');

            dispatch(setIsLoggedIn(true));
            dispatch(
              setUserDetails({
                userName: name,
                email: username,
                accessToken: accessToken,
                idToken: idToken,
                companyId: companyId,
              }),
            );
            toast.success(`${t('Welcome to PowerHive')}`);
            navigate('/user/app/dashboard');
          }
        }
      }
    } catch (error) {
      console.log('Error while login', error);
      if (error?.resposne?.status == 401) {
        toast.error(`${t('Unauthorized')}`);
      }
      if (error?.resposne?.status == 404) {
        toast.error(`${t('User not found')}`);
      }
      if( error?.response?.data?.code == 400){
        setShowMessageModal(true)
      }
      else {
        toast.error(
          error?.response?.data?.message ||
            'Internal server error Or Please check your username or password.',
        );
      }
    }
    setLoading(false);
  };
  const togglePassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
      return;
    }
    setPasswordType('password');
  };
  const handleClose= () =>{
    setShowMessageModal(false)
  }

  return (
    <div className="auth-layout">
      {isLoading && <Loader isForm="true" isOpen={isLoading} />}
      <Row className="w-100 m-md-4 m-2 login-form">
        <Col lg={7} xl={6} xs={12}>
          <Card className="bg-auth">
            <div className="bg-auth-footer">
              {t('Make energy work for you')}
            </div>
          </Card>
        </Col>
        <Col lg={5} xl={6} xs={12} className="auth-container p-md-4">
          <Card className="auth-card ">
            <CardBody className="py-lg-4 py-3">
              <h2 className="text-center font-bold mb-3">
                {t('Welcome Back')}
              </h2>
              <form
                className="mx-md-4 mx-1 h-75 "
                onSubmit={formik.handleSubmit}>
                <div className="auth-user">
                  <FormGroup className="position-relative  " floating>
                    <Input
                      id="username"
                      type="text"
                      name="username"
                      placeholder="Email Address"
                      value={formik.values.username}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <Label for="username">{t('Email Address')}</Label>
                    {formik.errors.username && formik.touched.username && (
                      <div className="error-icon">
                        <img src={error} />
                      </div>
                    )}
                    <div className="text-danger error-msg">
                      {formik.errors.username && formik.touched.username
                        ? formik.errors.username
                        : ''}
                    </div>
                  </FormGroup>
                  <FormGroup
                    className="position-relative password-input"
                    floating>
                    <Input
                      id="password"
                      type={passwordType}
                      name="password"
                      placeholder="Password"
                      className={
                        formik.errors.password &&
                        formik.touched.password &&
                        'has-error'
                      }
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <Label for="password">{t('Password')}</Label>
                    <div className="toggle-password position-absolute">
                      <img
                        src={passwordType === 'password' ? invisible : visible}
                        className=""
                        onClick={togglePassword}
                      />
                    </div>
                    {formik.errors.password && formik.touched.password && (
                      <div className="error-icon">
                        <img src={error} />
                      </div>
                    )}
                    <div className="text-danger error-msg">
                      {formik.errors.password && formik.touched.password
                        ? formik.errors.password
                        : ''}
                    </div>
                  </FormGroup>
                  <div className="d-flex alin-items-center justify-content-end">
                    {/* <FormGroup check>
                      <Input
                        id="remember"
                        name="remember"
                        type="checkbox"
                        checked={rememberMe}
                        onChange={e => setRememberMe(e.target.checked)}
                      />
                      <Label check for="remember" className="font-regular">
                        Remember me
                      </Label>
                    </FormGroup> */}
                    <a
                      className="font-regular text-dark underline-none"
                      target="blank"
                      href={`${RESET_PASS_URL}`}>
                      {t('Forgot Password ?')}
                    </a>
                  </div>
                  <div></div>
                </div>
                <div className="d-flex justify-content-center mb-40">
                  <Button className=" btn-submit w-50 " type="submit">
                    {t('Login Now')}
                  </Button>
                </div>
              </form>
              <h6 className="text-center font-regular text-gray">
                {t('Don’t have an account?')}
                <span
                  role="button"
                  onClick={() => navigate('/auth/get-started')}
                  className="btn-link font-bold">
                  &nbsp;{t('Register with us')}
                </span>
              </h6>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {showMessageModal && <MessagePopup  isOpen={setShowMessageModal} handleClose={handleClose}/>}
    </div>
  );
};
export default Login;
