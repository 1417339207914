import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import closeIcon from '../../assests/images/icons/close.svg';
import { useTranslation } from 'react-i18next';
import { IS_FEATURED } from '../../config/constants';

const MessagePopup = ({ isOpen = '', handleClose = {}, type = '' }) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal isOpen={isOpen} centered backdrop className="mw-450">
        <ModalBody className="p-3">
          {IS_FEATURED == 'true' && (
            <span role="button" className="close-button">
              <img src={closeIcon} height={25} onClick={handleClose} />
            </span>
          )}
          <p className="font-regular text-gray px-lg-2 fs-18 text-center">
            {t(
              'Unable to create organisation with InterSolve. Please contact support',
            )}{' '}
            <a href="mailto:contact@powerhive.energy">
              contact@powerhive.energy
            </a>{' '}
            {t('for more information.')}
          </p>
        </ModalBody>
      </Modal>
    </>
  );
};

export default MessagePopup;
