import { useState } from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { getCompanyData } from '../../pages/OnBoarding/function';
import '../styles.scss';

const CompanyList = ({
  companyList = [],
  setShowResult = {},
  setQuery = {},
  setCompanyData = {},
  setDisabled = {},
  setIsEditing={}
}) => {
  const [selectedItem, setSelectedItem] = useState('');

  const handleGetCompanyDetails = async id => {
    let { data } = await getCompanyData(id);
    if (!data) {
      return;
    }
    setQuery(data?.naam);
    setCompanyData(data);
    setShowResult(false);
    setSelectedItem(id);
    setDisabled(false);
    setIsEditing(false)
  };

  return (
    <div className="company-list">
      <ListGroup className={`${companyList?.length == 0 && 'no-record-item'}`}>
        {companyList?.length > 0 &&
          companyList.map(item => (
            <ListGroupItem
              key={item?.kvkNumber}
              className={`${item?.id === selectedItem ? 'active' : ''} cursor-pointer`}
              onClick={() => handleGetCompanyDetails(item?.id)}>
              {item?.names[0]}
            </ListGroupItem>
          ))}
        {companyList.length == 0 && (
          <ListGroupItem className="no-record-item text-center flex-column">
            {/* <span>No Records found!</span> */}
            <span className="fs-14 font-monospace- pt-2">
              Geen resultaat gevonden. Neem contact op met{' '}
              <a href="mailTo:contact@powerhive.energy">
                contact@powerhive.energy
              </a>{' '}
              en dan helpen we je verder.
              {/* We’re unable to find your company details. Please contact us at&nbsp; */}
            </span>
          </ListGroupItem>
        )}
      </ListGroup>
    </div>
  );
};

export default CompanyList;
