import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BsCircleFill, BsSquare, BsSquareFill } from 'react-icons/bs';
import {
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
} from 'reactstrap';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import EmptyIcon from '../../assests/images/miscellaneous/search.svg';
import NoData from '../../assests/images/miscellaneous/file.svg';
import closeIcon from '../../assests/images/icons/close.svg';
import { api } from '../../utilities/api';
import { formatNumber } from '../../utilities/function';
import { FaCheckCircle, FaClock, FaTelegramPlane } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const PerDayRecord = ({
  isOpen = '',
  data = '',
  date = '',
  orderId = '',
  handleClose = {},
  status = '',
}) => {
  const {t} = useTranslation()
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{label.substring(0, 5)}</p>
          <p>
            <BsSquareFill
              size={20}
              color={`${payload[0]?.color}`}
              className="me-2"
            />
            {formatNumber(payload[0]?.payload?.actualVolume)}&nbsp;
            {data?.graphData[0]?.volumeUnit}
          </p>
          <p>
            <BsSquareFill
              size={20}
              color={`${payload[1]?.color}`}
              className="me-2"
            />
            {formatNumber(payload[1]?.payload?.forecastedVolume)}&nbsp;
            {data?.graphData[0]?.volumeUnit}
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <Modal
      isOpen={isOpen}
      centered
      backdrop
      size="xl"
      className="order-details">
      <ModalBody>
        <div className="modal-header-detail">
          <div className="px-md-4 px-3 font-bold">{t('Order')} #{orderId}</div>
          <div className="px-md-4 px-3">
            {moment(new Date(date)).format('DD MMM YYYY') || 'N/A'}
          </div>
          <div className="d-flex align-items-center justify-content-evenly px-md-4 px-3 text-capitalize">
            {/* <BsCircleFill
              size={10}
              width={10}
              className="me-1 mb-1"
              color={`${status == 'Draft' ? '#FC7900' : status == 'Preliminary' ? '#F60094' : status == 'Final' ? '#06C202' : '#156CFE'}`}
            /> */}
            {status == 'Draft' ? (
              <FaTelegramPlane color="#001427" size={15} className='me-1 mb-1'/>
            ) : status == 'Preliminary' ? (
              <FaClock color="#001427" size={15} className='me-1 mb-1'/>
            ) : status == 'Final' ? (
              <FaCheckCircle color="#001427" size={15} className='me-1 mb-1'/>
            ) : (
              '-'
            )}{' '}
            {t(`${status}`)}
          </div>
        </div>
        <span role="button" className="close-button">
          <img src={closeIcon} height={25} onClick={handleClose} />
        </span>
        <div className="pt-md-4 pt-3">
          {!data || data.length <= 0 ? (
            <div className="no-data-view fs-16">
              <img
                src={data.length <= 0 ? NoData : EmptyIcon}
                className=""
                height={100}
              />
              {data.length <= 0 ? (
                <span className="font-regular text-center">{('No data yet')}</span>
              ) : (
                <span className="font-regular text-center">
                  {('Forecast is being generated.')} <br />
                  {('This usually appears the next day')}
                </span>
              )}
            </div>
          ) : (
            <>
              <div className="items-center justify-content-end mx-md-4 text-blue">
                <>
                  <BsCircleFill
                    color="#FBBB36"
                    size={20}
                    className="mx-md-2 mx-1"
                  />
                  <span>
                    {t('Forecast')}&nbsp;({data?.graphData[0]?.volumeUnit || 'kWh'})
                  </span>
                </>
                <>
                  <BsCircleFill
                    color="#001427"
                    size={20}
                    className="mx-md-2 mx-1"
                  />
                  <span>
                    {t('Imbalance')}&nbsp;({data?.graphData[0]?.volumeUnit || 'kWh'})
                  </span>
                </>
              </div>
              <div style={{ height: '400px' }} className="mx-md-4">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    width={500}
                    height={300}
                    data={data.graphData}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 10,
                      bottom: 30,
                    }}>
                    <CartesianGrid strokeDasharray="5 3" vertical={false} />
                    <XAxis
                      dataKey="hour"
                      tickFormatter={tick => tick.substring(0, 5)}
                      tickCount={24}
                      angle={-90}
                      dy={20}
                      axisLine={false}
                      tickLine={false}
                      padding={{ left: 10, right: 10 }}
                    />
                    <YAxis
                      tickCount={6}
                      axisLine={false}
                      tickLine={false}
                      tickSize={15}
                      tickFormatter={tick =>
                        tick != 0 ? formatNumber(tick) : '0'
                      }
                    />
                    <Tooltip content={<CustomTooltip />} cursor={false} />
                    <Line
                      type="monotone"
                      dataKey="actualVolume"
                      stroke="#FBBB36"
                      activeDot={{ r: 8 }}
                      strokeWidth={2}
                      unit={data?.graphData[0]?.volumeUnit}
                    />

                    <Line
                      type="monotone"
                      dataKey="forecastedVolume"
                      stroke="#001427"
                      activeDot={{ r: 8 }}
                      strokeWidth={2}
                      unit={data?.graphData[0]?.volumeUnit}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
              <div className="mx-lg-5 mx-3 mt-5 mb-3">
                {data?.historicalData && data?.historicalData.length > 0 ? (
                  <Table className="summary-box mb-0 per-day-record">
                    <thead>
                      <tr>
                        <td className="table-header " valign="top" width="10%">
                          {t('Hour')}
                        </td>
                        <td
                          className="table-header text-end- text-transform-none"
                          valign="top"
                          width="15%">
                          {t('VOLUME')}
                          <br />({data?.graphData[0]?.volumeUnit})
                        </td>
                        <td
                          className="table-header text-transform-none"
                          valign="top"
                          width="15%">
                          {t('IMBALANCES')}
                          <br />({data?.graphData[0]?.volumeUnit})
                        </td>
                        <td
                          className="table-header text-end-"
                          valign="top"
                          width="15%">
                          EPEX&nbsp;{t('PRICE')}
                        </td>
                        <td
                          className="table-header text-end-"
                          valign="top"
                          width="15%">
                          {t('Imbalance')} {t('PRICE')}
                        </td>
                        <td
                          className="table-header text-end-"
                          valign="top"
                          width="15%">
                          {t('tax')}
                        </td>
                        <td
                          className="table-header text-end-"
                          valign="top"
                          width="15%">
                          {t('fees')}
                        </td>
                        <td
                          className="table-header text-end-"
                          valign="top"
                          width="15%">
                          {t('Total')}
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.historicalData &&
                        data?.historicalData.map((item, index) => (
                          <tr key={index} className="table-data">
                            <td className="">{item?.hour}</td>
                            <td>
                              {' '}
                              {item?.dealQuantityInKwh
                                ? formatNumber(
                                    Math.abs(item?.dealQuantityInKwh),
                                  )
                                : '-'}
                            </td>
                            <td valign="middle">
                              {item?.imbalanceInKwh
                                ? formatNumber(Math.abs(item?.imbalanceInKwh))
                                : '-'}
                            </td>
                            <td valign="middle">
                              {item?.epexPrice
                                ? formatNumber(
                                    Math.abs(item?.epexPrice),
                                    'currency',
                                  )
                                : '-'}
                            </td>
                            <td valign="middle">
                              {item?.imbalancePricePerKwh
                                ? formatNumber(
                                    Math.abs(item?.imbalancePricePerKwh),
                                    'currency',
                                  )
                                : '-'}
                            </td>
                            <td valign="middle">
                              {item?.energyTax
                                ? formatNumber(
                                    Math.abs(item?.energyTax),
                                    'currency',
                                  )
                                : '-'}
                            </td>
                            <td valign="middle">
                              {item?.platformFees
                                ? formatNumber(
                                    Math.abs(item?.platformFees),
                                    'currency',
                                  )
                                : '-'}
                            </td>
                            <td valign="middle">
                              {item?.totalSum
                                ? formatNumber(
                                    Math.abs(item?.totalSum),
                                    'currency',
                                  )
                                : '-'}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className="no-data-view">
                    <img src={NoData} alt="No Data" height={100} />
                    <span className="font-regular text-center">
                      {t('No data yet')}
                    </span>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PerDayRecord;
