// rootReducer.js
import { combineReducers } from '@reduxjs/toolkit';
import AuthReducer from './AuthReducer';

const rootReducer = combineReducers({
  userInfo: AuthReducer,
   // Add more reducers here if needed
});

export default rootReducer;
