import React, { useEffect, useState } from 'react';
import { Badge, Card, CardBody, CardTitle } from 'reactstrap';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import EmptyIcon from '../../assests/images/miscellaneous/search.svg';
import NoData from '../../assests/images/miscellaneous/file.svg';
import { BsSquareFill } from 'react-icons/bs';
import { api } from '../../utilities/api';
import { toast } from 'react-toastify';
import { formatNumber } from '../../utilities/function';
import { useTranslation } from 'react-i18next';
import { endpoints } from '../../utilities/Endpoints';

const EpexSpot = () => {
  const {t}= useTranslation()
  const [loading, setLoading] = useState(false);
  const [epexData, setEpexData] = useState([]);
  const getEPEXData = async () => {
    setLoading(true);
    try {
      const idToken =
        sessionStorage.getItem('idToken') || localStorage.getItem('idToken');
        const companyId =
        sessionStorage.getItem('companyId') || localStorage.getItem('companyId');
      const response = await api.get(endpoints.epexSpot, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          companyId:companyId
        },
      });
      if (response.status == 200) {
        setEpexData(response?.data);
      }
    } catch (error) {
      console.log('Error getting epex data', error);
      toast.error(t(error?.response?.data?.message));
      setEpexData([])
    }
    setLoading(false);
  };
  useEffect(() => {
    getEPEXData();
  }, []);
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      let price = Number(payload[0].value).toFixed(2);
      return (
        <div className="custom-tooltip">
          <p className="label mb-1 fs-14">{label}</p>
          <p className="mb-0 fs-14">
            <BsSquareFill
              size={20}
              color={`${payload[0]?.color}`}
              className="me-2"
            />
            {formatNumber(price)}
            {epexData[0]?.unit}
          </p>
        </div>
      );
    }

    return null;
  };
  return (
    <Card className="cstm-card">
      <CardBody className="p-2">
        {!epexData || epexData.length <= 0 || loading ? (
          <div className="no-data-view">
            <img src={loading ? EmptyIcon : NoData} className="" height={80} />
            {loading ? (
              <span className="font-regular text-center">
                {t('Forecast is being generated.')}
                <br /> {t('This usually appears the next day')}
              </span>
            ) : (
              <span className="font-regular text-center">{t('No data yet')}</span>
            )}
          </div>
        ) : (
          <>
            <div style={{ height: 'inherit' }}>
              <ResponsiveContainer
                width="100%"
                height="100%"
                minWidth="250px"
                minHeight="190px"
                maxHeight="190px">
                <AreaChart
                  width={'95%'}
                  height={'95%'}
                  data={epexData}
                  margin={{ top: 20, right: 10, left: -25, bottom: -10 }}>
                  <defs>
                    <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                      <stop
                        offset="0%"
                        stopColor="#06C202"
                        stopOpacity={0.25}
                      />
                      <stop offset="100%" stopColor="#06C202" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis
                    dataKey="intervalHour"
                    tickCount={4}
                    axisLine={false}
                    interval={5}
                    dx={18}
                  />
                    <YAxis tickCount={4} tickSize={3} axisLine={false} tickFormatter={tick=>formatNumber(tick)}/>

                  <CartesianGrid strokeDasharray="5 3" />
                  <Tooltip content={<CustomTooltip />} cursor={false} />
                  <Area
                    dataKey="price"
                    stroke="#06C202"
                    strokeWidth={2}
                    fillOpacity={1}
                    fill="url(#gradient)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default EpexSpot;
