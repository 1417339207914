import React, { useEffect, useState } from 'react';
import { BsCircleFill } from 'react-icons/bs';
import NoData from '../../assests/images/miscellaneous/file.svg';
import EmptyIcon from '../../assests/images/miscellaneous/search.svg';
import {
  FaAngleRight,
  FaTelegramPlane,
  FaCheckCircle,
  FaClock,
} from 'react-icons/fa';
import { Card, CardBody, Spinner, Table, Tooltip } from 'reactstrap';
import { formatNumber } from '../../utilities/function';
import PerDayRecord from './PerDayRecord';
import { api } from '../../utilities/api';
import moment from 'moment';
import Loader from '../common/Loader';
import { useTranslation } from 'react-i18next';
import { endpoints } from '../../utilities/Endpoints';

const History = ({
  data = '',
  loading = '',
  hasMore=true,
  handleShowMore = {},
  historyLoading = '',
  lastTransactionDate = '',
  fetchNextRecord = false,
}) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [isLoadingData, setLoadingData] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const [orderDate, setOrderDate] = useState('');
  const [orderStatus, setOrderStatus] = useState('');
  const [orderId, setOrderId] = useState('');
  let lastDate = new Date(lastTransactionDate).getDate();

  const [tooltipOpen, setTooltipOpen] = useState({});
  // const toggle = () => setTooltipOpen(!tooltipOpen);
  const toggle = index => {
    setTooltipOpen({ ...tooltipOpen, [index]: !tooltipOpen[index] });
  };
  const handleOrderDetails = async (transactionDate, orderid, status) => {
    setLoadingData(true);
    try {
      const idToken =
        sessionStorage.getItem('idToken') || localStorage.getItem('idToken');
      const companyId =
        sessionStorage.getItem('companyId') ||
        localStorage.getItem('companyIds');
      const response = await api.get(
        `${endpoints.historicalDataByDay}?transaction_date=${transactionDate}`,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
            companyId: companyId,
          },
        },
      );
      if (response.status == 200 && response.data) {
        setOrderDate(transactionDate);
        setOrderStatus(status);
        setOrderId(orderid);
        setOrderDetails(response.data);
        setLoadingData(false);
        setOpen(true);
      }
    } catch (error) {
      console.log('Error fetching data', error);
    }
    setLoadingData(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Card className="cstm-card mt-3">
        <CardBody className="p-0">
          <div className="m-lg-3 m-2">
            {loading ||
            !data ||
            data.length <= 0 ||
            fetchNextRecord ? (
              <div className="no-data-view fs-16">
                <img
                  src={
                    fetchNextRecord ? EmptyIcon : !loading ? NoData : EmptyIcon
                  }
                  alt="No Data"
                  height={100}
                />
                {loading || fetchNextRecord ? (
                  <span className="font-regular text-center">
                    {t('Forecast is being generated.')}
                    <br /> {t('This usually appears the next day')}
                  </span>
                ) : (
                  <span className="font-regular text-center">
                    {t('No data yet')}
                  </span>
                )}
              </div>
            ) : (
              <div className="history-table">
                <Table className="summary-box mb-0" hover>
                  <thead>
                    <tr>
                      <td className="table-header " valign="top" width="8%">
                        {t('ORDER ID')}
                      </td>
                      <td
                        className="table-header text-end-"
                        valign="top"
                        width="8%">
                        {t('DATE')}
                      </td>
                      <td
                        className="table-header text-transform-none"
                        valign="top"
                        width="15%">
                        {t('VOLUME')}
                        <br />
                        (kWh){' '}
                      </td>
                      <td
                        className="table-header text-transform-none"
                        valign="top"
                        width="10%">
                        {t('imbalance-volume')}
                        <br/>
                        (kWh)
                        <br/>
                      </td>
                      <td
                        className="table-header text-end-"
                        valign="top"
                        width="15%"
                        style={{ minWidth: '100px' }}>
                        {t('costs')}
                      </td>
                      <td
                        className="table-header text-end-"
                        valign="top"
                        width="15%">
                        {t('imbalance-amount')}
                        <br/>
                      </td>
                      <td
                        className="table-header text-end-"
                        valign="top"
                        width="7%">
                        {t('tax')}
                      </td>
                      <td
                        className="table-header text-end-"
                        valign="top"
                        width="7%">
                        {t('fees')}
                      </td>
                      <td
                        className="table-header text-end-"
                        valign="top"
                        width="16%"
                        style={{ minWidth: '100px' }}>
                        {t('Total')}
                      </td>
                      <td
                        className="table-header ps-4"
                        valign="top"
                        width="10%">
                        {t('status')}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.map((item, index) => (
                        <tr
                          key={index}
                          className={` table-details`}
                          onClick={() =>
                            handleOrderDetails(
                              item?.transactionDate,
                              item?.externalReferenceId,
                              item?.status,
                            )
                          }>
                          <td className="font-bold" valign="middle">
                            {item?.externalReferenceId
                              ? '#' + item?.externalReferenceId
                              : '-'}
                          </td>
                          <td className="text-end-" valign="middle">
                            {moment(new Date(item?.transactionDate)).format(
                              'DD/MM',
                            ) || '-'}
                          </td>
                          <td valign="middle" className={` text-end-`}>
                            {item?.volume
                              ? (item?.volume < 0 ?'-':'')+ formatNumber(Math.abs(item?.volume))
                              : '-'}
                          </td>
                          <td valign="middle" className={` text-end-`}>
                            {item?.imbalanceInKwh
                              ? 
                              (item?.imbalanceInKwh < 0 ?'-':'') +formatNumber(Math.abs(item?.imbalanceInKwh))
                              : '-'}
                          </td>
                          <td valign="middle" className={` text-end-`}>
                            {item?.dealAmount
                              ? (item?.dealAmount < 0 ?'-':'') +'€' + formatNumber(Math.abs(item?.dealAmount))
                              : '-'}
                          </td>
                          <td valign="middle" className={` text-end-`}>
                            {item?.imbalanceAmount
                              ? 
                               (item?.imbalanceAmount < 0 ?'-':'')+'€' + formatNumber(Math.abs(item?.imbalanceAmount))
                              : '-'}
                          </td>
                        
                          <td valign="middle" className={`text-end-`}>
                            {item?.energyTax
                              ? '€' + formatNumber(Math.abs(item?.energyTax))
                              : '-'}
                          </td>
                          <td valign="middle" className={`text-end-`}>
                            {item?.platformFees
                              ? '€' + formatNumber(Math.abs(item?.platformFees))
                              : '-'}
                          </td>
                          <td valign="middle" className={` text-end-`}>
                            {(item?.totalSum < 0 ?'-':'') +'€' + formatNumber(Math.abs(item?.totalSum))}
                          </td>
                          <td
                            valign="middle"
                            className=" text-capitalize status">
                            <div
                              className="ps-3 d-flex align-items-center justify-content-start"
                              id={`Tooltip-${index}`}>
                              {item?.status &&
                              (item?.status.toLowerCase() == 'senttomarket' || item?.status.toLowerCase() == 'draft') ? (
                                <FaTelegramPlane color="#001427" size={15} />
                              ) : item?.status &&
                                item?.status.toLowerCase() == 'preliminary' ? (
                                <FaClock
                                  color="#001427"
                                  size={14}
                                  id="status"
                                />
                              ) : item?.status &&
                                item?.status.toLowerCase() == 'final' ? (
                                <FaCheckCircle
                                  color="#001427"
                                  size={14}
                                  id="status"
                                />
                              ): (
                                'N/A'
                              )}
                            </div>
                            <Tooltip
                              isOpen={tooltipOpen[index]}
                              target={`Tooltip-${index}`}
                              className={`${item.status != '' ? '':'d-none'} tooltip-status`}
                              placement="top"
                              toggle={() => toggle(index)}>
                              {t(`${item?.status}`)}
                            </Tooltip>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            )}
            {!historyLoading &&
              !loading &&
              data.length > 0 && lastDate!=1&&
              hasMore && (
                <div className="d-flex justify-content-end align-items-center mt-2 cstm-button">
                  <button
                    className={` fs-14 text-blue text-end items-center`}
                    role="button"
                    onClick={handleShowMore}>
                    <span>{t('Show more')}</span>
                    <FaAngleRight color="#718ebf" size={14} />
                  </button>
                </div>
              )}
            {historyLoading && !loading && (
              <div className="w-100 text-center font-regular text-gray mt-2">
                Loading
                <Spinner
                  color="secondary"
                  size="sm"
                  type="grow"
                  className="ms-2">
                  Loading...
                </Spinner>{' '}
                <Spinner color="secondary" size="sm" type="grow">
                  Loading...
                </Spinner>
              </div>
            )}
          </div>
        </CardBody>
      </Card>
      {isLoadingData && <Loader isOpen={isLoadingData} />}
      {isOpen && (
        <PerDayRecord
          isOpen={isOpen}
          status={orderStatus}
          date={orderDate}
          orderId={orderId}
          data={orderDetails}
          handleClose={handleClose}
        />
      )}
    </div>
  );
};

export default History;
